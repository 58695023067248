<template>
<div class="pages-home-index-wrapper"  @click="hideAlert">
    <home-index-welcome :language = "language" :sliderImagesData = "sliderImagesData" :markets = "markets" :activityBannerImgUrl = "activityBannerImgUrl" @reload-markets="reloadMarkets" />
    <home-trades-title :language = "language"/>
    <home-trades-main-main-area :language = "language"  :checkDrapName = "checkDrapName" :isHideDrapdown = "isHideDrapdown" :isCheckdDrap = "isCheckdDrap" :quotationTypeValueArea = "quotationTypeValueArea" :quoteCenterAreaData = "quoteCenterAreaData" :quoteCenterAreaPassCard = "quoteCenterAreaPassCard" :checkOrderColumnArea = "checkOrderColumnArea"  :originalArea = "originalArea" :quotationType = "areaQuotationType" @check-drap="checkDrap"  @show-drap-down="showDrapDown" @delete-drap = "deleteDrap" @change-qttt-area = "changeQtttArea" @check-market-favorite = "checkMarketFavoriteArea" @order-list-area = "orderListArea" @reload-list-data="reloadlistAreaData" />
    <!--
    <home-trades-main-innovation-zone :language = "language" :quotationTypeValueInnovation = "quotationTypeValueInnovation"  :quoteCenterInnovationData = "quoteCenterInnovationData" :checkOrderColumnInnovation = "checkOrderColumnInnovation" :orderTypeInnovation = "orderTypeInnovation"  :originalInnovation = "originalInnovation" :quotationType = "innovationQuotationType" @change-qttt-innovation = "changeQtttInnovation"  @check-market-favorite = "checkMarketFavoriteInnovation" @order-list-innovation = "orderListInnovation" @reload-list-data="reloadlistInnovationData"/>
    -->
    <home-index-features :language = "language"/>
    <home-index-main :language = "language"/>
    <home-index-download :language = "language" :ios_app_down_link = "ios_app_down_link"  :android_app_down_link = "android_app_down_link"  :ios_app_down_qr_code_img_link = "ios_app_down_qr_code_img_link"  :android_app_down_qr_code_img_link = "android_app_down_qr_code_img_link"/>
    <home-index-register :language = "language" v-if="isNotLoggedIn"/>
    <nav-footer :language = "language"/>
</div>
</template>
<script>
import HomeIndexWelcome from '../Home/HomeIndexWelcome'
//import HomeTradesTitle from '../Home/HomeTradesTitle'
import HomeTradesMainMainArea from '../Home/HomeTradesMainMainArea'
import HomeTradesMainInnovationZone from '../Home/HomeTradesMainInnovationZone'
import HomeIndexFeatures from '../Home/HomeIndexFeatures'
import HomeIndexMain from '../Home/HomeIndexMain'
import HomeIndexDownload from '../Home/HomeIndexDownload'
import HomeIndexRegister from '../Home/HomeIndexRegister'
import NavFooter from '../NavFooter'
import Vue from 'vue'
var quoteCenterAreaDataTemp = []
var quoteCenterAreaDataTempBig = []
var quoteCenterInnovationDataTemp = []
var quoteCenterInnovationDataTempBig = []
export default {
  data(){
    return {
      checkDrapTempName:'',
      isHideDrapdown:true,
      isCheckdDrap:false,
      quotationTypeValueArea:'',
      quotationTypeValueInnovation:'',
      sliderImagesData: [],
      markets: [],
      quoteCenterAreaData: [],
      quoteCenterInnovationData: [],
      ios_app_down_link:'',
      android_app_down_link:'',
      ios_app_down_qr_code_img_link:'',
      android_app_down_qr_code_img_link:'',
      quoteCenterAreaPassCard:[],
      checkOrderColumnArea:'',
      originalArea:'',

      checkOrderColumnInnovation:'',
      orderTypeInnovation:'',
      originalInnovation:'',
      checkDrapCode: '',
      quoteCenterAreaDataBig: [],
      quoteCenterAreaPassCardBig: [],


      quoteCenterInnovationDataBig: [],
      quoteCenterInnovationPassCardBig: [],

      areaQuotationType: [],
      innovationQuotationType: [],
      activityBannerImgUrl: ""
    }
  },
  computed: {
    checkDrapName: function(){
      if(this.isCheckdDrap == false){
        return this.$t('search_pass_describe');
      }else{
        return this.checkDrapTempName
      }
    },
    language: function () {
      return this.currentLang;
    }
  },
  methods: {
    reloadMarkets(){
      //图表中心数据
      this.markets = this.currentWwwHomeMarket;
    },
    filterDrapCodeAreaData(){
      if(this.isCheckdDrap){
        //选择了通证
        //过滤非此通证的数据
        var filterDrapCodeAreaData = [];
        for (var i = 0; i < this.quoteCenterAreaData.length; i++) {
          if(this.quoteCenterAreaData[i].ask_unit == this.checkDrapCode){
            filterDrapCodeAreaData.push(this.quoteCenterAreaData[i]);
          }
        }
        //将值改为过滤后的
        this.quoteCenterAreaData = filterDrapCodeAreaData;
      }
    },
    getMarketFavoriteList(type){
      this.getUrl('/www/home/market_favorite_list', {
        lang: this.currentLang,
        type: type,
      }).then((response) => {
        if(response.data.code == 20000){
          if(type == 'main'){
            Vue.setGlobalVariable({'main_trade_area_favorite_ids': response.data.data.list});
            Vue.setGlobalVariable({'main_trade_area_favorite_passcard': response.data.data.pass_card});
            this.reloadlistAreaData();
          }else{
            Vue.setGlobalVariable({'main_trade_innovation_favorite_ids': response.data.data.list});
            Vue.setGlobalVariable({'main_trade_innovation_favorite_passcard': response.data.data.pass_card});
            this.reloadlistInnovationData();
          }
        }
      });
    },
    //创新区加载数据
    reloadlistInnovationData(label = this.quotationTypeValueInnovation){
      //交易对类型
      if(this.currentWwwHomeTradesMainInnovationList.length != 0){
        //websocket值存在
        this.innovationQuotationType = Object.keys(this.currentWwwHomeTradesMainInnovationList);
      }else{
        //websocket值不存在
        this.innovationQuotationType = Object.keys(quoteCenterInnovationDataTempBig);
      }
      //通过websocket加载最新的数据
      if(this.quotationTypeValueInnovation == 'zxq'){
        var zxqAllListInnovation = []
        if(this.currentWwwHomeTradesMainInnovationList.length != 0){
          //websocket值存在
          var allList = this.currentWwwHomeTradesMainInnovationList;
        }else{
          //websocket值不存在
          var allList = quoteCenterInnovationDataTempBig;
        }
        for (var i = 0; i < Object.keys(allList).length; i++) {
          if(Object.keys(allList)[i] != 'zxq'){
            //排除默认接口返回的自选区数据
            for (var k = 0; k < allList[Object.keys(allList)[i]].length; k++) {
              if(this.currentMainInnovationFavoriteIds.includes(allList[Object.keys(allList)[i]][k]['id'])){
                //自选区列表一定为关注的
                zxqAllListInnovation.push(allList[Object.keys(allList)[i]][k])
              }
            }
          }
        }
        this.quoteCenterInnovationData = Object.assign([],zxqAllListInnovation);
        quoteCenterInnovationDataTemp =  Object.assign([], zxqAllListInnovation);
        //根据列表要排序的字段 - 排序
        this.orderListInnovationNoHand(this.checkOrderColumnInnovation);
      }else{
        //非自选区
        if(this.currentWwwHomeTradesMainInnovationList.length != 0){
          //已有websocket 取websocket的值
          //将data值设置成上次websocket取到的值
          this.quoteCenterInnovationData = Object.assign([],this.currentWwwHomeTradesMainInnovationList[label]);
          //上次websocket取到的值保存至一个临时变量里，方便未排序的时候列表数据复原
          quoteCenterInnovationDataTemp = Object.assign([],this.currentWwwHomeTradesMainInnovationList[label]);
        }else if(this.currentWwwHomeTradesMainInnovationList.length == 0){
          //未websocket 为空值
          //将data值设置成上次websocket取到的值
          if(this.quoteCenterInnovationDataBig.hasOwnProperty(label)){
            this.quoteCenterInnovationData = Object.assign([],this.quoteCenterInnovationDataBig[label]);
          }
          if(quoteCenterInnovationDataTempBig.hasOwnProperty(label)){
            //上次websocket取到的值保存至一个临时变量里，方便未排序的时候列表数据复原
            quoteCenterInnovationDataTemp = Object.assign([],quoteCenterInnovationDataTempBig[label]);
          }
        }
        //根据列表要排序的字段 - 排序
        this.orderListInnovationNoHand(this.checkOrderColumnInnovation);
      }
    },
    //主区加载数据
    reloadlistAreaData(label = this.quotationTypeValueArea){
      //交易对类型
      if(this.currentWwwHomeTradesMainInnovationList.length != 0){
        //websocket值存在
        this.areaQuotationType = Object.keys(this.currentWwwHomeTradesMainAreaList);
      }else{
        //websocket值不存在
        this.areaQuotationType = Object.keys(quoteCenterAreaDataTempBig);
      }
      //通过websocket加载最新的数据
      if(this.quotationTypeValueArea == 'zxq'){
        var zxqAllListArea = []
        if(this.currentWwwHomeTradesMainAreaList.length != 0){
          //websocket值存在
          var allList = this.currentWwwHomeTradesMainAreaList;
        }else{
          //websocket值不存在
          var allList = quoteCenterAreaDataTempBig;
        }
        for (var i = 0; i < Object.keys(allList).length; i++) {
          if(Object.keys(allList)[i] != 'zxq'){
            //排除默认接口返回的自选区数据
            for (var k = 0; k < allList[Object.keys(allList)[i]].length; k++) {
              if(this.currentMainAreaFavoriteIds.includes(allList[Object.keys(allList)[i]][k]['id'])){
                //自选区列表一定为关注的
                zxqAllListArea.push(allList[Object.keys(allList)[i]][k])
              }
            }
          }
        }
        this.quoteCenterAreaData = Object.assign([],zxqAllListArea);
        quoteCenterAreaDataTemp =  Object.assign([], zxqAllListArea);
        //搜索通证
        this.quoteCenterAreaPassCard = this.currentMainAreaFavoritePassCard;
        //根据列表要排序的字段 - 排序
        this.orderListAreaNoHand(this.checkOrderColumnArea);
        //过滤通证
        this.filterDrapCodeAreaData();
      }else{
        //非自选区
        if(this.currentWwwHomeTradesMainAreaList.length != 0){
          //已有websocket 取websocket的值
          //将data值设置成上次websocket取到的值
          this.quoteCenterAreaData = Object.assign([],this.currentWwwHomeTradesMainAreaList[label]);
          //上次websocket取到的值保存至一个临时变量里，方便未排序的时候列表数据复原
          quoteCenterAreaDataTemp = Object.assign([],this.currentWwwHomeTradesMainAreaList[label]);
          //搜索通证
          this.quoteCenterAreaPassCard = Object.assign([],this.currentWwwHomeTradesMainAreaPassCard[label+'_pass_card']);
        }else if(this.currentWwwHomeTradesMainAreaList.length == 0){
          //未websocket 为空值
          //将data值设置成上次websocket取到的值
          if(this.quoteCenterAreaDataBig.hasOwnProperty(label)){
            this.quoteCenterAreaData = this.quoteCenterAreaDataBig[label];
          }
          if(quoteCenterAreaDataTempBig.hasOwnProperty(label)){
            //上次websocket取到的值保存至一个临时变量里，方便未排序的时候列表数据复原
            quoteCenterAreaDataTemp = Object.assign([],quoteCenterAreaDataTempBig[label]);
          }
          //搜索通证
          this.quoteCenterAreaPassCard = Object.assign([],this.quoteCenterAreaPassCardBig[label+'_pass_card']);
        }
        //根据列表要排序的字段 - 排序
        this.orderListAreaNoHand(this.checkOrderColumnArea);
        //过滤通证
        this.filterDrapCodeAreaData();
      }
      if(this.quotationTypeValueArea == '' && Object.keys(this.currentWwwHomeTradesMainAreaList).length > 0){
        this.quotationTypeValueArea =  Object.keys(this.currentWwwHomeTradesMainAreaList)[0]
      }
    },
    orderListInnovationNoHand(label){
      //如果排序的值 升序/降序/无 为空 说明还原默认值
      if(this.originalInnovation == ''){
          //未排序返回默认值
          this.quoteCenterInnovationData = Object.assign([],quoteCenterInnovationDataTemp);
      }else{
          //已经存在排序类型 根据当前选中排序的字段 和 排序类型 进行排序
          this.sortInnovation(this.checkOrderColumnInnovation,this.originalInnovation);
      }
    },
    orderListAreaNoHand(label){
      //如果排序的值 升序/降序/无 为空 说明还原默认值
      if(this.originalArea == ''){
          //未排序返回默认值
          this.quoteCenterAreaData = Object.assign([],quoteCenterAreaDataTemp);
          //过滤通证
          this.filterDrapCodeAreaData();
      }else{
          //已经存在排序类型 根据当前选中排序的字段 和 排序类型 进行排序
          this.sortArea(this.checkOrderColumnArea,this.originalArea);
      }
    },
    orderListArea(label){
      //手动点击排序
      if(label != this.checkOrderColumnArea){
          //当 当前要排序的字段不是上次排序的字段，则将上次排序的类型 升序/降序/无 改为默认（从新开始，即升序）
          this.originalArea = '';
      }
      //将当前排序的字段记录下来
      this.checkOrderColumnArea = label;
      //如果排序的类型为空 说明这是初始化后第一次点击排序
      if(this.originalArea == ''){
          //升序操作
          //先将排序方式 升序/降序/无 改为升序
          this.originalArea = 'asc';
          //根据当前选中排序的字段 和 排序类型 进行排序
          this.sortArea(this.checkOrderColumnArea,this.originalArea);
      }else if(this.originalArea == 'asc'){
          //上一次为升序，则当前操作改为降序
          this.originalArea = 'desc';
          this.sortArea(this.checkOrderColumnArea,this.originalArea);
      }else{
          //else则为  上一次为降序，此次将不进行排序，初始化列表数据
          //设置排序类型 为空
          this.originalArea = '';
          //还原列表的默认值
          this.quoteCenterAreaData = Object.assign([],quoteCenterAreaDataTemp);
          //过滤通证
          this.filterDrapCodeAreaData();
      }
    },
    orderListInnovation(label){
      if(label != this.checkOrderColumnInnovation){
          this.orderTypeInnovation = '';
      }
      this.checkOrderColumnInnovation = label;
      if(this.orderTypeInnovation == ''){
          this.orderTypeInnovation = 'on';
          this.originalInnovation = 'asc';
          this.sortInnovation(this.checkOrderColumnInnovation,this.originalInnovation);
      }else if(this.orderTypeInnovation == 'on'){
          this.orderTypeInnovation = 'down';
          this.originalInnovation = 'desc';
          this.sortInnovation(this.checkOrderColumnInnovation,this.originalInnovation);
      }else{
          this.orderTypeInnovation = '';
          this.originalInnovation = '';
          this.quoteCenterInnovationData = Object.assign([],quoteCenterInnovationDataTemp);
      }
    },
    sortArea(type,originalArea) {
      //排序方法 根据要排序的字段  和 排序的 升/降/无 序 进行排序
      this.quoteCenterAreaData.sort(this.compareArea(type,originalArea));
    },
    sortInnovation(type,originalInnovation) {
      this.quoteCenterInnovationData.sort(this.compareInnovation(type,originalInnovation));
    },
    resetNumber(val,originalArea){
      if(val == '--'){
        //如果为--排序则放在最后面
        if(originalArea == 'asc'){
          //因为是升序，所以给 -- 定义一个最大值
          return 999999999999999999;
        }else{
          //倒序 给 -- 定义一个最小的值
          return  -999999999999999999;
        }
      }else{
        val = String(val)
        //正常的值 去掉其中的百分号，不然无法运算排序
        return val.replace("%","");
      }
    },
    compareArea(attr,originalArea) {
      //根据要排序的字段，排序类型 进行data值排序
      that = this;
      return function(a,b){
          var val1 = that.resetNumber(a[attr],originalArea);
          var val2 = that.resetNumber(b[attr],originalArea);
          if(originalArea == 'asc'){
            return val1 - val2;
          }else{
            return val2 - val1;
          }
      }
    },
    compareInnovation(attr,originalInnovation) {
        that = this;
        return function(a,b){
            var val1 = that.resetNumber(a[attr],originalInnovation);
            var val2 = that.resetNumber(b[attr],originalInnovation);
            if(originalInnovation == 'asc'){
              return val1 - val2;
            }else{
              return val2 - val1;
            }
        }
    },
    deleteDrap(){
        this.isCheckdDrap = false;
        this.reloadlistAreaData();
    },
    hideAlert(){
      if(this.isHideDrapdown == false){
        this.isHideDrapdown = true;
      }
    },
    showDrapDown(){
      this.isHideDrapdown = !this.isHideDrapdown;
    },
    checkDrap(unitCode,unitName){
      //点击通证
      this.checkDrapTempName = unitName;
      this.checkDrapCode = unitCode;
      this.isHideDrapdown = false;
      this.isCheckdDrap = true;
      this.reloadlistAreaData();
    },
    reloadData(){
      this.getUrl('/www/home', {
        lang:this.currentLang,
        pass_card: this.isCheckdDrap ? this.checkDrapCode : '',
        main_coin_type: this.quotationTypeValueArea,
        innovation_coin_type: this.quotationTypeValueInnovation,
      }).then((response) => {
        if(response.data.code == 20000){
            result = response.data.data;
            this.sliderImagesData = result['slider_images'];
            this.markets = [];
            quoteCenterAreaDataTempBig = [];
            this.quoteCenterAreaDataBig = [];
            this.quoteCenterAreaPassCardBig = [];

            quoteCenterInnovationDataTempBig = [];
            this.quoteCenterInnovationDataBig = [];
            this.areaQuotationType = [];
            this.innovationQuotationType = [];
            this.reloadlistAreaData();
            //this.reloadlistInnovationData();
            this.ios_app_down_link = result['ios_app_down_link'];
            this.android_app_down_link = result['android_app_down_link'];
            this.ios_app_down_qr_code_img_link = result['ios_app_down_qr_code_img_link'];
            this.android_app_down_qr_code_img_link = result['android_app_down_qr_code_img_link'];
            this.activityBannerImgUrl = result['activity_banner_img_url'];
        }
      });
    },
    changeQtttArea(label) {
        //要排序的字段 设置为空
        this.checkOrderColumnArea = '';
        //排序的类型 升序/降序/无 设置为空
        this.originalArea = '';
        //取消通证的选择
        this.isCheckdDrap = false;
        //设置当前unit类型为当前选择的
        this.quotationTypeValueArea = label;
        //切换unit的时候，data和tempdata重新取值，并排序等
        this.reloadlistAreaData(label);
    },
    changeQtttInnovation(label) {
        //要排序的字段 设置为空
        this.checkOrderColumnInnovation = '';
        //排序的类型 升序/降序/无 设置为空
        this.originalInnovation = '';
        //设置当前unit类型为当前选择的
        this.quotationTypeValueInnovation = label;
        //切换unit的时候，data和tempdata重新取值，并排序等
        this.reloadlistInnovationData(label);
    },
    checkMarketFavoriteArea(market_id){
        if(this.isNotLoggedIn){
          this.$message({
            message: this.$t('please_log_in_first'),
            type: 'warning'
          })
          return
        }
        this.postUrl('/www/home/switch_member_market_favorite', {
          lang:this.currentLang,
          market_id: market_id,
          type: 'main'
        }, true, false).then((response) => {
          if(response.data.code == 20000){
            //请求关注的列表
            Vue.setGlobalVariable({'main_trade_area_favorite_ids': response.data.data.list});
            Vue.setGlobalVariable({'main_trade_area_favorite_passcard': response.data.data.pass_card});
            this.reloadlistAreaData();
          }
        });
    },
    checkMarketFavoriteInnovation(market_id){
        if(this.isNotLoggedIn){
          this.$message({
            message: this.$t('please_log_in_first'),
            type: 'warning'
          })
          return
        }
        this.postUrl('/www/home/switch_member_market_favorite', {
          lang:this.currentLang,
          market_id: market_id,
          type: 'innovation'
        }, true, false).then((response) => {
          if(response.data.code == 20000){
            //请求关注的列表
            Vue.setGlobalVariable({'main_trade_innovation_favorite_ids': response.data.data.list});
            Vue.setGlobalVariable({'main_trade_innovation_favorite_passcard': response.data.data.pass_card});
            this.reloadlistInnovationData();
          }
        });
    }
  },
  mounted() {
    //请求关注的列表
    this.getMarketFavoriteList('main');
    this.getMarketFavoriteList('innovation');
    this.reloadData();
  },
  watch: {
      //仅仅是app.vue做完判断的时候回调
      currentJudgeIsLoggedIn(newVal) {
        if (newVal) {
         this.getMarketFavoriteList('main');
         this.getMarketFavoriteList('innovation');
         this.reloadData();
        }
      },
  },
  components: {
    HomeIndexWelcome,
    //HomeTradesTitle,
    HomeTradesMainMainArea,
    HomeTradesMainInnovationZone,
    HomeIndexMain,
    HomeIndexFeatures,
    HomeIndexDownload,
    HomeIndexRegister,
    NavFooter
  }
}
</script>
