import Vue from 'vue'
import i18n from '../i18n/i18n'
import store from '../vuex/store'

export default function (Vue) {
  Vue.mixin({
    watch: {
      //仅仅是app.vue做完判断的时候回调
      // currentJudgeIsLoggedIn(newVal) {
      //   if (newVal) {
      //    this.callbackMemberLoggedIn()
      //   }
      // },
      // currentJudgeIsNotLoggedIn(newVal) {
      //   if (newVal) {
      //    this.callbackMemberNotLoggedIn()
      //   }
      // }
    },
    computed: {
      isLoggedIn() {
        return !this.objIsEmpty(this.currentUserInfo)
      },
      isNotLoggedIn() {
        return !this.isLoggedIn
      }
    },
    methods: {
      OperationValidation(operation_type, k, v){
        this.getUrl('/www/user_center/get_sensitive_operation_validation').then((response) => {
          if(response.data.code == 20000){
            Vue.setGlobalVariable({
              'is_mobile_bound': response.data.sensitive_operation_validation_details.is_mobile_bound
            });
            Vue.setGlobalVariable({
              'is_email_bound': response.data.sensitive_operation_validation_details.is_email_bound
            });
            Vue.setGlobalVariable({
              'is_assets_password_bound': (response.data.sensitive_operation_validation_details.is_assets_password_bound && 
                this.$route.path != '/bind_phone' && this.$route.path != '/bind_email' && this.$route.path != '/google_auth' && 
                this.$route.path != '/edit_password' && this.$route.path != '/user_center')
            });
            Vue.setGlobalVariable({
              'is_otp_binded': response.data.sensitive_operation_validation_details.is_otp_bound
            });
          }
        });
        Vue.setGlobalVariable({
          'new_fund_password': k
        });
        Vue.setGlobalVariable({
          'new_fund_password_repeat': v
        });
        Vue.setGlobalVariable({'operation_validation_show': true});
        Vue.setGlobalVariable({'operation_type': operation_type});
      },

      submitBindPhoneUnbind(){
        this.postUrl('/www/user_center/unbind_mobile', {
            lang:this.currentLang,
         }).then((response) => {
            if(response.data.code == 20000){
              //重新加载用户数据
              this.getMemberInfo();
              this.$router.push({path:'/user_center'}).catch(data => {  })
            }
         });
      },

      UserProtocol(){
        Vue.setGlobalVariable({'user_protocol_show': true});
      },
      CheckboxSelect(){
        if(this.currentCheckboxSelect){
          Vue.setGlobalVariable({'checkbox_select': false});
        }else{
          Vue.setGlobalVariable({'checkbox_select': true});
        }
        
      },
      Agree(){
        if(!this.currentCheckboxSelect){
          this.$message({
            message: this.$t("please_tick_the_box_to_continue"),
            type: 'warning'
          })
          return
        }
        if(!this.currentUserInfo.real_name){
          this.$message({
            message: this.$t("please_complete_the_real_name_authentication_before_signing_the_agreement"),
            type: 'warning'
          })
          return
        }
        this.postUrl('/www/home/agree_protocol').then((response) => {
          if(response.data.code == 20000){
            Vue.setGlobalVariable({'user_protocol_show': false});
            this.getMemberInfo()
          }
        });
      },
      Close(){
        Vue.setGlobalVariable({'user_protocol_show': false});
      },
      objIsEmpty(obj) {
        for(var prop in obj) {
          if(obj.hasOwnProperty(prop)) {
            return false;
          }
        }
        return JSON.stringify(obj) === JSON.stringify({});
      },
      signOut() {
        this.postUrl('/cas/sign_out', {}, true, true).then((response) => {
          this.setUserInfoEmpty()
          this.$cookies.remove("st")
          this.unsubscribe()
          location.reload();
        }, (error) => {
          this.setUserInfoEmpty()
        })
      },
      clearSign() {
        this.postUrl('/cas/sign_out', {}, true, true).then((response) => {
          this.setUserInfoEmpty()
          this.$cookies.remove("st")
          this.unsubscribe()
        }, (error) => {
          this.setUserInfoEmpty()
        })
      },
      setUserInfoEmpty() {
        //需要退出
        Vue.setStorageVariable({'user_info':{}});
      },
      cookiesDomain() {
        cookies_domain = []
        temp_domain = window.location.hostname.split('.')
        let temp_count = 0
        while(true) {
          cookies_domain.push(temp_domain.pop())
          temp_count ++
          if (temp_domain.length === 0 || temp_count === 2) {
            break;
          }
        }
        cookies_domain = cookies_domain.reverse().join('.')
        if (cookies_domain.indexOf('localhost') < 0) {
          cookies_domain = '.' + cookies_domain
        }
        return cookies_domain
      },
      initProject() {
        store.dispatch('SET_GLOBAL_VARIABLE')
        store.dispatch('SET_STORAGE_VARIABLE')
        this.setLocale()
      },
      callbackMemberLoggedIn() {
        if (this.$options["loginStatus"]) {
          let config = this.$options.loginStatus
          if (config.userLoggedIn) {
            let func = config.userLoggedIn.bind(this)
            func()
          }
        }
      },
      callbackMemberNotLoggedIn() {
        if (this.$options["loginStatus"]) {
          let config = this.$options.loginStatus
          if (config.userNotLoggedIn) {
            let func = config.userNotLoggedIn.bind(this)
            func()
          }
        }
      },
      judgeIsLoggedIn() {
        return new Promise((resolve, reject)=>{
          if (this.$route.query.ticket) {
            this.ticketValidate().then(()=>{
              Vue.setGlobalVariable({judge_is_logged_in: true})
              resolve()
            })
          } else {
            this.cookieValidate().then(()=>{
              Vue.setGlobalVariable({judge_is_logged_in: true})
              resolve()
            })
          }
        })
      },
      setLocale() {
        if (this.$route.query.lang) {
          Vue.setStorageVariable({ lang: this.$route.query.lang })
          let query = Object.assign({}, this.$route.query);
          delete query.lang;
          this.$router.replace({ query });
        }
        i18n.locale = this.currentLang
      },
      ticketValidate(success, failure) {
        return new Promise((resolve, reject)=>{
          this.postUrl('/cas/service_st_ticket_validate', {
            ticket: this.$route.query.ticket,
            lang: this.currentLang
          }, true,true).then((response)=>{
            if (response.data.code === 20000) {
              this.$cookies.set("st", this.$route.query.ticket, response.data.expires, '/', this.cookiesDomain());
              // 有ticket先保存再更换url参数
              let query = Object.assign({}, this.$route.query)
              delete query.ticket;
              this.$router.replace({ query })
              this.getMemberInfo().then(()=>{
                resolve()
              })
            }else{
              this.setUserInfoEmpty()
              Vue.setGlobalVariable({judge_is_not_logged_in: true}) 
            }
          }, (error) => {
              this.setUserInfoEmpty()
          })
        })
      },
      cookieValidate(success, failure) {
        return new Promise((resolve, reject) => {
          this.postUrl('/cas/service_st_cookie_validate', {lang: this.currentLang}, true,true).then((response) => {
            if (response.data.code === 20000) {
              this.getMemberInfo().then(()=>{
                resolve()
              })
            }else{
              this.setUserInfoEmpty()
              Vue.setGlobalVariable({judge_is_not_logged_in: true})
            }
          }, (error) => {
            this.setUserInfoEmpty()
          })
        })
      },
      getMemberInfo(success, failure) {
        return new Promise((resolve, reject) => {
          this.postUrl('/help_center/get_member_info', {
            lang: this.currentLang
          }, true,true).then((response) => {
            if(response.data.code == 20000){
              Vue.setStorageVariable({'user_info': response.data.dataResult});
              Vue.setGlobalVariable({'have_new_message': response.data.dataResult["have_new_messages"]});
              Vue.setGlobalVariable({'is_agree_protocol': response.data.dataResult["is_agree_protocol"]});

              Vue.setGlobalVariable({
                'is_mobile_bound': response.data.dataResult["sensitive_operation_validation_details"]["is_mobile_bound"]
              });
              Vue.setGlobalVariable({
                'is_email_bound': response.data.dataResult["sensitive_operation_validation_details"]["is_email_bound"]
              });
              Vue.setGlobalVariable({
                'is_assets_password_bound': response.data.dataResult["sensitive_operation_validation_details"]["is_assets_password_bound"]
              });
              Vue.setGlobalVariable({
                'is_otp_binded': response.data.dataResult["sensitive_operation_validation_details"]["is_otp_binded"]
              });
              if(response.data.dataResult["protocol_show_for_user"] == 'selected'){
                if(response.data.dataResult["is_agree_protocol"] == null){
                  Vue.setGlobalVariable({'protocol_for_users': false});
                }else{
                  Vue.setGlobalVariable({'protocol_for_users': true});
                }
              }else if(response.data.dataResult["protocol_show_for_user"] == 'all'){
                Vue.setGlobalVariable({'protocol_for_users': true});
              }
              
              resolve()
            }else{
              this.setUserInfoEmpty()
            }
          }, (error) => {
            this.setUserInfoEmpty()
          })
        })
      },
      subscribeRefactoring(option) {
        parameter = option["channel"]
        if(option["market_id"]){
          parameter = parameter + '·' + option["market_id"]
        }
        if(option["lang"]){
          parameter = parameter + '·' + option["lang"]
        }
        if(option["token"]){
          parameter = parameter + '·' + option["token"]
        }
        return parameter
      },
      priceChangeRatio(data){
        return data.indexOf('-') >= 0
      }
    }
  })
}

