<template>
	<div class="header_text">
		<div class="t1" v-if='!bind_otp_binded'>
			{{$t("google_authenticator_is_a_dynamic_password_tool_that_works_like_SMS_dynamic_verification_a_dynamic_verification_code_is_generated_every_30s_after_binding_and_the_verification_code_can_be_used_for_security_verification_of_operations_such_as_login_withdrawal_and_modification_of_security_settings")}}.
		</div>
		<div class="t1" v-if="bind_otp_binded">
			{{$t('google_authenticator_is_set_for_your_security_it_is_highly_recommended_to_open_once_opened_you_can_use_the_coin_transfer_function_you_can_untie_and_then_re_bind_your_new_mobile_number')}}.
		</div>
	</div>
</template>
<style type="text/css" scoped>
	.header_text{
		margin: 0px auto;
    	color: #65698d;
    	font-size: 16px;
    	height: auto;
    	display: flex;
  		justify-content:space-between;
  		padding-left: 135px;
    	width: var(--page-content-width);
	    padding-top: 14px;
	    padding-bottom: 25px;
	}
	.t1{
		margin-left: -115px;
	}
</style>
<script type="text/javascript">
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    bind_otp_binded: Boolean
  }
}
</script>
