<template>
  <div class="pages-info-coins-index-listLeft">
    <div class="pages-info-coins-index-listTitle">{{$t('list_of_digital_assets')}}</div>
    <div style="background-color: var(--assets-new-list-bgcolor); padding-top: 18px;border-radius: 0px 0px 4px 4px;">
      <span class="ant-input-search ant-input-affix-wrapper">
        <input :placeholder="$t('search_pass')" class="ant-input" type="text" v-model="searchPassData" style="background-color: var(--home-list-col-input-bgcolor);" @keyup="inputSearch">
        <span class="ant-input-suffix">
          <i aria-label="图标: search" class="anticon anticon-search ant-select-arrow-icon" v-if="searchPassData == ''">
              <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
              </svg>
          </i>

           <i aria-label="图标: search" class="anticon anticon-search ant-select-arrow-icon" @click.stop="deleteDrap" v-if="searchPassData != ''">
              <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 0 1-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
              </svg>
          </i>
        </span>
      </span>
      <div class="pages-info-coins-index-listUl" >
        <div class="pages-info-coins-index-item" @click="changeSelectName(item.code.toUpperCase())" v-for="item in currencies" :class="[item.code.toUpperCase() === selectedName ? 'pages-info-coins-index-activeItem' : null ]">
          <span class="pages-info-coins-index-line" v-if="item.code.toUpperCase() === selectedName" style="height: 19.8px;"></span>
          {{item.code.toUpperCase()}}
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css" scoped>

</style>
<script>
var currencieOldDatas = []
export default {
  data() {
    return {
      selectedName: '',
      currencies: [],
      searchPassData: ''
    }
  },
  mounted() {
    //列表
    this.getUrl('/www/currencies_list').then((response)=>{
      //选择第一个
      this.currencies = response.data.currencies
      currencieOldDatas = response.data.currencies
      this.changeSelectName(this.currencies[0].code.toUpperCase())
    })
  },
  methods: {
    deleteDrap(){
      this.searchPassData = '';
      this.inputSearch();
    },
    inputSearch(){
      this.currencies = Object.assign([],currencieOldDatas);
      this.filterByName();
    },
    changeSelectName(name) {
      this.selectedName = name
      this.$emit('changed', name)
    },
    filterByName(){
      var currencieTemps = [];
      for (var i = 0; i < this.currencies.length; i++) {
        code = this.currencies[i].code;
        if(code.indexOf(this.searchPassData.toLowerCase()) != '-1'){
            //不存在
            currencieTemps.push(this.currencies[i])
        }
      }
      //将值改为过滤后的
      this.currencies = currencieTemps;
    }
  }
}
</script>
