import Vue from 'vue'

export default function (Vue) {
    Vue.mixin({
        methods: {
            checkMemberInfo(){
                //this.postUrl('/help_center/get_member_info').then((response) => {
                //    //存入用户信息
                //    if(response.data.code == 20000){
                //      Vue.setStorageVariable({'user_info':JSON.stringify(response.data.dataResult)});
                //    }else{
                //      Vue.setStorageVariable({'user_info':[]});
                //      if(this.$route.path !='/'){
                //        //不是首页的界面 需要登录
                //        location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
                //      }
                //    }
                //}, (error) => {
                //    //需要退出
                //    Vue.setStorageVariable({'user_info':[]});
                //    if(this.$route.path !='/'){
                //      //不是首页的界面 需要登录
                //      location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
                //    }
                //})
            }
        }
    })
}
