/* eslint-disable */
import {
    SET_STORAGE_VARIABLE,
  } from '../mutation_types'

  const state = {
    theme: 'light', //主题颜色
    kline_interval: '5min', //k线的interval
    lang: 'zh', //国际化
    chart_type: 'kline', //当前显示的表类型
    fixed_ask_order_book: true, //是否order book卖单置底
    hidden_assets: false, //是否隐藏资产信息
    market_list_third_column_type: 'change_ratio', //change_ratio(涨跌幅), total_volumn(成交量), total_amount(成交额)
    order_book_third_column_type: 'total_volume',  //total_volume(累计数量), amount(金额), total_amount(累计金额)
    order_book_bar_chart_type: "volume", //volume数量, total_volume(累计数量), amount(金额), total_amount(累计金额)
    ident_auth: "chinese",
    user_info:{},
    rwd_assets_type: 'recharge',
    page_count: 50
  }

  const mutations = {
    //设置用户个人信息
    [SET_STORAGE_VARIABLE] (state, data) {
      try {
        Object.keys(data).forEach(function(key){
            state[key] = data[key]
        })
      } catch (err) {
        console.log(err)
      }
    },
  }


  const actions = {
    [SET_STORAGE_VARIABLE] ({ commit, state }, data) {
      //保存信息
      if (data !== undefined) {
        let localStorage = window.localStorage
        let old_data = JSON.parse(localStorage.getItem('STORAGE_VARIABLE'))
        if (!old_data) {
          localStorage.setItem('STORAGE_VARIABLE', JSON.stringify(data))
        } else {
          Object.keys(data).forEach(function(key){
              old_data[key] = data[key]
          })
          localStorage.setItem('STORAGE_VARIABLE', JSON.stringify(old_data))
        }
        commit(SET_STORAGE_VARIABLE, data)
      } else {
        // 加载localstorage数据
        if (localStorage.getItem('STORAGE_VARIABLE')) {
          data = JSON.parse(localStorage.getItem('STORAGE_VARIABLE'))
          commit(SET_STORAGE_VARIABLE, data)
        } else {
          console.info('global variable no data')
        }
      }
    }
  }

  export default {
    state,
    mutations,
    actions,
  }
