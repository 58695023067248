<template>
  <div class="pages-home-trades-main outer-most" style="">
    <div class="ant-table-wrapper pages-home-trades-table" v-if="currency_info.funding_currency_info">
      <div class="first-built-in">
        {{$t('you_are_working_for')}} {{currency_info.currency_code}} {{$t('fight_new')}}
      </div>
      <div class="margin-size-20" style="color: var(--main-color);">{{$t('number_of_calls_you_have_participated_in')}}：<span style="color: #006ae3;">{{currency_info.already_participate}} {{currency_info.funding_currency_info.funding_currency_code}} </span> </div>
      <div class="margin-size-20" style="color: var(--main-color);">{{$t('minimum_number_of_new_calls')}}：<span style="color: #006ae3;">{{currency_info.min_funding_amount}} {{currency_info.funding_currency_info.funding_currency_code}} </span></div>
      <div class="margin-size-20" style="color: var(--main-color);">{{$t('maximum_number_of_new_calls')}}：<span style="color: #006ae3;">{{currency_info.max_funding_amount}} {{currency_info.funding_currency_info.funding_currency_code}} </span></div>
      <div class="margin-size-30">
        <div class="float-left">
          <img class="img" :src="currency_info.funding_currency_info.icon_url">
        </div>
        <div class="float-left" style="margin: 5px 10px; color: var(--main-color);">{{$t('the_number_of')}} {{currency_info.funding_currency_info.funding_currency_code}} {{$t('you_currently_have')}}：{{currency_info.funding_currency_info.funding_currency_amount}}</div>
      </div>
      
      <div class="float-left" style="clear: left; margin-top: 30px;">
        <span style="color: var(--main-color);">{{$t('you_want_to_invest')}}:</span>
        <input class="new-input" type="text" v-model="amount"> <span style="color: var(--main-color);" type="number">{{currency_info.funding_currency_info.funding_currency_code}}</span>
        <span style="margin-left: 20px; color: red;">{{min_max_amount}}</span>
        <span style="margin-left: 20px; color: red;">{{not_number}}</span>
      </div>

      <div class="float-left" style="width: 30%; clear: left; padding-top: 20px;">
        <button tabindex="-1" type="button" style="border: 0px; cursor: pointer; width: 40%; margin-top: 5px; height: 32px; background-color: rgb(0, 108, 219); border-radius: 4px; color: rgb(255, 255, 255);" @click="submitFightNew(currency_info.sale_promotion_id)">{{$t('confirm')}}</button>
      </div>
    </div>
  </div>
</template>
<style type="text/css" scoped>
  .outer-most{
    padding: 30px; 
    margin-top: 40px;
    height: auto; 
    /*margin: 0px auto; */
    border-radius: 4px; 
    width: var(--page-content-width); 
    background-color: var(--assets-new-box-bg);
  }
  .first-built-in{
    background-color: var(--sale-new-header);
    border: var(--sale-new-header-border);
    color: var(--main-color);
    width: 40%;
    height: 49px;
    font-size: 14px;
    padding: 13px 30px
  }
  .new-input{
    height: 30px;
    margin-left: 10px;
    background-color: var(--sale-new-input);
    border: var(--sale-new-input-border);
    border-radius: 5px;
    text-align: center;
    color: var(--main-color);
  }
  .img{
    width: 30px;
    height: 30px;
  }
  .margin-size-20{
    margin-top: 20px;
  }
  .margin-size-30{
    margin-top: 30px;
  }
  .float-left{
    float: left;
  }
  .float-right{
    float: right;
  }
</style>
<script type="text/javascript">
import Vue from 'vue'
export default {
  data () {
    return {
      amount: "",
      currency_info: "",
      min_max_amount: '',
      not_number: ''
    }
  },
  watch: {
  },
  created(){
  },
  computed: {

  },
  mounted() {
    this.fundingCurrencyInfo(this.$route.params.id)
  },
  methods: {
    fundingCurrencyInfo(id){
      this.getUrl('/www/sales_promotion/funding_currency_info?id=' + id).then((response)=>{
        if (response.data.code ===  20000) {
          this.currency_info = response.data.currency_info;
          // this.myEchart(this.sale_promotion);
        }
      })
    },
    submitFightNew: function(id){
      amount = this.amount
      num = /^\+?[1-9][0-9]*$/
      float = /^[+-]?[1-9]?[0-9]*\.[0-9]*$/
      if(!num.test(amount) && !float.test(amount)){
        return this.not_number = "请输入有效数字"
      }
      if(parseFloat(this.currency_info.min_funding_amount) <= parseFloat(amount)  &&  parseFloat(amount) <= parseFloat(this.currency_info.max_funding_amount)){
        this.postUrl('/www/user_center/reset_asset_password_locked', {
          lang: this.currentLang
        }).then((response) => {
          if(response.data.code == 20000){
            this.postUrl('/www/sales_promotion/create_sale_promotion', {
              id: id,
              amount: this.amount
            }).then((response) => {
              this.min_max_amount = ''
              if(response.data.code == 20000){
                //显示当前打新币种信息
                this.$router.push({ path: ('/successful_sales_promotion/' + id)});
              }
            },(error) => {
              console.log('失败')
            });
          }
        });
      }else{
        this.min_max_amount = this.$t('greater_than_or_equal_to') + ' ' + this.currency_info.min_funding_amount + ' ' + this.$t('less_than_or_equal_to') + ' ' + this.currency_info.max_funding_amount
      }
    },
  }
}

</script>
