<template>
    <div class="pages-assets-balances-navigation-wrapper">
        <page-assets-header :faceValue="faceValue" :currency="currency"
                            :withdrawAvailableBalance="withdraw_available_balance"
                            :withdrawLockedBalance="withdraw_locked_balance"
                            :withdrawsFiatSymbol="withdraws_fiat_symbol" :withdrawsFiatAmount="withdraws_fiat_amount"
                            :withdrawsConvertCurrency="withdraws_convert_currency"
                            :withdrawTotalAmount="withdraw_total_amount" :transferTotalAmount="transfer_total_amount"/>
        <rwd-balc-navig-tabs @change-type="changeType" :faceValue="faceValue" :language="language"
                             :currency="currency"/>
        <assets-balc-navig-ctn-recharge v-if="currency.is_depositable" :faceValue="faceValue" :language="language"
                                        :is_have_payment_address="is_have_payment_address"
                                        :erc20_or_omni="erc20_or_omni" :paymentAddress="paymentAddress"
                                        :currency="currency" :depositsRecharge="depositsRecharge"
                                        :available_balance="withdraw_available_balance"
                                        :locked_balance="withdraw_locked_balance" @generate-address="generateAddress"/>
        <assets-balc-navig-ctn-cawi v-if="currency.is_withdrawable" :faceValue="faceValue" :language="language"
                                    :currency="currency" :withdraws="withdraws"
                                    :available_balance="withdraw_available_balance" :withdraw_comment="withdraw_comment"
                                    :isShowWithdrawComment="isShowWithdrawComment"
                                    :locked_balance="withdraw_locked_balance" @reload-data="reloadData"/>
        <assets-balc-navig-ctn-transfer v-if="currency.is_transfered" :faceValue="faceValue" :language="language"
                                        :currency="currency" :internalTransfers="internalTransfers"
                                        :available_balance="transfer_available_balance"
                                        :locked_balance="transfer_locked_balance"
                                        @check-transfer-data="checkTransferData" @reload-data="reloadData"/>
        <operation-validation  @reload-data="reloadData"/>
    </div>
</template>
<script>
    import Vue from 'vue'
    import PageAssetsHeader from '../Assets/PageAssetsHeader'
    import RwdBalcNavigTabs from '../Assets/RwdBalcNavigTabs'
    import AssetsBalcNavigCtnAssets from '../Assets/AssetsBalcNavigCtnAssets'
    import AssetsBalcNavigCtnRecharge from '../Assets/AssetsBalcNavigCtnRecharge'
    import AssetsBalcNavigCtnCawi from '../Assets/AssetsBalcNavigCtnCawi'
    import AssetsBalcNavigCtnTransfer from '../Assets/AssetsBalcNavigCtnTransfer'
    import OperationValidation from '../OperationValidation'

    export default {
        data() {
            return {
                is_have_payment_address: false,
                is_have_erc_20_payment_address: false,
                is_have_omni_payment_address: false,
                erc20_or_omni: 'erc_20',
                paymentAddress: "",
                currency: {},
                depositsRecharge: [],
                withdraws: [],
                internalTransfers: [],
                withdraw_available_balance: "",
                withdraw_total_amount: "",
                withdraw_locked_balance: "",
                transfer_available_balance: "",
                transfer_locked_balance: "",
                transfer_total_amount: "",
                seach_date_start: '',
                seach_date_end: '',
                transfer_initiator: '',
                transferred_user: '',
                withdraw_comment: "",
                withdraws_fiat_symbol: '',
                withdraws_fiat_amount: '',
                withdraws_convert_currency: '',
                isShowWithdrawComment: false
            }
        },
        created() {

        },
        computed: {
            faceValue: function () {
                return this.currentRwdAssetsType;
            },
            language: function () {
                return this.currentLang;
            }
        },
        mounted() {
            var code = this.$route.params.code;
            if (this.$route.params.code.indexOf('-') != '-1') {
                //trading跳转过来的
                if (code.split("-")[1] == 'recharge' || code.split("-")[1] == 'withdrawal' || code.split("-")[1] == 'transfer') {
                    //指定了界面
                    Vue.setStorageVariable({rwd_assets_type: code.split("-")[1]})
                }
                code = code.split("-")[0]
                this.$router.push({name: 'rwd_assets', params: {code: code}})
            }
            if (this.currentJudgeIsLoggedIn) {
                this.reloadData()
            }
        },
        watch: {
            //仅仅是app.vue做完判断的时候回调
            currentJudgeIsLoggedIn(newVal) {
                if (newVal) {
                    this.reloadData()
                }
            },
        },
        components: {
            PageAssetsHeader,
            RwdBalcNavigTabs,
            AssetsBalcNavigCtnAssets,
            AssetsBalcNavigCtnRecharge,
            AssetsBalcNavigCtnCawi,
            AssetsBalcNavigCtnTransfer,
            OperationValidation
        },
        methods: {
            checkTransferData(seach_date, transfer_initiator, transferred_user) {
                this.reloadData(seach_date, transfer_initiator, transferred_user);
            },
            p(s) {
                return s < 10 ? '0' + s : s
            },
            getYmdDate(date) {
                resDate = date.getFullYear() + '-' + this.p((date.getMonth() + 1)) + '-' + this.p(date.getDate())
                resTime = this.p(date.getHours()) + ':' + this.p(date.getMinutes()) + ':' + this.p(date.getSeconds())
                return resDate + ' ' + resTime;
            },
            reloadData(seach_date = '', transfer_initiator = '', transferred_user = '') {
                if (seach_date != '' && seach_date != null) {
                    this.seach_date_start = this.getYmdDate(new Date(seach_date[0]))
                    this.seach_date_end = this.getYmdDate(new Date(seach_date[1]))
                } else {
                    this.seach_date_start = '';
                    this.seach_date_end = '';
                }
                this.transfer_initiator = transfer_initiator;
                this.transferred_user = transferred_user;
                this.getUrl('/www/assets/asset_recharge_withdrawal_transfer', {
                    lang: this.currentLang,
                    code: this.$route.params.code,
                    from_email: this.transfer_initiator,
                    to_email: this.transferred_user,
                    from_date: this.seach_date_start,
                    to_date: this.seach_date_end,
                    usdt_address_type: this.erc20_or_omni
                }).then((response) => {
                    if (response.data.code == 20000) {
                        this.is_have_payment_address = response.data.data.is_have_payment_address
                        if (response.data.data.payment_address != null) {
                            this.paymentAddress = response.data.data.payment_address.address
                        }
                        this.currency = response.data.data.currency
                        this.depositsRecharge = response.data.data.deposits_recharge
                        this.withdraws = response.data.data.withdraws
                        this.internalTransfers = response.data.data.internal_transfers
                        this.withdraw_available_balance = response.data.data.account_withdraw.balance
                        this.withdraw_total_amount = response.data.data.account_withdraw.total_amount
                        this.withdraw_locked_balance = response.data.data.account_withdraw.locked
                        this.transfer_available_balance = response.data.data.account_transfer.balance
                        this.transfer_locked_balance = response.data.data.account_transfer.locked
                        this.transfer_total_amount = response.data.data.account_transfer.total_amount
                        this.withdraws_fiat_symbol = response.data.data.account_withdraw.fiat_symbol
                        this.withdraws_fiat_amount = response.data.data.account_withdraw.fiat_amount
                        this.withdraw_comment = response.data.data.account_withdraw.withdraw_comment
                        this.withdraws_convert_currency = response.data.data.account_withdraw.convert_currency
                        if (this.withdraw_comment.length != 0) {
                            this.isShowWithdrawComment = true
                        }
                    }
                });
            },
            generateAddress(type) {
                this.erc20_or_omni = type
                this.reloadData();
                if(!this.is_have_payment_address){
                  this.postUrl('/www/assets/generate_new_address', {
                      lang: this.currentLang,
                      code: this.$route.params.code,
                      usdt_address_type: type
                  }).then((response) => {
                      if (response.data.code == 20000) {
                          this.reloadData();
                      }
                  });
                }
            },
            changeType(label) {
                Vue.setStorageVariable({'rwd_assets_type': label});
                if(label == 'recharge'){
                    this.generateAddress(this.erc20_or_omni)
                }
            }
        }
    }
</script>
