<template>
    <div class="pages-home-index-main" style="background-color: #1e1b26;">
   		<div class="main_index">
        <div class=main_index_picture_text>{{main_index_picture_text}}</div>
   			<div class="main_index_text">
   				<div>{{$t("sync_on_the_app_side")}}</div>
   				<div style="margin-top: 16px; width: 502px; word-wrap: break-word;">
          {{$t("quick_transactions_anytime_anywhere")}}</div>
   			</div>
   		</div>
    </div>
</template>
<style type="text/css" scoped>
	.main_index{
		display: flex;
		flex-direction: column;
    align-items: center;
    height: 400px;
    color: #ffffff;
    font-size: 25px;
    margin-right: 330px;
	}
  .main_index_picture_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 50px;
    width: 510px;
    font-size: 60px;
    font-weight: 700;
  }
  .main_index_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 10px;
    width: 510px;
  }
</style>
<script type="text/javascript">
import { main_index_picture_text } from '../../application'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String
  },
  data() {
    return {
      main_index_picture_text: main_index_picture_text
    }
  },
}
</script>
