<template>
<div style="box-sizing: border-box; background-color: var(--sales-promotion_show-bgcolor); min-width: var(--page-content-width);">
     <sales-promotion-show/>
     <nav-footer  :language = "language"/>
</div>
</template>
<style lang="scss">
@import url('../css/layouts.956413c0.chunk.scss');
@import url('../css/umi.0d36cc22.scss');
</style>
<script>
import SalesPromotionShow from '../components/SalesPromotion/SalesPromotionShow'
import NavFooter from '../components/NavFooter'
export default {
  created(){
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  components: {
    SalesPromotionShow,
    NavFooter
  }
}
</script>

