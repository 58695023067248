import Vue from 'vue'
export default {
  methods: {
    serviceStValidate() {
      return new Promise((logged_in, reject)=>{
        if (this.$route.query.ticket) {
          this.postUrl('/api/v2/cas/service_st_ticket_validate', {
            ticket: this.$route.query.ticket
          }).then((response)=>{
            if (response.data.code === 20000) {
              let query = Object.assign({}, this.$route.query);
              delete query.ticket;
              this.$router.replace({ query });
              //已登录
              logged_in()
            }
          })
        } else if (this.$route.query.auth_result === 'false') {
          this.$cookies.set("auto_auth", "pause", 1 ,"/");
          let query = Object.assign({}, this.$route.query);
          delete query.auth_result;
          this.$router.replace({ query });
          //未登录
          reject('not_logged_in')
        } else {
          this.postUrl('/api/v2/cas/service_st_cookie_validate').then((response) => {
            console.info(response)
            if (response.data.code === 20004) {
              let temp_cookies_value = this.$cookies.get("auto_auth")
              console.info(temp_cookies_value)
              if (!temp_cookies_value) {
                window.location.href = Vue.getValueByKey('cas_path') + "?service="+ window.location.href.split(',')[0] +"&auto_auth=true"
              } else {
                //未登录
                reject('not_logged_in')
              }
            } else if (response.data.code === 40002) {
              //超时
              reject('timeout')
            } else if (response.data.code === 20000) {
              //登录成功
              logged_in()
            } else {
              console.log('未识别的code = ' + response.data.code)
            }
          }, (error) => {
          })
        }
      })
    }
  }
}
