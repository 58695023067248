<template>
<section class="pages-ucenter-commonform-content">
    <form class="ant-form ant-form-vertical ant-form-hide-required-mark" v-if="!bind_otp_binded">
        <section class="pages-ucenter-google-auth-common-item">
            <h4 class="pages-ucenter-google-auth-common-step">
                <span>1</span>
                {{$t("download_google_authenticator_app")}}
            </h4>
            <p class="pages-ucenter-google-auth-common-paragraph">
                iOS {{$t("user_login")}} App Store {{$t("search")}} 'Authenticator' {{$t("download")}}.<br>
                Android {{$t("user_login")}} App Store {{$t("or_search_using_a_mobile_browser")}} 'Google Authenticator‘ {{$t("download")}}.<br>
                {{$t("when")}} Android {{$t("users_cannot_search")}} ’Google Authenticator‘ ,{{$t("please_search_for_authenticator_from_the_tencent_app")}}.
            </p>
        </section>
        <section class="pages-ucenter-google-auth-common-item">
            <h4 class="pages-ucenter-google-auth-common-step">
                <span>2</span>
                {{$t("add_a_key_and_back_up_in_google_authenticator")}}
            </h4>
            <p class="pages-ucenter-google-auth-common-paragraph">
                {{$t("open_google_authenticator_scan_the_qr_code_below_or_manually_enter_the_following_key_to_add_a_verification_token")}}.<br>
                <span class="pages-ucenter-google-auth-common-red">{{$t("the_key_is_used_to_retrieve_the_google_authenticator_when_the_phone_is_replaced_or_lost_be_sure_to_save_the_following_key_backup_before_binding")}}.</span>
            </p>
            <div class="pages-ucenter-google-auth-open-qrcode">
                <div ref="qrAuthCodeDiv" style="width: 165px; height: 165px; padding: 8px; background-color: #fffffb">

                </div>
                <div style="width: 400px;margin-left: 30px;">
                    <p class="pages-ucenter-google-auth-common-paragraph">{{$t("key")}}</p>
                    <p class="pages-ucenter-google-auth-common-paragraph" style="width:400px;">
                        <span>{{otp_secret}}</span>
                        <a class="pages-ucenter-google-auth-common-copy" :data-clipboard-text="otp_secret" @click="copyMiyao" id="copy_text" style="color: #0397e3" onselectstart="return false;">{{$t("copy")}}</a>
                    </p>
                </div>
            </div>
        </section>
        <!-- <section class="pages-ucenter-google-auth-common-item">
            <h4 class="pages-ucenter-google-auth-common-step">
                <span>3</span>
                {{$t("enter_your_login_password")}}
            </h4>
            <div class="ant-row ant-form-item"  :class="[loginPwdErrorClass ? 'ant-form-item-with-help' : null ]" >
                <div class="ant-col ant-form-item-label">
                    <label for="password" class="ant-form-item-required ant-form-item-no-colon" :title="$t('login_password')">{{$t("login_password")}}</label>
                </div>
                <div class="ant-col ant-form-item-control-wrapper">
                    <div class="ant-form-item-control"  :class="[loginPwdErrorClass ? 'has-feedback has-error' : null ]">
                        <span class="ant-form-item-children">
                            <input type="password" id="password" class="ant-input ant-input-lg" value="" v-model="loginPwd"  @keyup="inputLoginPwd()"  maxlength="32">
                        </span>
                        <div class="ant-form-explain" v-if="isShowErrorLoginPwdDiv">{{loginPwdErrorMsg}}</div>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="pages-ucenter-google-auth-common-item">
            <h4 class="pages-ucenter-google-auth-common-step">
                <span>4</span>
                {{$t("enter_the_6_digit_verification_code_in_google_authenticator")}}
            </h4>
            <div class="ant-row ant-form-item" :class="[GoogleCodeErrorClass ? 'ant-form-item-with-help' : null ]">
                <div class="ant-col ant-form-item-label">
                    <label for="googleCaptcha" class="ant-form-item-required ant-form-item-no-colon" :title="$t('google_verification_code')">{{$t("google_verification_code")}}</label>
                </div>
                <div class="ant-col ant-form-item-control-wrapper">
                    <div class="ant-form-item-control"  :class="[GoogleCodeErrorClass ? 'has-feedback has-error' : null ]">
                        <span class="ant-form-item-children">
                            <input type="text" id="googleCaptcha" class="ant-input ant-input-lg" value="" v-model="GoogleCode" @keyup="inputGoogleCode()" maxlength="10">
                        </span>
                        <div class="ant-form-explain" v-if="isShowErrorGoogleCodeDiv">{{GoogleCodeErrorMsg}}</div>
                    </div>
                </div>
            </div>
        </section>
        <div class="pages-ucenter-commonform-handle">
            <button type="button" class="ant-btn ant-btn-primary" style="width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);" :disabled="buttonIsDisabled" :title="$t('confirm_binding')"  @click="submitGoogleAuthBind" >
              <span>{{$t("confirm_binding")}}</span>
            </button>
            <router-link class="pages-ucenter-commonform-back" to="/user_center">{{$t("return")}}</router-link>
        </div>
    </form>

    <form class="ant-form ant-form-vertical ant-form-hide-required-mark" v-if="bind_otp_binded">
        <!-- <section class="pages-ucenter-google-auth-common-item">
            <div class="ant-row ant-form-item" :class="[GoogleCodeErrorClass ? 'ant-form-item-with-help' : null ]">
                <div class="ant-col ant-form-item-label">
                    <label for="googleCaptcha" class="ant-form-item-required ant-form-item-no-colon" :title="$t('please_enter_the_number_displayed_by_google_authenticator')">{{$t("please_enter_the_number_displayed_by_google_authenticator")}}</label>
                </div>
                <div class="ant-col ant-form-item-control-wrapper">
                    <div class="ant-form-item-control"  :class="[GoogleCodeErrorClass ? 'has-feedback has-error' : null ]">
                        <span class="ant-form-item-children">
                            <input type="text" id="googleCaptcha" class="ant-input ant-input-lg" value="" v-model="GoogleCode" @keyup="inputGoogleCode()" placeholder="e.g. 123456" style="width: 476px;height: 46px;" maxlength="10">
                        </span>
                        <div class="ant-form-explain" v-if="isShowErrorGoogleCodeDiv">{{GoogleCodeErrorMsg}}</div>
                    </div>
                </div>
            </div>
        </section> -->
        <div class="pages-ucenter-commonform-handle">
            <!-- <button type="button" class="ant-btn ant-btn-primary" :disabled="unButtonIsDisabled" style="width: 130px;height: 46px;border-radius: 6px;background: -webkit-linear-gradient(left, rgb(118, 85, 233), rgb(79, 141, 233));" :title="$t('unbind')" @click="submitGoogleAuthUnBind" > -->
            <button type="button" class="ant-btn ant-btn-primary" style="width: 130px;height: 46px;border-radius: 6px;background: -webkit-linear-gradient(left, rgb(118, 85, 233), rgb(79, 141, 233));" :title="$t('unbind')" @click="OperationValidation('unbund_google_auth')" >
                <span>{{$t("unbind")}}</span>
            </button>
            <router-link class="pages-ucenter-commonform-back" to="/user_center">{{$t("return")}}</router-link>
        </div>
    </form>
</section>
</template>
<script type="text/javascript">
import Clipboard from 'clipboard';
import QRCode from 'qrcodejs2';
import Vue from 'vue'
export default {
  data() {
      return {
        loginPwd:'',
        loginPwdErrorMsg:'',
        loginPwdErrorClass:false,
        isShowErrorLoginPwdDiv:false,

        MsmCode:'',
        MsmCodeErrorMsg:'',
        MsmCodeErrorClass:false,
        isShowErrorMsmCodeDiv:false,

        GoogleCode:'',
        GoogleCodeErrorMsg:'',
        GoogleCodeErrorClass:false,
        isShowErrorGoogleCodeDiv:false,

        //phoneMsmButton:"",
        phoneMsmIsAble:false,
        phoneMsmCd:60
      }
  },
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    bind_otp_binded: Boolean,
    otp_url: String
  },
  mounted: function () {
    if(!this.bind_otp_binded && this.otp_url != undefined){
        this.bindQRCode();
    }
  },
  watch: {
    otp_url(newVal,oldval) {
        // 调用绘制二维码
        if(newVal != oldval && !this.bind_otp_binded && newVal != undefined){
            this.bindQRCode();
        }
    }
  },
  computed: {
    otp_secret: function () {
        if(this.currentJudgeIsLoggedIn){
            return this.currentUserInfo.otp_secret
        }else{
            return false;
        }
    },
    phoneMsmButton: function () {
         if(this.phoneMsmCd > 1 && this.phoneMsmIsAble == true){
             return this.$t('click_to_get')+"("+(this.phoneMsmCd )+")";
         }else{
             //重置
             return this.$t('click_to_get');
         }
    },
    unButtonIsDisabled: function () {
        if(!this.GoogleCodeErrorClass && !this.isShowErrorGoogleCodeDiv && this.GoogleCode != ''){
            return false;
        }else{
            return true;
        }
    },
    buttonIsDisabled: function () {
        if(!this.GoogleCodeErrorClass && !this.isShowErrorGoogleCodeDiv && this.GoogleCode != ''){
            return false;
        }else{
            return true;
        }
    }
  },
  methods: {
    submitGoogleAuthUnBind: function () {
        this.postUrl('/www/user_center/disable_google_auth', {
            lang:this.currentLang,
            // login_password: this.loginPwd,
            otp_code: this.GoogleCode
        }).then((response) => {
            if(response.data.code == 20000){
              this.getMemberInfo();
              this.$router.push({path:'/user_center'}).catch(data => {  })
            }
        });
    },
    submitGoogleAuthBind: function () {
        this.postUrl('/www/user_center/valid_google_auth', {
            lang:this.currentLang,
            // login_password: this.loginPwd,
            otp_code: this.GoogleCode
        }).then((response) => {
            if(response.data.code == 20000){
              this.OperationValidation('bund_google_auth')
              // this.getMemberInfo();
              // this.$router.push({path:'/user_center'}).catch(data => {  })
            }
        });
    },
    bindQRCode: function () {
        new QRCode(this.$refs.qrAuthCodeDiv, {
          text: this.otp_url,
          width: 150,
          height: 150,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        })
    },
    inputGoogleCode: function () {
        this.GoogleCode = this.GoogleCode.replace(/[\u4e00-\u9fa5]/ig,'');
        if (this.GoogleCode == '') {
            //不合法 不可为空
            this.GoogleCodeErrorMsg = this.$t('please_enter_google_verification_code');
            this.GoogleCodeErrorClass = true;
            this.isShowErrorGoogleCodeDiv = true;
        }else if(this.GoogleCode.length != 6){
            //不合法 格式错误
            this.GoogleCodeErrorMsg = this.$t('google_verification_code_format_is_incorrect');
            this.GoogleCodeErrorClass = true;
            this.isShowErrorGoogleCodeDiv = true;
        }else{
            //正确
            this.GoogleCodeErrorClass = false;
            this.isShowErrorGoogleCodeDiv = false;
        }
    },
    inputMsmCode: function () {
        this.MsmCode = this.MsmCode.replace(/[^0-9]/g,'');
        if (this.MsmCode == '') {
            //不合法 不可为空
            this.MsmCodeErrorMsg = this.$t('please_enter_sms_verification_code');
            this.MsmCodeErrorClass = true;
            this.isShowErrorMsmCodeDiv = true;
        }else if(this.MsmCode.length != 6){
            //不合法 格式错误
            this.MsmCodeErrorMsg = this.$t('sms_verificationcode_format_is_incorrect');
            this.MsmCodeErrorClass = true;
            this.isShowErrorMsmCodeDiv = true;
        }else{
            //正确
            this.MsmCodeErrorClass = false;
            this.isShowErrorMsmCodeDiv = false;
        }
    },
    phoneMsmSend: function () {
　　　　this.timer = setInterval( () => {
          if(this.phoneMsmCd > 1){
             this.phoneMsmIsAble = true;
             this.phoneMsmCd = this.phoneMsmCd - 1;
         }else{
             //重置
             this.phoneMsmIsAble = false;
             this.phoneMsmCd = 60;
             clearInterval(this.timer)
         }
　　　　}, 1000);
　　},
    inputLoginPwd: function () {
        this.loginPwd = this.loginPwd.replace(/[\u4e00-\u9fa5]/ig,'');
        let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,32}$/;
        if (this.loginPwd == '') {
            //不合法 不可为空
            this.loginPwdErrorMsg = this.$t('please_enter_your_password');
            this.loginPwdErrorClass = true;
            this.isShowErrorLoginPwdDiv = true;
        }else if(this.loginPwd.length < 8 || this.loginPwd.length > 32){
            //不合法 格式错误
            this.loginPwdErrorMsg = this.$t('please_enter_an_8_32_password');
            this.loginPwdErrorClass = true;
            this.isShowErrorLoginPwdDiv = true;
        }else if(!reg.test(this.loginPwd)){
            this.loginPwdErrorMsg = this.$t('password_must_contain_letters_and_numbers');
            this.loginPwdErrorClass = true;
            this.isShowErrorLoginPwdDiv = true;
        }else{
            //正确
            this.loginPwdErrorClass = false;
            this.isShowErrorLoginPwdDiv = false;
        }
    },
    copyMiyao: function () {
        var _this = this;
        var clipboard = new Clipboard('#copy_text');
        clipboard.on('success', e => {
            // 释放内存
            this.$message({
                message: this.$t('replication_success'),
                type: 'success',
            })
            clipboard.destroy()
        })
        clipboard.on('error', e => {
            // 不支持复制
            // 释放内存
            this.$message({
                message: this.$t('the_browser_does_not_support_automatic_copying'),
                type: 'warning',
            })
            clipboard.destroy()
        })
    }
  },
  components: {

  }
}
</script>
