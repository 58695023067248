<template>
  <div style="box-sizing: border-box;">
    <page-home/>
  </div>
</template>
<style lang="scss">
@import url('../css/p__home.2518f844.chunk.scss');
</style>
<script>
import PageHome from '../components/Home/PageHome'
import pako from 'pako'
import Vue from 'vue'
export default {
  created(){
  },
  mounted() {

  },
  methods: {

  },
  components: {
    PageHome
  }
}
</script>

