<template>
	<div style="box-sizing: border-box; background-color: var(--my-assets-bgcolor);min-width: var(--page-content-width);">
		<div class="pages-assets-balances-navigation-container isShow" style="min-height: 311px; width: 85%">
	    <div class="pages-assets-balances-asset-account" style="margin-top: 10px;">
				<div style="margin-left: 22px; padding-top: 20px;" class="tabShow">
          <span :class="currentType == 'AllMessage' ? 'active' : '' " style="border:#E4EDF8 1px solid; padding:5px 15px 5px 15px;cursor:pointer;" @click="getMessage('AllMessage')">{{$t("AllMessage")}}</span>
          <span :class="currentType == 'UnreadMessage' ? 'active' : '' " style="border:#E4EDF8 1px solid; padding:5px 15px 5px 15px;cursor:pointer;" @click="getMessage('UnreadMessage')">{{$t("UnreadMessage")}}</span>
          <span :class="currentType == 'ReadMessage' ? 'active' : '' " style="border:#E4EDF8 1px solid; padding:5px 15px 5px 15px;cursor:pointer;" @click="getMessage('ReadMessage')">{{$t("ReadMessage")}}</span>
          <span style="padding-left: 100px;cursor:pointer;" @click="makeAllRead()" v-if="currentType != 'ReadMessage'">{{$t("mark_all_read")}}</span>
        </div>
        <div class="ant-tabs ant-tabs-top ant-tabs-line">
          <div class="showPre1">
            <div class="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content" style="margin-left: 0%;">
              <div role="tabpanel" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active">
                <div class="ant-row pages-assets-balances-coinlist-thead" style="background: var(--assets-new-label-bgcolor);">
                  <div class="ant-col ant-col-3" style="width: 60%; padding-top: 2px;">{{$t(currentType)}}</div>
                  <div class="ant-col ant-col-3" style="text-align: left; padding-top: 2px;">时间</div>
                </div>
                <div class="ant-row-flex pages-assets-balances-coinlist-trow"  v-for="item in messagestList" style="padding: 0px 15px;">
                	<div class="" style="border-radius:5px; background-color: red; height: 10px; width: 10px; margin-right: 10px; margin-bottom: 2px;" v-if="!item.is_read"></div>
                	<div class="" style="border-radius:5px; height: 10px; width: 10px; margin-right: 10px; margin-bottom: 2px;" v-if="item.is_read"></div>
                  <div class="ant-col ant-col-3" style="text-align: left;width: 58.5%; cursor:pointer;" @click="messageDetails(item.id)">{{item.title}}</div>
                  <div class="ant-col ant-col-3" style="text-align: left;"> {{item.created_at}} </div>
                </div>
              </div>
            </div>
          </div>
	     </div>
		  </div>
	  </div>
    <nav-footer/>
  </div>
</template>
<style type="text/css" scoped>
	.active{
		background-color: #0099FF;
	}
	.isShow{
		border-radius: 12px;
    margin: 0 auto;
    width: var(--page-content-width);
	}
  .tabShow{
    color: var(--main-color);
  }
</style>
<script type="text/javascript">
import Vue from 'vue'
import NavFooter from '../components/NavFooter'
export default {
	data(){
		return {
			currentType: "AllMessage",
			messagestList: []
		}
	},
  components: {
    NavFooter
  },
  watch: {
    //仅仅是app.vue做完判断的时候回调
    currentJudgeIsLoggedIn(newVal) {
      if (newVal) {
       this.getMessage()
      }
    },
  },
  created(){
    if (this.currentJudgeIsLoggedIn) {
    	this.getMessage()
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  	getMessage(type){
      this.currentType = type || this.currentType
      this.getUrl('/www/user_center/station_letter', {
          lang:this.currentLang,
          message_type: this.currentType
        }).then((response) => {
        	console.log(response.data.messages)
        	this.messagestList = response.data.messages
      });
  	},

    makeAllRead(){
      this.getUrl('/www/user_center/set_all_read', {
          lang:this.currentLang,
          message_type: this.currentType
        }).then((response) => {
          console.log(response.data.messages)
          this.messagestList = response.data.messages
          Vue.setGlobalVariable({'have_new_message': false});
      });
    },

	  messageDetails(id){
	  	this.$router.push({path:'/message_details/'+id}).catch(data => {  })
	  },
  }
}
</script>
