<template>
  <div class="pages-info-coins-index-listRight">
    <div class="pages-info-coins-index-listTitle">{{codeName}}</div>
    <div class="pages-info-coins-index-infoBox">
      <div class="pages-info-coins-index-infoDetails" >
        <div class="pages-info-coins-index-infoFlexCol">
          <div class="pages-info-coins-index-infoImg">
            <img :src="information.icon" alt="">
            <b>{{codeName}}</b>
          </div>
          <div class="pages-info-coins-index-linkBox">
            <a :href="information.white_paper_url" rel="noopener noreferrer" target="_blank" style="margin-right: 20px;margin-bottom: 20px;">{{$t('white_paper')}}</a>
            <a :href="information.official_website" rel="noopener noreferrer" target="_blank" style="margin-bottom: 20px;">{{$t('official_website')}}</a>
          </div>
        </div>
        <div class="pages-info-coins-index-infoContent" style="margin-right: 30px;">
          <div class="pages-info-coins-index-flexBetween">
            <span>{{$t('english_name_of_the_token')}}</span>
            <span>{{information.en_name}}</span>
          </div>
          <div class="pages-info-coins-index-flexBetween">
            <span>{{$t('token_chinese_name')}}</span>
            <span>{{information.zh_name}}</span>
          </div>
          <div class="pages-info-coins-index-flexBetween">
            <span>{{$t('publish_time2')}}</span>
            <span>{{information.published_at}}</span>
          </div>
          <div class="pages-info-coins-index-flexBetween">
            <span>{{$t('issue_price')}}</span>
            <span>{{information.initial_price}}</span>
          </div>
          <div class="pages-info-coins-index-flexBetween">
            <span>{{$t('total_issued')}}</span>
            <span>{{information.published_amount}}</span>
          </div>
        </div>
        <div class="pages-info-coins-index-infoContent">
          <div class="pages-info-coins-index-flexBetween">
            <span>{{$t('withdrawal_status')}}</span>
            <span>{{information.is_withdrawable ? $t('withdrawable') : $t('no_withdrawal')}}</span>
          </div>
          <div class="pages-info-coins-index-flexBetween">
            <span>{{$t('recharge_status')}}</span>
            <span>{{information.is_withdrawable ? $t('can_recharge') : $t('not_rechargeable')}}</span>
          </div>
        </div>
      </div>
      <div class="pages-info-coins-index-infoDescribe" v-html="information.description">
      </div>
    </div>
  </div>
</template>
<style type="text/css" scoped>
  .pages-info-coins-index-flexBetween span{
    color: var(--main-color);
  }
</style>
<script>
export default {
  props: {
    codeName: String,
    information: Object
  },
  data() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
