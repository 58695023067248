<template>
	<div style="box-sizing: border-box;background-color: var(--my-assets-bgcolor);min-width: var(--page-content-width);">
		<div class="pages-assets-balances-navigation-container isShow" style="min-height: 311px; width: 85%">
	    <div class="pages-assets-balances-asset-account" style="margin-top: 30px;">
        <div class="ant-tabs ant-tabs-top ant-tabs-line" style=" text-align: center;">
          <p style="padding-top: 30px; font-size: 20px;"><strong>{{message.title}}</strong></p>
          <p v-html="message.details" style="padding-top: 30px;"></p>
		    </div>
	    </div>
    </div>
    <nav-footer/>
  </div>
</template>
<style type="text/css" scoped>
	.active{
		background-color: #0099FF;
	}
	.isShow{
		border-radius: 12px;
    margin: 0 auto;
    width: var(--page-content-width);
	}
</style>
<script type="text/javascript">
import Vue from 'vue'
import NavFooter from '../components/NavFooter'
export default {
	data(){
		return {
			message: ""
		}
	},
  components: {
    NavFooter
  },
  created(){
  	this.getMessageDetails()
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  	getMessageDetails(type){
      this.currentType = type || this.currentType
      this.getUrl('/www/user_center/station_letter_details', {
          lang:this.currentLang,
          id: this.$route.params.id
        }).then((response) => {
        	this.message = response.data
      });
  	},
  }
}
</script>
