//global_variable
export const SET_GLOBAL_VARIABLE = 'SET_GLOBAL_VARIABLE'

//storage_variable
export const SET_STORAGE_VARIABLE = 'SET_STORAGE_VARIABLE'

//user info
export const SET_BASEINFO = 'SET_BASEINFO'
export const GET_BASEINFO = 'GET_BASEINFO'
export const CLEAR_BASEINFO = 'CLEAR_BASEINFO'

//countdown
export const START_COUNTDOWN = 'START_COUNTDOWN'