<template>
    <div class="pages-home-index-download">
      <div style="width: 551px; height: 439px; background: #FFFFFF; border-radius: 8px; margin: auto;" >
        <img v-if="android_app_down_qr_code_img_link" :src="android_app_down_qr_code_img_link" width="200" alt="" style="margin-top: 40px;">
        <!--
        <img v-if="ios_app_down_qr_code_img_link ":src="ios_app_down_qr_code_img_link" width="120" alt="" style="margin-left: 120px">
        -->
        <div style="margin-top: 50px; width: 551px; background: #FFFFF; border-radius: 8px;">
            <p>
                <a style="margin-right: 0;" v-if="android_app_down_link" :href="android_app_down_link" target="_blank"><i class="iconfont icon-anzhuo" style="color: black"></i>
                    <span>Android Download</span>
                </a>
                <a v-if="ios_app_down_link" :href="ios_app_down_link" target="_blank" style="margin-left: 24px;"><i class="iconfont icon-ios" style="color: black"></i>
                    <span>Apple Download</span>
                </a>
            </p>
        </div>
        <p style="margin-top: 14px;">{{$t("please_scan_the_official_QR_code_to_download_the")}} {{currentHomeText}} {{$t("app")}}.</p>
      </div>
    </div>
</template>
<script type="text/javascript">
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String,
    ios_app_down_link: String,
    android_app_down_link: String,
    ios_app_down_qr_code_img_link: String,
    android_app_down_qr_code_img_link: String
  }
}
</script>
