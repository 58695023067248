/* eslint-disable */
import {website_name} from '../../application'
import {
    SET_GLOBAL_VARIABLE,
  } from '../mutation_types'

  const state = {
    show_left_drawer: false, //是否显示左侧的抽屉（当屏幕不够大的时候）
    selected_tab: 'usdt', //左侧bid_unit类型
    delegation_type: 'my_order', //底部委托列表类型（限价、历史）
    trade_type: 'limit', //右下方交易类型
    home_text: website_name,
    judge_is_logged_in: false,
    judge_is_not_logged_in: false,
    main_trade_area_favorite_ids: [],
    main_trade_innovation_favorite_ids: [],
    main_trade_area_favorite_passcard: [],
    main_trade_innovation_favorite_passcard: [],
    sales_promotion: 'ongoing',
    is_agree_protocol: false,
    user_protocol_show: false,
    protocol_for_users: false,
    checkbox_select: false,
    operation_validation_show: false,
    is_mobile_bound: false,
    is_email_bound: false,
    is_assets_password_bound: false,
    is_otp_binded: false,
    email_code_validation: true,
    mobile_code_validation: true,
    assets_password_validation: true,
    otp_code_validation: true,
    operation_type:"",
    new_phone:"",
    new_email:"",
    new_pwd: "",
    new_pwd_repeat:"",
    new_fund_password:"",
    new_fund_password_repeat:"",
    transfer_amount:"",
    email_address_of_the_transferred_user:"",
    withdrawal_amount: "",
    withdraw_to_address: "",
    description: "",
    have_new_message: false,
    input_assets_password_times: ""

  }

  const mutations = {
    //设置用户个人信息
    [SET_GLOBAL_VARIABLE] (state, data) {
      try {
        Object.keys(data).forEach(function(key){
            state[key] = data[key]
        })
      } catch (err) {
        console.log(err)
      }
    },
  }


  const actions = {
    [SET_GLOBAL_VARIABLE] ({ commit, state }, data) {
      //保存信息
      if (data !== undefined) {
        commit(SET_GLOBAL_VARIABLE, data)
      }
    }
  }

  export default {
    state,
    mutations,
    actions,
  }
