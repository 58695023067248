<template>
    <div class="pages-assets-balances-navigation-container isShow" v-show="faceValue == 'recharge'">
        <div class="ant-row-flex" style="margin-left: -10px;">
            <div class="ant-col ant-col-16" style="padding-left: 10px; padding-right: 10px;width: 60.666667%;">
                <div class="pages-assets-balances-recharge-main"
                     style="border: 1px solid rgba(255,255,255,0);background-color: var(--assets-new-box-bg);margin-top: 0px;">
                    <div class="pages-assets-balances-coinsearch-wrap">
	                <span class="pages-assets-balances-coinsearch-handle" style="color: #006cdb">
	                	<i class="pages-assets-balances-coinsearch-icon"
                           style="background-image: url(&quot;/api/file/download/F20190402135101606U2BkOHXvHA6cEy&quot;);"></i>
	                	<span>{{currency.code}}</span>&nbsp;
	                	<em style="color: #006cdb;">({{currency.full_name}})</em>
                	</span>
                    </div>
                    <div class="pages-assets-balances-recharge-content">
                        <div class="pages-assets-balances-recharge-usable">
                            <label for="" style="font-size:16px;">{{$t("available_balance")}}</label>
                            <p style="font-size: 16px">{{available_balance}} {{currency.code}}</p>
                        </div>
                        <div class="ant-row">
                            <div class="ant-col ant-col-14">
                                <label for="" style="font-size:16px;">{{$t("frozen_amount")}}</label>
                                <p style="color: var(--main-color);font-size: 16px">{{locked_balance}}
                                    {{currency.code}}</p>
                            </div>
                        </div>
                        <div>
                            <!-- <div class="pages-assets-balances-recharge-createAddr" v-if="!is_have_payment_address">
                                <p>
                                    <i class="iconfont icon-zhuyi"></i>
                                    {{$t("note_please_recharge_after_confirming_the_pass_and_address_if_you_fill_in_other_addresses_you_cannot_retrieve_it")}}!
                                </p>
                                <button type="button" class="ant-btn ant-btn-primary" style="background-color: #006cdb;border: 0px" @click="$emit('generate-address')">
                                    <span>{{$t("click_to_generate_address")}}</span>
                                </button>
                            </div> -->
                            <!-- <div class="pages-assets-balances-recharge-address" v-if="is_have_payment_address"> -->
                            <div v-if="currency.code == 'USDT'">
                                <!--                          <el-radio v-model="radio" label="1" border>备选项</el-radio>-->
                                <!--                          <el-radio v-model="radio" label="2" border>备选项</el-radio>-->
                                <!--                          <el-row>-->
                                <!--                              <el-button plain :autofocus="[erc20_or_omni == 'omni' ? 'true' : 'false']" v-model="radio" label="1">OMNI</el-button>-->
                                <!--                              <el-button plain :autofocus="[erc20_or_omni == 'erc_20' ? 'true' : 'false']">ERC_20</el-button>-->
                                <!--                          </el-row>-->

                                <button class="omni_or_erc_button" :class="[erc20_or_omni == 'erc_20' ? 'active' : 'nex-style']"
                                        @click="$emit('generate-address', 'erc_20')">ERC_20<i></i>
                                </button>
                                <!-- <button class="omni_or_erc_button"
                                        :class="[erc20_or_omni == 'omni' ? 'active' : 'nex-style']"
                                        style="margin-left: 4%;" @click="$emit('generate-address', 'omni')">OMNI
                                </button> -->
                            </div>
                            <div class="pages-assets-balances-recharge-address">

                                <div id="qrCode" class="qr_code_div" ref="qrCodeDiv"
                                     style="width: 115px; height: 115px; padding: 5px; background-color: #fffffb"></div>
                                <div class="pages-assets-balances-recharge-val" style="padding-top: 10px">
                                    <label>{{$t('recharge_address')}}</label>
                                    <span class="ant-input-affix-wrapper">
                            <input type="text" class="ant-input" :value="paymentAddress" disabled="disabled"
                                   style="background-color: var(--bind-phone-phone-bgcolor);border: 0px solid var(--assets-new-box-bg);"/>
                            <span class="ant-input-suffix">
                              <a style="font-size: 14px" :data-clipboard-text="paymentAddress" @click="copyMiyao"
                                 id="copy_text" onselectstart="return false;">{{$t('copy')}}</a>
                            </span>
                          </span>
                                    <p class="pages-assets-balances-recharge-red">
                                        {{$t('please_do_not_transfer_funds_through_the_eth_contract_otherwise_the_coins_will_be_lost')}}!</p>
                                </div>
                            </div>
                            <div class="pages-assets-balances-recharge-info">
                                <div class="pages-assets-balances-recharge-item"><i class="iconfont icon-zhuyi"></i>
                                    <div class="pages-assets-balances-recharge-desc">
                                        <p>
                                            {{$t("it_is_forbidden_to_recharge_the_address_to_assets_other_than_the_current_certificate_any_non_current_certificate_assets_that_are_filled_into_the_current_pass_address_will_not_be_retrievable")}}.</p>
                                        <p>
                                            <span> {{$t("need_to_get_back_the_address")}}<em>12</em> {{$t("network_confirmation")}}.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="pages-assets-balances-recharge-footer">
                                <a target="_blank" rel="noopener noreferrer"
                                   :href="trading_url">{{$t("go_to_trade")}}</a>
                                <a target="_blank" rel="noopener noreferrer"
                                   :href="otc_url">{{$t("otc_transaction")}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ant-col ant-col-8" style="width: 39.333333%;">
                <div class="pages-assets-balances-recordlately-wrap"
                     style="border: 1px solid rgba(255,255,255,0);background-color: var(--assets-new-box-bg);margin-top: 0px;">
                    <div class="pages-assets-balances-recordlately-header" style="padding: 0 10px;">
                        <h3>{{$t('recent_recharge_record')}}</h3>
                        <a class="pages-assets-balances-recordlately-extra"><!-- {{$t("all_records")}} --></a>
                    </div>
                    <div class="ant-table-wrapper">
                        <div class="ant-spin-nested-loading">
                            <div class="ant-spin-container">
                                <div class="ant-table ant-table-default ant-table-empty ant-table-scroll-position-left">
                                    <div class="ant-table-content">
                                        <div class="ant-table-body">
                                            <table class="">
                                                <colgroup>
                                                    <col>
                                                    <col>
                                                    <col>
                                                    <col>
                                                </colgroup>
                                                <div class="ant-table-tbody"
                                                     style="background-color: var(--assets-rwd-home-list);">
                                                    <tr>
                                                        <th class=""
                                                            style="background-color: var(--assets-rwd-home-list);border-top-left-radius: 0px;width: 16%;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">{{$t("type_of_pass")}}</span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                        </th>
                                                        <th class="ant-table-align-center"
                                                            style="text-align: center;background-color: var(--assets-rwd-home-list);width: 16%;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                            <span class="ant-table-column-title">{{$t("status")}}</span>
                                                            <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                        </th>
                                                        <th class="ant-table-align-center"
                                                            style="text-align: center;background-color: var(--assets-rwd-home-list);width: 39%;">
                                                        <span class="ant-table-header-column">
                                                            <div>
                                                                <span class="ant-table-column-title">{{$t("time")}}</span>
                                                                <span class="ant-table-column-sorter"></span>
                                                            </div>
                                                        </span>
                                                        </th>
                                                        <th class="ant-table-align-right"
                                                            style="text-align: right;background-color: var(--assets-rwd-home-list);border-top-right-radius: 0px;width: 29%;">
                                                        <span class="ant-table-header-column">
                                                             <div>
                                                                 <span class="ant-table-column-title">{{$t("quantity")}}</span>
                                                                 <span class="ant-table-column-sorter"></span>
                                                             </div>
                                                        </span>
                                                        </th>
                                                    </tr>
                                                </div>
                                                <div class="ant-table-tbody tcontent" v-if="list_is_have_data"
                                                     style="overflow-y: scroll;overflow-x: hidden;height: 427px;">
                                                    <tr v-for="item in depositsRecharge">
                                                        <th style="width: 16%;">
                                                        <span>
                                                            <div>
                                                                <span>{{currency.code}}</span>
                                                                <span></span>
                                                            </div>
                                                        </span>
                                                        </th>
                                                        <th style="width: 16%">
                                                        <span>
                                                            <div style="text-align: center;">
                                                                <span>{{item.done_at == '' ? $t('unaccounted') : $t('arrived')}}</span>
                                                                <span></span>
                                                            </div>
                                                        </span>
                                                        </th>
                                                        <th style="width: 39%;">
                                                        <span>
                                                            <div style="text-align: center;">
                                                                <span>{{item.done_at}}</span>
                                                                <span></span>
                                                            </div>
                                                        </span>
                                                        </th>
                                                        <th style="width: 29%;">
                                                        <span>
                                                            <div style="text-align: right;">
                                                                <span>{{item.amount}}</span>
                                                                <span></span>
                                                            </div>
                                                        </span>
                                                        </th>
                                                    </tr>
                                                </div>
                                            </table>
                                        </div>
                                        <div class="ant-table-placeholder" v-if="!list_is_have_data">
                                            <div class="components-empty-index-wrapper">
                                                <i class="iconfont icon-wushuju"></i>
                                                <div>{{$t("no_data")}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style type="text/css" scoped>
    .tcontent tr {
        display: flex;
        justify-content: space-between;
    }

    .tcontent tr th {
        white-space: normal;
        word-wrap: break-word;
        word-break: normal;
    }

    .qr_code_div {
        height: 80px;
        width: 118px;
    }

    .ant-table-column-title {
        color: #006cdb;
    }

    body .ant-table-thead > tr > th {
        background-color: var(--assets-rwd-home-list);
    }

    .nex-style {
        color: var(--main-color);
    }

    .active {
        color: #357ce1;
        border: 1px solid #357ce1 !important; /* blue */
        background-color: transparent !important;
    }

    /*.omni_or_erc_button {*/
    /*    width: 80px;*/
    /*    height: 35px;*/
    /*    background-color: #f9f7fd;*/
    /*}*/

    .omni_or_erc_button {
        width: 80px;
        height: 35px;
        outline: none;
        border: 1px solid transparent; /* 透明 */
        background-color: #f9f7fd;
    }

    /*.omni_or_erc_button.active i {*/
    /*    display: block;*/
    /*    position: absolute;*/
    /*    border-bottom: 15.4px solid #357ce1;*/
    /*    border-left: 2rem solid transparent;*/
    /*    width: 0px;*/
    /*    height: 0px;*/
    /*    bottom: 0rem;*/
    /*    right: 0;*/
    /*}*/
    /*.omni_or_erc_button.active i::after {*/
    /*    position: absolute;*/
    /*    content: '\2714';*/
    /*    color: #fff;*/
    /*    left: -1rem;*/
    /*    top: -0.7rem;*/
    /*    font-size: 1.2rem;*/
    /*}*/

</style>
<script type="text/javascript">
    import Clipboard from 'clipboard';
    import QRCode from 'qrcodejs2';
    import Vue from 'vue'

    export default {
        props: {
            // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
            faceValue: String,
            language: String,
            is_have_payment_address: Boolean,
            paymentAddress: String,
            currency: Object,
            depositsRecharge: Array,
            available_balance: String,
            locked_balance: String,
            erc20_or_omni: String
        },
        watch: {
            paymentAddress(newVal, oldval) {
                // 调用绘制二维码
                if (newVal != oldval && newVal != '') {
                    document.getElementById('qrCode').innerHTML = ''
                    this.bindQRCode();
                }
            }
        },
        data() {
            return {
                otc_url: '',
                trading_url: '',
                radio: '1'
            }
        },
        mounted() {
            this.otc_url = Vue.getValueByKey('otc_path') + '?lang=' + this.currentLang;
            this.trading_url = Vue.getValueByKey('trading_path') + '?lang=' + this.currentLang;
            if (this.paymentAddress != '') {
                this.bindQRCode();
            }
        },
        computed: {
            list_is_have_data: function () {
                if (this.depositsRecharge.length > 0) {
                    return true;
                } else {
                    return false;
                }
            },
            assets: function () {
                return [
                    {
                        label: "bb",
                        name: this.$t('coin_account')
                    },
                    {
                        label: "hy",
                        name: this.$t('contract_account')
                    }
                ]
            }
        },
        methods: {
            bindQRCode: function () {
                this.$nextTick(() => {
                    new QRCode(this.$refs.qrCodeDiv, {
                        text: this.paymentAddress,
                        width: 100,
                        height: 100,
                        colorDark: "#333333", //二维码颜色
                        colorLight: "#ffffff", //二维码背景色
                        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
                    })
                })
            },
            copyMiyao() {
                var _this = this;
                var clipboard = new Clipboard('#copy_text');
                clipboard.on('success', e => {
                    // 释放内存
                    this.$message({
                        message: this.$t('replication_success'),
                        type: 'success',
                    })
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    // 不支持复制
                    // 释放内存
                    this.$message({
                        message: this.$t('the_browser_does_not_support_automatic_copying'),
                        type: 'warning',
                    })
                    clipboard.destroy()
                })
            }
        }
    }
</script>
