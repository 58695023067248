<template>
<div class="pages-ucenter-commonform-wrapper">
  <identity-authenticator-title :language = "language"/>
  <identity-authenticator-content :language = "language" />
</div>
</template>
<style type="text/css">

</style>
<script>
import IdentityAuthenticatorTitle from '../UcIdentityAuthentication/IdentityAuthenticatorTitle'
import IdentityAuthenticatorContent from '../UcIdentityAuthentication/IdentityAuthenticatorContent'
export default {
  data() {   
     return {

     }
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  methods: {
 
  },
  mounted() {
    
  },
  components: {
   IdentityAuthenticatorTitle,
   IdentityAuthenticatorContent
  }
}
</script>