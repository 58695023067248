<template>
  <div class="pages-home-trades-main" style="margin-bottom: 50px; margin-top: 50px;color: var(--main-color);">
    <div class="ant-table-wrapper pages-home-trades-table" style="margin-top: 40px;height: auto; margin: 0px auto; border-radius: 4px; width: var(--page-content-width); background-color: var(--assets-new-box-bg);border-radius: 16px;">
      <div style="margin: 30px;" v-if="sale_promotion.sale_token_id">
        <div>
          <div style="width: 361px; height: 96px;background-color: var(--sales-show-box-bg);  float: left; border-radius: 8px; box-shadow: 0 0 10px 10px var(--sales-promotion_show-bgcolor) ">
            <div style="float: left;">
              <div style="float: left; width: 66px;height: 66px; margin: 15px; border-radius:50px;">
                <img style="width: 66px;height: 66px; border-radius:50px" :src="sale_promotion.currency_info.icon_url">
              </div>
              <div style="float: left; margin-top: 24px">
                <b>{{sale_promotion.currency_info.currency_full_name}}</b></br>
                <b>{{$t('abbreviation')}}： {{sale_promotion.currency_info.currency_code}}</b>
              </div>
            </div>
            <div style="float: right; margin-top: 40px; margin-right: 10px; width: 80px;height: 26px; border-radius: 13px; text-align: center; padding: 2px;" :style="{backgroundColor: sale_promotion.state ==='inprogress' ? 'rgb(0, 106, 227)' : sale_promotion.state ==='coming' ? 'rgb(255, 255, 255)' : 'rgb(218, 218, 228)', color: sale_promotion.state ==='inprogress' ? '#ffffff' : sale_promotion.state ==='coming' ? '#006cdb' : '#a5a5ba', border: sale_promotion.state ==='coming' ? 'solid 1px #006ae3' : ''}">
              {{$t(sale_promotion.state)}}
            </div>
          </div>
          <div style="float: right; margin-right: 20px; width: 63%; margin-top: 3%;">
            <div id="my-echart" style="width: 100%; height: 8px; background-color: #f3f4f9; border-radius:4px; position: relative;">
              <div style="margin-top: -11px; display: flex; justify-content: space-between;">
                <div class="progress_split_point">
                  <div style="width: 10px; height: 10px; border-radius:50px;" :style="{backgroundColor: colorOne}"></div>
                </div>
                <div class="progress_split_point" >
                  <div style="width: 10px; height: 10px; border-radius:50px;" :style="{backgroundColor: colorTwo}"></div>
                </div>
                <div class="progress_split_point" >
                  <div style="width: 10px; height: 10px; border-radius:50px;" :style="{backgroundColor: colorThree}"></div>
                </div>
                <div class="progress_split_point" >
                  <div style="width: 10px; height: 10px; border-radius:50px;" :style="{backgroundColor: colorFour}"></div>
                </div>
                <div style="float: right; width: 14px; height: 14px; border-radius:50px; background-color: #fff; padding: 2px; margin: -3px">
                  <div style="width: 10px; height: 10px; border-radius:50px;" :style="{backgroundColor: colorFive}"></div>
                </div>
              </div>
              <div style="height: 8px; background-color: #686eff; border-radius:4px; max-width: 100%; min-width: 0%; display: flex; flex-direction: row-reverse; margin-top: -8px;" :style="{width: sale_promotion.progress_percentage}">
                <div style="width: 40px; height: 40px; margin-top: -14px; background-color: #fff; border-radius:50px; padding: 2px; margin-right: -20px;">
                  <div style="height: 36px; width: 36px; border-radius:50px; display: -moz-box; display: -webkit-box; -moz-box-align: center; -webkit-box-align: center; -moz-box-pack: center; -webkit-box-pack: center; font-size: 12px;" :style="{backgroundColor: colorSix}">
                    <span style="color: #1b1926" v-if="sale_promotion.progress_percentage === '0.0%'">{{dataShow(sale_promotion.progress_percentage)}}</span>
                    <span style="color: #fff" v-else>{{dataShow(sale_promotion.progress_percentage)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="float: left;clear: left; margin-top: 20px; width: 100%">
            <span><img style="width: 20px; height: 20px" src="https://cdn.gda.top/image_after_2019_06/2239/jieduan.png"> {{$t('stage')}}：{{sale_promotion.stage}}</span>
            <span style="margin-left: 11%"><img style="width: 20px; height: 20px" src="https://cdn.gda.top/image_after_2019_06/2238/fee.png"> {{$t('handling_fee')}}：{{sale_promotion.fee_rate}}</span>
          </div>
        </div>

        <div style="float: left;clear: left; margin-top: 30px; margin-bottom: 40px; width: 60%; height: 45%">
          <img style="width: 100%" :src="sale_promotion.icon_banner">
        </div>
        <div style=" width: 36%; height: 2px;float: left; margin-left: 20px; background-color: #dcdfea; margin-top: 32px"></div>
        <div style="width: 36%;float: left; margin-top: 20px; margin-left: 20px;">
          <div style="float: left;color: #939393; margin-top: 13px;">{{$t('total_limit')}}</div>
          <div style="float: right;color: #939393;">
            <span>{{sale_promotion.sale_token_amount}} {{sale_promotion.currency_info.currency_code}}</span></br>
            <span>({{sale_promotion.funding_currency_target_amount}} {{sale_promotion.funding_currency_code}})</span>
          </div>
        </div>
        <div style=" width: 36%; height: 2px;float: left; margin-left: 20px; background-color: #dcdfea"></div>

        <div style=" width: 36%;float: left; margin-left: 20px;">
          <div style="float: left;color: #939393; margin-top: 13px;">{{$t('the_starting_time')}}</div>
          <div style="float: right;color: #939393; margin-top: 20px;">{{sale_promotion.started_at}}</div>
        </div>

        <div style=" width: 36%;float: left; margin-left: 20px;">
          <div style="float: left;color: #939393; margin-top: 13px;">{{$t('ended_at')}}</div>
          <div style="float: right;color: #939393; margin-top: 20px;">{{sale_promotion.ended_at}}</div>
        </div>
        <div v-if="sale_promotion.state === 'inprogress'">
          <div style=" width: 38%;float: left; text-align: center; padding-top: 60px; margin-left: 22px;" v-if="isLoggedIn">
            <button tabindex="-1" type="button" style="border: 0px; cursor: pointer; width: 40%; margin-top: 5px; height: 32px; background-color: rgb(0, 108, 219); border-radius: 16px; color: rgb(255, 255, 255);" @click="NewSalesPromotion(sale_promotion.sale_token_id)">{{$t('click_to_start_new')}}</button>
          </div>

          <div style=" width: 38%;float: left; text-align: center; padding-top: 20px;margin-left: 22px;" v-if="isNotLoggedIn">
            <button tabindex="-1" type="button" style="border: 0px; cursor: pointer; margin-top: 5px; height: 32px; background-color: rgb(0, 108, 219); border-radius: 16px; color: rgb(255, 255, 255);width:fit-content; width:-webkit-fit-content; width:-moz-fit-content; padding: 3px 20px 3px 20px" @click="Login">{{$t('account_number_login')}}</button>
          </div>

          <div style=" width: 38%;float: left; text-align: center; padding-top: 20px;margin-left: 22px;" v-if="isNotLoggedIn">
            <button tabindex="-1" type="button" style="border: 0px; cursor: pointer; margin-top: 5px; height: 32px; background-color: rgb(0, 108, 219); border-radius: 16px; color: rgb(255, 255, 255);width:fit-content; width:-webkit-fit-content; width:-moz-fit-content; padding: 3px 20px 3px 20px" @click="siginUp">{{$t('no_account_to_sigin_up')}}</button>
          </div>

        </div>
      </div>

      <div style="float: left;clear: left; margin: 30px;width: 95%; height: 268px;">
        <div class="showPre1">
          <div class="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content"
              style="margin-left: 0%;">
              <div role="tabpanel" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active" v-if="sale_promotion.sale_token_id">
                  <div class="ant-row pages-assets-balances-coinlist-thead" style="background: var(--assets-new-label-bgcolor);">
                      <div class="ant-col ant-col-3" style="padding-left: 68px;width: 25%;padding-top: 2px;">{{$t('user')}}</div>
                      <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px;">{{$t('amount')}}({{sale_promotion.currency_info.currency_code}})</div>
                      <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px;">{{$t('participation_time')}}</div>
                      <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px;">{{$t('amount_received')}}({{sale_promotion.funding_currency_code}})</div>
                  </div>
                  <div class="ant-row-flex pages-assets-balances-coinlist-trow" style="padding: 0px 15px;" >
                    <div style="height: 250px;">
                      <vue-seamless-scroll :data="all_sales_promotion" :class-option="optionHover" class="seamless-warp">
                          <div v-for="item in all_sales_promotion">
                            <div v-text="item.user_email" class="ant-col ant-col-3" style="width: 28%;padding-left: 30px">{{item}}</div>
                            <div v-text="item.funding_currency_amount" class="ant-col ant-col-3" style="text-align: center;width: 20%;"></div>
                            <div v-text="item.created_at" class="ant-col ant-col-3" style="text-align: center;width: 31%;">}</div>
                            <div v-text="item.sale_token_amount" class="ant-col ant-col-3" style="text-align: center;width: 19%;"></div>
                          </div>
                      </vue-seamless-scroll>
                    </div>
                  </div>
              </div>
              <div role="tabpanel" aria-hidden="true" class="ant-tabs-tabpane ant-tabs-tabpane-inactive"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css" scoped>
  .seamless-warp {
    height: 250px;
    width: 1099px;
    overflow: hidden;
  }
  .progress_split_point{
    float: left;
    width: 14px;
    height: 14px;
    border-radius:50px;
    background-color: #ffffff;
    padding: 2px;
    margin: -3px;
  }
  .margin-left{
    /*margin-left: 22%;*/
  }
</style>
<script type="text/javascript">
import Vue from 'vue'
import echarts from 'echarts'
export default {
  data () {
    return {
        charts: '',
        sale_promotion: {},
        all_sales_promotion:[],
        listData: [],
        colorOne: '#f3f4f9',
        colorTwo: '#f3f4f9',
        colorThree: '#f3f4f9',
        colorFour: '#f3f4f9',
        colorFive: '#f3f4f9',
        colorSix: '#6875ff',
    }
  },
  watch: {
    sale_promotion(){
      if(this.sale_promotion.sale_token_id){
        this.progressColor(this.sale_promotion.progress_percentage);
      }
    }
  },
  created(){
  },
  computed: {
    optionHover () {
        return {
          hoverStop: true,
          step: 0.5, // 数值越大速度滚动越快
          limitMoveNum: 12, // 开始无缝滚动的数据量 this.dataList.length
          // hoverStop: true, // 是否开启鼠标悬停stop
          // direction: 0, // 0向下 1向上 2向左 3向右
          // openWatch: true, // 开启数据实时监控刷新dom
          // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          // singleHeight: 20, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
        }
    }
  },
  mounted() {
    this.getSalePromotion(this.$route.params.id)
  },
  methods: {
    dataShow(data){
      if(data === '100.0%'){return '100%'}else{return data}
    },
    progressColor(data){
      data = parseFloat(data.replace(/%/g, ""))
      if(data > 0){this.colorOne = '#6875ff'}
      if(data >= 25){this.colorTwo = '#6875ff'}
      if(data >= 50){this.colorThree = '#6875ff'}
      if(data >= 75){this.colorFour = '#6875ff'}
      if(data >= 100){this.colorFive = '#6875ff'}
      if(data == 0){this.colorSix = '#f3f4f9'}
    },
    Login(){
      window.location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
    },
    siginUp(){
      window.location.href =  Vue.getValueByKey('cas_path')+'/sign_up?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
    },
    getSalePromotion(id){
      this.getUrl('/www/sales_promotion/sales_promotion_show?id=' + id).then((response)=>{
        if (response.data.code ===  20000) {
          this.sale_promotion = response.data.sale_promotion;
          this.all_sales_promotion = response.data.all_sales_promotion;
        }
      })
    },

    NewSalesPromotion(id){
      // 跳到打新new页面
      this.$router.push({ path: ('/new_sales_promotion/' + id)});
    }
  }
}

</script>
