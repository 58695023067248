<template>
<div class="pages-ucenter-commonform-wrapper"  @click="hideAlert">
  <bind-phone-title :language = "language"/>
  <bind-phone-header-text :language = "language"/>
  <bind-phone-content :language = "language"  :isHideCountry="isHideCountry"  :isHideLanguage="isHideLanguage" :defaultCountryIconClass = "defaultCountryIconClass"  :defaultCountryName = "defaultCountryName"  :defaultCountryMember = "defaultCountryMember" @show-country="showCountry" @check-country="checkCountry"/>
  <operation-validation></operation-validation>
</div>
</template>
<script>
import BindPhoneTitle from '../BindPhone/BindPhoneTitle'
import BindPhoneContent from '../BindPhone/BindPhoneContent'
import BindPhoneHeaderText from '../BindPhone/BindPhoneHeaderText'
import OperationValidation from '../OperationValidation'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)

  },
  data() {
     return {
        isHideCountry:true,
        defaultCountryIconClass:"components-countryselect-index-flag-cn",
        defaultCountryNameTemp:"",
        defaultCountryMember:"+86",
        isHideLanguage:true
     }
  },
  computed: {
    language: function () {
      return this.currentLang;
    },
    defaultCountryName: function () {
      if(this.defaultCountryNameTemp == ''){
        return this.$t('china');
      }else{
        return this.defaultCountryNameTemp;
      }
      
    },
  },
  mounted() {
    
  },
  methods: {
    hideAlert(){
      if(this.isHideCountry == false){
        this.isHideCountry = true;
      }
    },
    checkCountry(item){
      this.defaultCountryIconClass = item.icon;
      this.defaultCountryNameTemp = item.name;
      this.defaultCountryMember = item.number;
      this.isHideCountry = true;
    },
    showCountry(label) {
        this.isHideCountry = ! label;
    }
  },
  components: {
    BindPhoneTitle,
    BindPhoneContent,
    BindPhoneHeaderText,
    OperationValidation
  }
}
</script>
