<template>
<div class="pages-ucenter-commonform-wrapper">
  <edit-password-title :language = "language"/>
  <edit-password-content :language = "language"/>
  <operation-validation></operation-validation>
</div>
</template>
<style type="text/css">

</style>
<script>
import EditPasswordTitle from '../UcEditPassword/EditPasswordTitle'
import EditPasswordContent from '../UcEditPassword/EditPasswordContent'
import OperationValidation from '../OperationValidation'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  mounted() {
    
  },
  components: {
   EditPasswordTitle,
   EditPasswordContent,
   OperationValidation
  }
}
</script>