<template>
<div class="pages-home-index-wrapper" style="width: 1200px;margin: 0 auto;">
    <user-center-header :language = "language"/>
    <user-center-features :language = "language"/>
</div>
</template>
<style type="text/css" scope >

</style>
<script>
import UserCenterFeatures from '../UserCenter/UserCenterFeatures'
import UserCenterHeader from '../UserCenter/UserCenterHeader'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  mounted() {
    
  },
  components: {
    UserCenterFeatures,
    UserCenterHeader,
  }
}
</script>