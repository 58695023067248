<style>
	.page-bar {
	  text-align: center;
	  height: 36px;
	  position: relative;
	 }
	.page-bar ul {
	  min-width: 10px;
	  display: block;
	}
	.page-bar li {
	  display: block;
	  width: 36px;
	  height: 36px;
	  border-radius: 4px;
	  list-style: none;
	  overflow: hidden;
	  position: relative;
	  float: left;
	  margin-left: 1px;
	}
	.page-bar .first{
	  display: block;
	  height: 36px;
	  font-size: 14px;
	  line-height: 36px;
	  text-align: center;
	  width:auto; display:inline-block !important;
	  display:inline; 
	}
	.page-bar .last_li{
	  width: 74px;
	  height: 31px;
	  border: 1px solid #ddd;
	}
	.page-bar .last_li span{
	  width: 100%;
	  height: 100%;
	  line-height: 36px;
	  text-align: center;
	  float: left;
	}
	.page-bar li:first-child {
	  margin-left: 0px
	}

	.page-bar a {
		width: 23px;
		height: 23px;
		border: 1px solid #ddd;
		text-decoration: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		/*margin-left: -1px;*/
		line-height: 23px;
		/*color: #F5F5F5;*/
		cursor: pointer
	}

	.page-bar .li_a a:hover {
		background-color: #eee;
		border: 1px solid #40A9FF;
		color: #40A9FF;
	}

	.page-bar a.banclick {
		cursor: not-allowed;
	}

	.page-bar .active a {
		color: #fff;
		cursor: default;
		background-color: #1890FF;
		border-color: #1890FF;
	}

	.page-bar i {
		font-style: normal;
		/*color: #d44950;*/
		margin: 0px 4px;
		font-size: 14px;
	}
</style>
<template>
	<div class="page-bar">
	  <ul>
			<li class="first">
				<!-- <span>共{{dataNum}}条记录 第 {{cur}} / {{all}} 页</span> -->
				<span>{{$t('some_records_in_total', {dataNum})}} {{$t('some_page', {cur, all})}} </span>
			</li>
			<li v-if="cur>1">
			  <a v-on:click="cur--,pageClick()"><</a>
			</li>
			<li v-if="cur==1">
			  <a class="banclick"><</a>
			</li>
			<li  class="li_a" v-for="index in indexs" v-bind:class="{ 'active': cur == index}">
			  <a v-on:click="btnClick(index)">{{ index }}</a>
			</li>
			<li v-if="cur!=all">
			  <a v-on:click="cur++,pageClick()">></a>
			</li>
			<li v-if="cur == all">
			  <a class="banclick">></a>
			</li>
			<!-- <li class="last_li">
			  <span>共<i>{{all}}</i>页</span>
			</li> -->
	  </ul>
	</div>
</template>
<script>
	export default {
	  //显示的声明组件
		name: "paging",
	  //从父级组件中传值过来的，你可以自己设置名字，但是需要跟父级传入的名字一致！
	  props : ["dataAll","dataCur","datanum","dataDatanum"],   
	  data() {
      return {
				all: this.dataAll, //总页数
				cur:  this.dataCur ,//当前页码
				num: this.datanum , //一页显示的数量  奇数
				dataNum: this.dataDatanum,//数据的数量  
      }
	  },
    watch: {
	   	dataAll(newValue) {
        this.all = newValue
		  },
	   	dataCur(newValue) {
	   		this.cur = newValue
	   	},
	   	datanum(newValue) {
	   		this.num = newValue
	   	},
	   	dataDatanum(newValue) {
	   		this.dataNum = newValue
	   	},
      cur: function(oldValue, newValue) {
        //父组件通过change方法来接受当前的页码
        this.$emit('change', oldValue)
        //这里是直接点击执行函数
      }
    },
	  methods: {
      btnClick: function(data) { //页码点击事件
        if(data != this.cur) {
          this.cur = data
        }
      },
      pageClick: function() {
        console.log('现在在' + this.cur + '页');
        //父组件通过change方法来接受当前的页码
        //这里是点击下一页执行函数
        this.$emit('change',  this.cur)
      }
	  },

	  computed: {
      indexs: function() {
        var left = 1;
        var right = this.all;
        var ar = [];
        if(this.cur > 3 && this.cur < this.all - 2) {
          if(this.cur - 2 >= 1){
          	left = this.cur - 2
          }
          if(right >= this.cur + 2){
          	right = this.cur + 2
          }
        }else{
          if(this.cur <= 3) {
            left = 1
            if(right >= this.cur + (5 - this.cur)){
            	right = this.cur + (5 - this.cur)
            }
            
          }else{
            right = this.cur
            left = this.cur - 4
            if(this.all - this.cur <= 2){
            	right = this.all
            	left = this.cur - (4 - this.all + this.cur)
            }
            
          }
        }
        while(left <= right) {
          ar.push(parseInt(left))
          left++
        }
        return ar
      }
	  }
	}
</script>