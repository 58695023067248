<template>
<section class="pages-ucenter-commonform-content" style="margin-top: 15px;">
  <form class="ant-form ant-form-vertical ant-form-hide-required-mark">
    <!-- <div class="ant-row ant-form-item edit_fund_pwd_title" v-if="is_bind_phone">
     {{$t("your_current_mobile_phone_number_is")}}：{{now_mobile}}
    </div>
    <div class="ant-row ant-form-item edit_fund_pwd_title" v-if="is_bind_email && !is_bind_phone">
     {{$t("your_currently_bound_mailbox_is")}}：{{now_email}}
    </div> -->
    <!-- <div class="pages-ucenter-bind-phone-index-captcha">
      <div class="ant-row ant-form-item" v-bind:class="[isShowErrorMsmClass ? 'ant-form-item-with-help' : null ]">
        <div class="ant-col ant-form-item-label">
          <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('verification_code')">{{$t("verification_code")}}</label></div>
        <div class="ant-col ant-form-item-control-wrapper">
          <div class="ant-form-item-control"  v-bind:class="[isShowErrorMsmClass ? 'has-error' : null ]">
            <span class="ant-form-item-children">
              <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                <span class="ant-input-wrapper ant-input-group">
                  <input type="text" id="smsCode" class="ant-input ant-input-lg" v-model="msm" @keyup="inputmsm()" maxlength="6">
                  <span class="ant-input-group-addon">
                    <button type="button" class="ant-btn ant-btn-primary" :disabled="msmIsAble" @click="msmSend" style="background-color: #006cdb;opacity: 1;font-weight:normal;border: 0px">
                      <span style="font-size: 14px;">{{msmButton}}</span>
                    </button>
                  </span>
                </span>
                <div class="ant-form-explain" v-if="isShowErrorMsmDiv">{{msmErrorMsg}}</div>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="ant-col ant-form-item-label">
      <label for="newPassword" class="ant-form-item-required ant-form-item-no-colon" style="color: red; font-size: 20px;" :title="$t('old_fund_password')">{{$t("the_account_cannot_be_operated_within_24_hours_after_changing_the_fund_password")}}</label>
    </div>
    
    <div class="ant-row ant-form-item" :class="[fundPwdErrorClass ? 'ant-form-item-with-help' : null ]" style="margin-top: 50px;">
        <div class="ant-col ant-form-item-label">
          <label for="newPassword" class="ant-form-item-required ant-form-item-no-colon" :title="$t('fund_password')">{{$t("fund_password")}}</label>
        </div>
        <div class="ant-col ant-form-item-control-wrapper">
            <div class="ant-form-item-control"  :class="[fundPwdErrorClass ? 'has-feedback has-error' : null ]" >
              <span class="ant-form-item-children">
                <input type="password" id="fundPassword" class="ant-input ant-input-lg" value=""  @keyup="inputFundPwd()" v-model="fundpwd" maxlength="32">
              </span>
                <div class="ant-form-explain"  v-if="isShowErrorFundPwdDiv">{{fundPwdErrorMsg}}</div>
            </div>
        </div>
    </div>
    <div class="ant-row ant-form-item" :class="[fundPwdErrorClassRepeat ? 'ant-form-item-with-help' : null ]" >
        <div class="ant-col ant-form-item-label">
          <label for="newPassword" class="ant-form-item-required ant-form-item-no-colon" :title="$t('confirm_fund_password')">{{$t("confirm_fund_password")}}</label>
        </div>
        <div class="ant-col ant-form-item-control-wrapper">
            <div class="ant-form-item-control"  :class="[fundPwdErrorClassRepeat ? 'has-feedback has-error' : null ]" >
              <span class="ant-form-item-children">
                <input type="password" id="fundPasswordRepeat" class="ant-input ant-input-lg" value=""  @keyup="inputFundPwdRepeat()"  v-model="fundpwdRepeat" maxlength="32">
              </span>
                <div class="ant-form-explain"  v-if="isShowErrorFundPwdDivRepeat">{{fundPwdErrorMsgRepeat}}</div>
            </div>
        </div>
    </div>

    <div class="pages-ucenter-commonform-handle">
      <button type="button" class="ant-btn ant-btn-primary" style="width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);" :disabled="submitIsAble" :title="$t('confirm_password')" @click="OperationValidation('editFundPwd', fundpwd, fundpwdRepeat)">
        <span>{{$t("confirm_password")}}</span>
      </button>
      <router-link class="pages-ucenter-commonform-back" to="/user_center">{{$t("return")}}</router-link>
    </div>
  </form>
</section>
</template>
<style type="text/css">
.edit_fund_pwd_title{
  color: #e45858;
  margin-bottom: 15px;
  font-size: 13px;
}
</style>
<script type="text/javascript">
import Vue from 'vue'
import i18n from '../../i18n/i18n'
import store from '../../vuex/store'
export default {
  props: {

  },
  data() {
     return {
      msm: '',
      // msmIsAble:false,
      // isShowErrorMsmClass:false,
      // isShowErrorMsmDiv:false,
      msmErrorMsg:"",
      phoneMsmCd:60,
      // oldFundPwd: "",
      // oldFundPwdErrorMsg:'',
      // oldFundPwdErrorClass:'',
      // isShowErrorOldFundPwdDiv:'',
      fundpwd:'',
      fundPwdErrorMsg:'',
      fundPwdErrorClass:'',
      isShowErrorFundPwdDiv:'',
      fundpwdRepeat:'',
      fundPwdErrorMsgRepeat:'',
      fundPwdErrorClassRepeat:'',
      isShowErrorFundPwdDivRepeat:'',
      bindMsmIsSend: false
     }
  },
  mounted() {

  },
  computed: {
    is_bind_phone: function (){
        if(this.currentJudgeIsLoggedIn){
            if(this.currentUserInfo.mobile == '' || this.currentUserInfo.mobile == null){
                return false;
            }else{
              return true;
            }
        }else{
            return false;
        }
    },
    is_bind_email: function (){
        if(this.currentJudgeIsLoggedIn){
            if(this.currentUserInfo.email.indexOf('needtobindemail') != '-1'){
                return false;
            }else{
                return true;
            }
        }else{
            return false;
        }
    },
    now_email: function () {
      if(this.currentJudgeIsLoggedIn){
          return this.currentUserInfo.email;
      }else{
          return '';
      }
    },
    now_mobile: function () {
      if(this.currentJudgeIsLoggedIn){
          return this.currentUserInfo.mobile;
      }else{
          return '';
      }
    },
    submitIsAble: function () {
      if(!this.fundPwdErrorClass  && !this.isShowErrorFundPwdDiv && !this.fundPwdErrorClassRepeat && 
        !this.isShowErrorFundPwdDivRepeat && this.fundpwd != '' && this.fundpwdRepeat != ''){
          return false;
      }else{
          return true;
      }
    },
    msmButton: function () {
       if(this.phoneMsmCd > 0 && this.msmIsAble == true && this.bindMsmIsSend){
           return "("+(this.phoneMsmCd )+"S)";
       }else{
           //重置
           return this.$t('click_to_get');
       }
    }
  },
  methods: {
      // validOldAssetOassword(){
      //   this.postUrl('/www/user_center/valid_old_asset_password', {
      //       lang:this.currentLang,
      //       old_fund_password: this.oldFundPwd,
      //       new_password: this.fundpwd,
      //       new_password_repeat: this.fundpwdRepeat
      //    }).then((response) => {
      //       if(response.data.code == 20000){
      //         //加载热验证
      //         this.OperationValidation('editFundPwd');
      //         Vue.setGlobalVariable({
      //           'new_fund_password': this.fundpwd
      //         });
      //         Vue.setGlobalVariable({
      //           'new_fund_password_repeat': this.fundpwdRepeat
      //         });
      //       }
      //    });
      // },
      
      inputFundPwdRepeat(){
        this.fundpwdRepeat = this.fundpwdRepeat.replace(/[\u4e00-\u9fa5]/ig,'');
        if (this.fundpwdRepeat == '') {
            //不合法 不可为空
            this.fundPwdErrorMsgRepeat = this.$t('please_enter_your_password');
            this.fundPwdErrorClassRepeat = true;
            this.isShowErrorFundPwdDivRepeat = true;
        }else if(this.fundpwdRepeat !== this.fundpwd){
            this.fundPwdErrorMsgRepeat = this.$t('inconsistent_password_input_twice');
            this.fundPwdErrorClassRepeat = true;
            this.isShowErrorFundPwdDivRepeat = true;
        }else{
            //正确
            this.fundPwdErrorClassRepeat = false;
            this.isShowErrorFundPwdDivRepeat = false;
        }
      },

      // inputOldFundPwd(){
      //   this.oldFundPwd = this.oldFundPwd.replace(/[\u4e00-\u9fa5]/ig,'');
      //   let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,32}$/;
      //   if (this.oldFundPwd == '') {
      //       //不合法 不可为空
      //       this.oldFundPwdErrorMsg = this.$t('please_enter_your_password');
      //       this.oldFundPwdErrorClass = true;
      //       this.isShowErrorOldFundPwdDiv = true;
      //   }else if(this.oldFundPwd.length < 8 || this.oldFundPwd.length > 32){
      //       //不合法 格式错误
      //       this.oldFundPwdErrorMsg = this.$t('please_enter_an_8_32_password');
      //       this.oldFundPwdErrorClass = true;
      //       this.isShowErrorOldFundPwdDiv = true;
      //   }else if(!reg.test(this.oldFundPwd)){
      //       this.oldFundPwdErrorMsg = this.$t('password_must_contain_letters_and_numbers');
      //       this.oldFundPwdErrorClass = true;
      //       this.isShowErrorOldFundPwdDiv = true;
      //   }else{
      //       //正确
      //       this.oldFundPwdErrorClass = false;
      //       this.isShowErrorOldFundPwdDiv = false;
      //   }

      //   if(!this.currentIsAssetsPasswordBound){
      //     this.oldFundPwdErrorClass = false
      //     this.isShowErrorOldFundPwdDiv = false
      //   }
      // },

      inputFundPwd(){
        this.fundpwd = this.fundpwd.replace(/[\u4e00-\u9fa5]/ig,'');
        let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,32}$/;
        if (this.fundpwd == '') {
            //不合法 不可为空
            this.fundPwdErrorMsg = this.$t('please_enter_your_password');
            this.fundPwdErrorClass = true;
            this.isShowErrorFundPwdDiv = true;
        }else if(this.fundpwd.length < 8 || this.fundpwd.length > 32){
            //不合法 格式错误
            this.fundPwdErrorMsg = this.$t('please_enter_an_8_32_password');
            this.fundPwdErrorClass = true;
            this.isShowErrorFundPwdDiv = true;
        }else if(!reg.test(this.fundpwd)){
            this.fundPwdErrorMsg = this.$t('password_must_contain_letters_and_numbers');
            this.fundPwdErrorClass = true;
            this.isShowErrorFundPwdDiv = true;
        }else{
            //正确
            this.fundPwdErrorClass = false;
            this.isShowErrorFundPwdDiv = false;
        }
      },
      inputmsm(){
        this.msm = this.msm.replace(/[^0-9]/g,'');
        if (this.msm == '') {
            //不合法 不可为空
            this.msmErrorMsg = this.$t('please_enter_sms_verification_code');
            this.isShowErrorMsmClass = true;
            this.isShowErrorMsmDiv = true;
        }else if(this.msm.length != 6){
            this.msmErrorMsg = this.$t('sms_verificationcode_format_is_incorrect');
            this.isShowErrorMsmClass = true;
            this.isShowErrorMsmDiv = true;
        }else{
            //正确
            this.isShowErrorMsmClass = false;
            this.isShowErrorMsmDiv = false;
        }
      },
      msmSend: function () {
        if(this.is_bind_email && !this.is_bind_phone){
          type = 'email';
        }else{
          type = 'mobile';
        }
        this.msmIsAble = true;
        this.postUrl('/www/user_center/send_edit_asset_password_code', {
            lang: this.currentLang,
            type: type
         }).then((response) => {
            if(response.data.code == 20000){
              //验证码发送成功
              this.bindMsmIsSend = true;
              this.timer = setInterval( () => {
                if(this.phoneMsmCd > 1){
                   this.msmIsAble = true;
                   this.phoneMsmCd = this.phoneMsmCd - 1;
               }else{
                   //重置
                   this.msmIsAble = false;
                   this.phoneMsmCd = 60;
                   this.bindMsmIsSend = false;
                   clearInterval(this.timer)
               }
      　　　　}, 1000);
            }else{
              this.msmIsAble = false;
            }
         });
  　　},
      hideAlert(){

      }
    }
}
</script>
