<template>
<div class="pages-ucenter-commonform-wrapper">
  <otc-nickname-title :language = "language"/>
  <otc-nickname-content :language = "language"/>
</div>
</template>
<style type="text/css">

</style>
<script>
import OtcNicknameTitle from '../UcOtcNickname/OtcNicknameTitle'
import OtcNicknameContent from '../UcOtcNickname/OtcNicknameContent'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  mounted() {
    
  },
  components: {
   OtcNicknameTitle,
   OtcNicknameContent
  }
}
</script>