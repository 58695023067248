<template>
    <div class="pages-assets-balances-navigation-wrapper" style="margin: -4px 0px 50px;">
        <page-assets-header :cgTotal = "cg_total" :fiatsCount = "fiats_count" :fiatSymbol = "fiat_symbol" :convertCurrency = "convert_currency"/>
        <page-assets-header-text/>
        <assets-balc-navig-ctn-assets  :language = "language" :accountList = "accountList" :isCertified = "isCertified"/>
     </div>
</template>
<script>
import PageAssetsHeader from '../Assets/PageAssetsHeader'
import PageAssetsHeaderText from '../Assets/PageAssetsHeaderText'
import AssetsBalcNavigCtnAssets from '../Assets/AssetsBalcNavigCtnAssets'
export default {
  data() {
     return {
        accountList: [],
        cg_total: '',
        fiats_count: '',
        fiat_symbol: '',
        convert_currency: '',
        isCertified: false,
     }
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  mounted() {
    if (this.currentJudgeIsLoggedIn) {
      this.loadPage()
    }
  },
  watch: {
      //仅仅是app.vue做完判断的时候回调
      currentJudgeIsLoggedIn(newVal) {
        if (newVal) {
         this.loadPage()
        }
      },
  },
  components: {
    PageAssetsHeader,
    PageAssetsHeaderText,
    AssetsBalcNavigCtnAssets
  },
  methods: {
    loadPage() {
      this.getUrl('/www/assets/', {
          lang:this.currentLang
      }).then((response) => {
          if(response.data.code == 20000){
            this.accountList = response.data.data.account;
            this.cg_total = response.data.data.cg_total;
            this.fiats_count = response.data.data.fiats_count;
            this.fiat_symbol = response.data.data.fiat_symbol;
            this.convert_currency = response.data.data.convert_currency;
            this.isCertified = response.data.data.is_certified;
          }
      });
    }
  }
}
</script>
