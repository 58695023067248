<template style="height: 100%;">
    <div class="show_my_family">
        <section class="pages-ucenter-profile-index-item pages-ucenter-profile-index-header">
            <section class="pages-ucenter-profile-index-content">
                <div class="header_content">{{$t('here_are_all_your_families')}}</div>
            </section>
        </section>
        <div id="wrap" class="wrap">
            <div class="container">
                <div class="row">
                    <div class='alert alert-info'>
                        {{$t('here_is_your_team_structure')}}。 <br/>
                        {{$t('top_is_yourself')}}。 <br/>
                        {{$t('the_sequence_is_a_tree_diagram_from_left_to_right')}}。 <br/>
                    </div>
                    <div class='my_tree'>
                        <span v-html="my_family_html">

                        </span>
                    </div>
                </div>
            </div>
        </div>
        <nav-footer  :language = "language"/>
    </div>
</template>

<script>
    import NavFooter from '../components/NavFooter'
    export default {
        name: "ShowMyFamily",
        components: {
            NavFooter
        },
        data(){
            return {
                my_family_html: ""
            }
        },
        methods:{
            showMyFamily(){
                this.getUrl('/www/user_center/show_my_family', {
                    lang:this.currentLang
                }).then((response) => {
                    if(response.data.code == 20000){
                        //重新加载用户数据
                        console.log(response.data)
                        this.my_family_html = response.data.my_family;

                    }
                });
            }
        },
        computed: {
            language: function () {
                return this.currentLang;
            }
        },
       mounted() {
            this.showMyFamily()
       }
    }
</script>

<style lang="scss" >
    .show_my_family{
        background-color: var(--user-center-bgcolor);
    }
    .show_my_family .wrap{
        min-height: 300px;
    }
    .show_my_family .pages-ucenter-profile-index-item{
        background-color: transparent !important;
    }
    .show_my_family .row{
        color: var(--header-color);
    }
    .show_my_family .header_content{
        font-size: 16px;
        color: var(--user-center-header-color);
        padding-left: 30px;
    }
    .show_my_family .my_tree ul, .my_tree li {
        position: relative;
    }

    .show_my_family .my_tree ul {
        list-style: none;
        padding-left: 32px;
    }

    .show_my_family .my_tree li::before, .my_tree li::after {
        content: "";
        position: absolute;
        left: -12px;
    }

    .show_my_family .my_tree li::before {
        border-top: 1px solid #000;
        top: 9px;
        width: 8px;
        height: 0;
    }

    .show_my_family .my_tree li::after {
        border-left: 1px solid #000;
        height: 100%;
        width: 0px;
        top: 2px;
    }

    .show_my_family .my_tree ul > li:last-child::after {
        height: 8px;
    }
</style>