module.exports = {
  //website_name: 'CADEX',
  website_name: 'GDA',
  main_index_picture_text: 'GDAEX',
  my_assets_right_text: 'GDAEX',
  white_logo: 'https://cdn.gda.top/files/other/1730163913834.png',
  //black_logo: 'https://showmethemoney.fuxinsci.com/image_after_2019_06/2188/logo_black.png',
  black_logo: 'https://cdn.gda.top/files/other/17302631224f5.png',
  android_download_url: '',
  android_download_qrcode: '',
  ios_download_url: '',
  ios_download_qrcode: '',
  my_assets_banner: '',
}
