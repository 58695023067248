<template>
<section class="pages-ucenter-commonform-content" style="margin-top: 15px">
    <form class="ant-form ant-form-vertical ant-form-hide-required-mark">
        <div class="ant-row ant-form-item" :class="[oldPwdErrorClass ? 'ant-form-item-with-help' : null ]">
            <div class="ant-col ant-form-item-label">
            	<label for="oldPassword" class="ant-form-item-required ant-form-item-no-colon" :title="$t('old_password')">{{$t("old_password")}}</label>
            </div>
            <div class="ant-col ant-form-item-control-wrapper">
                <div class="ant-form-item-control" :class="[oldPwdErrorClass ? 'has-feedback has-error' : null ]">
	                <span class="ant-form-item-children">
	                	<input type="password" id="oldPassword" class="ant-input ant-input-lg" value="" @keyup="inputOldPwd()"  v-model="oldPwd" maxlength="32">
	                </span>
                    <div class="ant-form-explain"  v-if="isShowErrorOldPwdDiv">{{oldPwdErrorMsg}}</div>
                </div>
            </div>
        </div>
        <div class="ant-row ant-form-item" :class="[newPwdErrorClass ? 'ant-form-item-with-help' : null ]" >
            <div class="ant-col ant-form-item-label">
            	<label for="newPassword" class="ant-form-item-required ant-form-item-no-colon" :title="$t('new_password')">{{$t("new_password")}}</label>
            </div>
            <div class="ant-col ant-form-item-control-wrapper">
                <div class="ant-form-item-control"  :class="[newPwdErrorClass ? 'has-feedback has-error' : null ]" >
	                <span class="ant-form-item-children">
	                	<input type="password" id="newPassword" class="ant-input ant-input-lg" value=""  @keyup="inputNewPwd()"  v-model="newPwd" maxlength="32">
	                </span>
                    <div class="ant-form-explain"  v-if="isShowErrorNewPwdDiv">{{newPwdErrorMsg}}</div>
                </div>
            </div>
        </div>
        <div class="ant-row ant-form-item"  :class="[newPwdErrorClassRepeat ? 'ant-form-item-with-help' : null ]">
            <div class="ant-col ant-form-item-label">
            <label for="newPasswordConfirm" class="ant-form-item-required ant-form-item-no-colon" title="$t('confirm_the_new_password')">{{$t("confirm_the_new_password")}}</label>
            </div>
            <div class="ant-col ant-form-item-control-wrapper">
                <div class="ant-form-item-control"  :class="[newPwdErrorClassRepeat ? 'has-feedback has-error' : null ]">
                	<span class="ant-form-item-children">
	                	<input type="password" id="newPasswordConfirm" class="ant-input ant-input-lg" value=""  @keyup="inputNewPwdRepeat()" v-model="newPwdRepeat" maxlength="32">
	                </span>
                    <div class="ant-form-explain"  v-if="isShowErrorNewPwdDivRepeat">{{newPwdErrorMsgRepeat}}</div>
				</div>
            </div>
        </div>
        <div class="pages-ucenter-commonform-handle">
	        <button type="button" class="ant-btn ant-btn-primary"  style="width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);"  :disabled="buttonIsDisabled" :title="$t('confirm_password')" @click="oldPasswordValid" >
	        	<span>{{$t("confirm_password")}}</span>
	        </button>
        	<router-link class="pages-ucenter-commonform-back" to="/user_center">{{$t("return")}}</router-link>
        </div>
    </form>
</section>
</template>
<script type="text/javascript">
import Vue from 'vue'
import i18n from '../../i18n/i18n'
import store from '../../vuex/store'
export default {
  data() {   
     return {
        oldPwd:'',
        oldPwdErrorMsg:'',
        oldPwdErrorClass:false,
        isShowErrorOldPwdDiv:false,

        newPwd:'',
        newPwdErrorMsg:'',
        newPwdErrorClass:false,
        isShowErrorNewPwdDiv:false,


        newPwdRepeat:'',
        newPwdErrorMsgRepeat:'',
        newPwdErrorClassRepeat:false,
        isShowErrorNewPwdDivRepeat:false
     }
  },
  computed: {
    buttonIsDisabled: function () {
        if(!this.newPwdErrorClass && !this.newPwdErrorClassRepeat && !this.oldPwdErrorClass && !this.isShowErrorNewPwdDiv && !this.isShowErrorNewPwdDivRepeat && !this.isShowErrorOldPwdDiv && this.newPwd != '' && this.newPwdRepeat != '' && this.oldPwd != ''){
            return false;
        }else{
            return true;
        }
    }
  },
  methods: {
    oldPasswordValid(){
      this.postUrl('/www/user_center/old_password_valid', {
          lang:this.currentLang,
          original_password: this.oldPwd,
          // new_password: this.newPwd,
          // new_password_repeat: this.newPwdRepeat
       }).then((response) => {
          if(response.data.code == 20000){
            Vue.setGlobalVariable({
              'new_pwd': this.newPwd
            });
            Vue.setGlobalVariable({
              'new_pwd_repeat': this.newPwdRepeat
            });
            // 热验证
            this.OperationValidation("editLoginPassword")
          }
       });
    },

    
    inputOldPwd(){
        this.oldPwd = this.oldPwd.replace(/[\u4e00-\u9fa5]/ig,'');
        if (this.oldPwd == '') {
            //不合法 不可为空
            this.oldPwdErrorMsg = this.$t('please_enter_your_password');
            this.oldPwdErrorClass = true;
            this.isShowErrorOldPwdDiv = true;
        }else{
            //正确
            this.oldPwdErrorClass = false;
            this.isShowErrorOldPwdDiv = false;
        }
    },
    inputNewPwd(){
        this.newPwd = this.newPwd.replace(/[\u4e00-\u9fa5]/ig,'');
        let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,32}$/;
        if (this.newPwd == '') {
            //不合法 不可为空
            this.newPwdErrorMsg = this.$t('please_enter_your_password');
            this.newPwdErrorClass = true;
            this.isShowErrorNewPwdDiv = true;
        }else if(this.newPwd.length < 8 || this.newPwd.length > 32){
            //不合法 格式错误
            this.newPwdErrorMsg = this.$t('please_enter_an_8_32_password');
            this.newPwdErrorClass = true;
            this.isShowErrorNewPwdDiv = true;
        }else if(!reg.test(this.newPwd)){
            this.newPwdErrorMsg = this.$t('password_must_contain_letters_and_numbers');
            this.newPwdErrorClass = true;
            this.isShowErrorNewPwdDiv = true;
        }else{
            //正确
            this.newPwdErrorClass = false;
            this.isShowErrorNewPwdDiv = false;
        }
    },
    inputNewPwdRepeat(){
        this.newPwdRepeat = this.newPwdRepeat.replace(/[\u4e00-\u9fa5]/ig,'');
        if (this.newPwdRepeat == '') {
            //不合法 不可为空
            this.newPwdErrorMsgRepeat = this.$t('please_enter_your_password');
            this.newPwdErrorClassRepeat = true;
            this.isShowErrorNewPwdDivRepeat = true;
        }else if(this.newPwdRepeat !== this.newPwd){
            this.newPwdErrorMsgRepeat = this.$t('inconsistent_password_input_twice');
            this.newPwdErrorClassRepeat = true;
            this.isShowErrorNewPwdDivRepeat = true;
        }else{
            //正确
            this.newPwdErrorClassRepeat = false;
            this.isShowErrorNewPwdDivRepeat = false;
        }
    }
  }  
}
</script>