<template>
<div class="pages-ucenter-commonform-wrapper">
  <google-authenticator-title :language = "language" :bind_otp_binded = "bind_otp_binded"/>
  <google-authenticator-header-text :language = "language" :bind_otp_binded = "bind_otp_binded"/>
  <google-authenticator-content :language = "language" :otp_url = "otp_url" :bind_otp_binded = "bind_otp_binded"/>
  <operation-validation></operation-validation>
</div>
</template>
<style type="text/css">

</style>
<script>
import GoogleAuthenticatorTitle from '../UcGoogleAuthenticator/GoogleAuthenticatorTitle'
import GoogleAuthenticatorContent from '../UcGoogleAuthenticator/GoogleAuthenticatorContent'
import GoogleAuthenticatorHeaderText from '../UcGoogleAuthenticator/GoogleAuthenticatorHeaderText'
import OperationValidation from '../OperationValidation'
export default {
  computed: {
    otp_url: function () {
      return this.currentUserInfo.otp_url
    },
    language: function () {
      return this.currentLang;
    },
    bind_otp_binded: function () {
        if(this.currentJudgeIsLoggedIn){
            return this.currentUserInfo.is_otp_binded
        }else{
            return false;
        }
    }
  },
  mounted() {

  },
  components: {
   GoogleAuthenticatorTitle,
   GoogleAuthenticatorContent,
   GoogleAuthenticatorHeaderText,
   OperationValidation
  }
}
</script>
