<template>
  <div style="box-sizing: border-box;min-width: var(--page-content-width);background-color: var(--my-assets-bgcolor);">
    <index/>
    <nav-footer  :language = "language"/>
  </div>
</template>
<script>
import Index from '../components/AssetIntroduction/Index'
import NavFooter from '../components/NavFooter'
import Vue from 'vue'
export default {
  components: {
    Index,
    NavFooter
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
}
</script>
