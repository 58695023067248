import { mapState } from 'vuex'
import store from './vuex/store'
export default function (Vue, configs) {
  Vue.setValueByKey = function(key, value) {
    window.localStorage.setItem(key, value);
  }
  Vue.getValueByKey = function(key) {
    return window.localStorage.getItem(key);
  }
  Vue.setGlobalVariable = function(hash) {
    store.dispatch('SET_GLOBAL_VARIABLE', hash)
  }
  // {key: value}
  Vue.setStorageVariable = function(hash) {
    store.dispatch('SET_STORAGE_VARIABLE', hash)
  }
  Vue.mixin({
    created: function () {
      //页面配置常量
      const configs = {
      }
      this.$configs = configs
    },
    computed: {
        ...mapState({
            currentTheme: state => state.storage_variable.theme,
            currentKlineInterval: state => state.storage_variable.kline_interval,
            currentLang: state => state.storage_variable.lang,
            currentChartType: state => state.storage_variable.chart_type,
            currentFixedAskOrderBook: state => state.storage_variable.fixed_ask_order_book,
            currentShowLeftDrawer: state => state.global_variable.show_left_drawer,
            currentHiddenAssets: state => state.storage_variable.hidden_assets,
            currentSelectedTab: state => state.global_variable.selected_tab,
            currentDelegationType: state => state.global_variable.delegation_type,
            currentTradeType: state => state.global_variable.trade_type,
            currentMarketListThirdColumnType: state => state.storage_variable.market_list_third_column_type,
            currentOrderBookThirdColumnType: state => state.storage_variable.order_book_third_column_type,
            currentOrderBookBarChartType: state => state.storage_variable.order_book_bar_chart_type,
            currentIdentAuth: state => state.storage_variable.ident_auth,
            currentPageCount: state => state.storage_variable.page_count,
            currentUserInfo: state => state.storage_variable.user_info,
            currentRwdAssetsType: state => state.storage_variable.rwd_assets_type,
            currentHomeText: state => state.global_variable.home_text,
            currentJudgeIsLoggedIn: state => state.global_variable.judge_is_logged_in,
            currentJudgeIsNotLoggedIn: state => state.global_variable.judge_is_not_logged_in,
            currentWwwHomeMarket: state => state.websocket.www_home_market,
            currentWwwHomeTradesMainAreaList: state => state.websocket.www_home_trades_main_area_list,
            currentWwwHomeTradesMainAreaPassCard: state => state.websocket.www_home_trades_main_area_pass_card,
            currentWwwHomeTradesMainInnovationList: state => state.websocket.www_home_trades_main_innovation_list,
            currentMainAreaFavoriteIds: state => state.global_variable.main_trade_area_favorite_ids,
            currentMainInnovationFavoriteIds: state => state.global_variable.main_trade_innovation_favorite_ids,
            currentMainAreaFavoritePassCard: state => state.global_variable.main_trade_area_favorite_passcard,
            currentMainInnovationFavoritePassCard: state => state.global_variable.main_trade_innovation_favorite_passcard,
            currentSalesPromotion: state => state.global_variable.sales_promotion,
            currentIsAgreeProtocol: state => state.global_variable.is_agree_protocol,
            currentUserProtocolShow: state => state.global_variable.user_protocol_show,
            currentProtocolForUsers: state => state.global_variable.protocol_for_users,
            currentCheckboxSelect: state => state.global_variable.checkbox_select,
            currentOperationValidationShow: state => state.global_variable.operation_validation_show,
            currentIsMobileBound: state => state.global_variable.is_mobile_bound,
            currentIsEmailBound: state => state.global_variable.is_email_bound,
            currentIsAssetsPasswordBound: state => state.global_variable.is_assets_password_bound,
            currentIsOtpBinded: state => state.global_variable.is_otp_binded,
            currentEmailCodeValidation: state => state.global_variable.email_code_validation,
            currentMobileCodeValidation: state => state.global_variable.mobile_code_validation,
            currentAssetsPasswordValidation: state => state.global_variable.assets_password_validation,
            currentInputAssetsPasswordTimes: state => state.global_variable.input_assets_password_times,
            currentOtpCodeValidation: state => state.global_variable.otp_code_validation,
            currentOperationType: state => state.global_variable.operation_type,
            currentNewPhone: state => state.global_variable.new_phone,
            currentNewEmail: state => state.global_variable.new_email,
            currentnewPwd: state => state.global_variable.new_pwd,
            currentnewPwdRepeat: state => state.global_variable.new_pwd_repeat,
            currentnewFundPassword: state => state.global_variable.new_fund_password,
            currentnewFundPasswordRepeat: state => state.global_variable.new_fund_password_repeat,
            currentTransferAmount: state => state.global_variable.transfer_amount,
            currentEmailAddressOfTheTransferredUser: state => state.global_variable.email_address_of_the_transferred_user,
            currentWithdrawalAmount: state => state.global_variable.withdrawal_amount,
            currentWithdrawToAddress: state => state.global_variable.withdraw_to_address,
            currentDescription: state => state.global_variable.description,
            currentHaveNewMessage: state => state.global_variable.have_new_message
        })
    },
  })
}