<template>
  <div style="box-sizing: border-box;">
    <div class="pages-info-coins-index-wrapper">
      <div class="pages-info-coins-index-title">{{$t('introduction_to_digital_assets')}}</div>
      <div class="pages-info-coins-index-listContent">
        <list-left :language = "language" @changed="loadCurrency"/>
        <list-right :language = "language" :codeName="codeName" :information = "information"/>
      </div>
    </div>
  </div>
</template>

<script>
import ListLeft from '../AssetIntroduction/ListLeft'
import ListRight from '../AssetIntroduction/ListRight'
export default {
  data() {
    return {
      codeName: '',
      information: {}
    }
  },
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
  },
  components: {
    ListLeft,
    ListRight,
  },
  mounted() {
    //回到顶部
    document.body.scrollTop = document.documentElement.scrollTop = 0
  },
  methods: {
    loadCurrency(value) {
      this.codeName = value.toLowerCase();
      this.getUrl('/www/currencies_information', {
        code: this.codeName,
        lang: this.currentLang
      }).then((response)=>{
        if (response.data.code ===  20000) {
          this.information = response.data.currency_data;
        }
      })
    }
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
}
</script>
