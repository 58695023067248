<template>
<section class="pages-ucenter-commonform-content" style="margin-top: 15px">
    <form class="ant-form ant-form-vertical ant-form-hide-required-mark">
        <div class="pages-ucenter-api-index-wrapper">
            <div>
                <div class="ant-row ant-form-item">
                    <div class="ant-col ant-form-item-label">
                    	<label for="remark" class="ant-form-item-required ant-form-item-no-colon" :title="$t('public_key')">{{$t("public_key")}}</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control">
	                        <span class="ant-form-item-children">
	                        	<input type="text" id="remark" class="ant-input ant-input-lg" value="" v-model="publicKey" disabled="disabled" style="color: var(--assets-label-color);">
	                        </span>
                        </div>
                    </div>
                </div>
                <div class="ant-row ant-form-item" style="width: auto">
                    <div class="ant-col ant-form-item-label">
                    	<label for="ipAddress" class="ant-form-item-no-colon" :title="$t('private_key')">{{$t("private_key")}}</label>
                    </div>
                    <div class="ant-col ant-form-item-control-wrapper">
                        <div class="ant-form-item-control" >
	                        <span class="ant-form-item-children">
	                        	<input type="text" id="ipAddress" class="ant-input ant-input-lg" value=""  v-model="privateKey" disabled="disabled" style="width: 360px;color: var(--assets-label-color);">
                                <el-button type="text" @click="resetKey" style="border-radius: 22px;margin-left: 18px;width: 90px;height: 40px;background: rgb(0, 108, 219);color: #ffffff;">{{$t('reset')}}</el-button>
	                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pages-ucenter-commonform-handle" :title="$t('return')">
            <router-link class="ant-btn ant-btn-primary"  style="width: 130px;line-height:46px;height: 46px;font-size: 15px;background: -webkit-linear-gradient(left, rgb(118, 85, 233), rgb(79, 141, 233));" to="/user_center"><span>{{$t("return")}}</span></router-link>
        </div>
    </form>
</section>
</template>
<style type="text/css">
    .el-button--small{
        background-color: #23abe6;
        color: #ffffff;
    }
    .el-button--small:hover
    {
        background-color: #0397e3;
        color: #ffffff;
    }
</style>
<script type="text/javascript">
export default {
  props: {
      changedApiType: String,
      isHideApiType: Boolean,
      apiType:String
  },
  data() {
     return {
        ip:'',
        remarks:'',
        ipErrorMsg:'',
        remarks:'',
        remarksErrorMsg:'',
        ipErrorClass:false,
        isShowErrorIpDiv:false,
        remarksErrorClass:false,
        isShowErrorRemarksDiv:false
     }
  },
  mounted(){
    if(this.currentJudgeIsLoggedIn){
        if(!this.currentUserInfo.is_api_used){
            //未开启
            location.href = "/user_center";
        }
    }else{
        location.href = "/user_center";
    }
  },
  computed: {
    publicKey: function () {
        if(this.currentJudgeIsLoggedIn){
            return this.currentUserInfo.public_key
        }else{
            return false;
        }
    },
    privateKey: function () {
        if(this.currentJudgeIsLoggedIn){
            return this.currentUserInfo.private_key
        }else{
            return false;
        }
    },
    ApiTypes: function () {
        return [
            {
                id:1,
                name:this.$t('spot')
            },
            {
                id:2,
                name:this.$t('contract')
            }
        ]
    },
    buttonIsDisabled: function () {
        if(!this.ipErrorClass && !this.isShowErrorIpDiv && !this.remarksErrorClass && !this.isShowErrorRemarksDiv  && this.remarks != '' ){
            return false;
        }else{
            return true;
        }
    }
  },
  methods: {
    resetKey(){
        this.$confirm(this.$t('cannot_be_restored_after_resetting_the_private_key'), this.$t('are_you_sure_you_want_to_reset_your_private_key')+'?', {
          confirmButtonText: this.$t('ok_reset'),
          cancelButtonText: this.$t('cancel')
        }).then(() => {
            //点击确定 重置key
            this.postUrl('/www/user_center/reset_private_key', {
                lang:this.currentLang
            }).then((response) => {
                if(response.data.code == 20000){
                  this.getMemberInfo();
                  this.$router.push({path:'/api_management'}).catch(data => {  })
                }
            });
        }).catch(() => {       
        });
    },
    hideAlert(){

    },
    inputIp(){
        let reg=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
        if(!reg.test(this.ip) && this.ip != ''){
            this.ipErrorMsg = this.$t('enter_up_to_4_ip_addresses');
            this.ipErrorClass = true;
            this.isShowErrorIpDiv = true;
        }else{
            //正确
            this.ipErrorClass = false;
            this.isShowErrorIpDiv = false;
        }
    },
    inputRemarks(){
        if(this.remarks == ''){
            this.remarksErrorMsg = this.$t('please_enter_a_note');
            this.remarksErrorClass = true;
            this.isShowErrorRemarksDiv = true;
        }else{
            //正确
            this.remarksErrorClass = false;
            this.isShowErrorRemarksDiv = false;
        }
    }
  }
}
</script>
