import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Myassets from '../views/Myassets.vue'
import UserCenter from '../views/UserCenter.vue'
import UcEditPassword from '../views/UcEditPassword.vue'
import UcGoogleAuthenticator from '../views/UcGoogleAuthenticator.vue'
import UcIdentityAuthenticator from '../views/UcIdentityAuthenticator.vue'
import UcApiManagement from '../views/UcApiManagement.vue'
import BindPhone from '../views/BindPhone.vue'
import UcFundPassword from '../views/UcFundPassword.vue'
import BindEmail from '../views/BindEmail.vue'
import InviteQrCode from '../views/InviteQrCode.vue'
import RwdAssets from '../views/RwdAssets.vue'
import AssetIntroduction from '../views/AssetIntroduction.vue'
import MessageList from '../views/MessageList.vue'
import MessageDetails from '../components/InformationDetails.vue'
import SalesPromotion from '../views/SalesPromotion.vue'
import ShowSalesPromotion from '../views/ShowSalesPromotion.vue'
import NewSalesPromotion from '../views/NewSalesPromotion.vue'
import SuccessfulSalesPromotion from '../views/SuccessfulSalesPromotion.vue'
import OtcNickname from '../views/UcOtcNickname.vue'
import Activity from '../views/Activity'
import CurrencyFee from '../views/CurrencyFee'
import ShowMyFamily from "../views/ShowMyFamily"
import TransferList from "../components/Assets/TransferList.vue"
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
      path: '/my_assets',
      name: 'my_assets',
      component: Myassets
    },
    {
      path: '/activity',
      name: 'activity',
      component: Activity
    },
    {
      path: '/user_center',
      name: 'user_center',
      component: UserCenter
    },
    {
      path: '/edit_password',
      name: 'edit_password',
      component: UcEditPassword
    },
    {
      path: '/fund_password',
      name: 'fund_password',
      component: UcFundPassword
    },
    {
      path: '/google_auth',
      name: 'google_auth',
      component: UcGoogleAuthenticator
    },
    // {
    //   path: '/identity_auth',
    //   name: 'identity_auth',
    //   component: UcIdentityAuthenticator
    // },
    {
      path: '/api_management',
      name: 'api_management',
      component: UcApiManagement
    },
    {
      path: '/bind_phone',
      name: 'bind_phone',
      component: BindPhone
    },
    {
      path: '/bind_email',
      name: 'bind_email',
      component: BindEmail
    },
    {
      path: '/otc_nickname',
      name: 'otc_nickname',
      component: OtcNickname
    },
    {
      path: '/invite_qrcode',
      name: 'invite_qrcode',
      component: InviteQrCode
    },
    {
      path: '/rwd_assets/:code',
      name: 'rwd_assets',
      component: RwdAssets
    },
    {
      path: '/asset_introduction',
      name: 'asset_introduction',
      component: AssetIntroduction
    },
    {
      path: '/message',
      name: 'message',
      component: MessageList
    },
    {
      path: '/message_details/:id',
      name: 'message_details',
      component: MessageDetails
    },
    {
      path: '/sales_promotion',
      name: 'sales_promotion',
      component: SalesPromotion
    },
    {
      path: '/show_sales_promotion/:id',
      name: 'show_sales_promotion',
      component: ShowSalesPromotion
    },
    {
      path: '/new_sales_promotion/:id',
      name: 'new_sales_promotion',
      component: NewSalesPromotion
    },
    {
      path: '/successful_sales_promotion/:id',
      name: 'successful_sales_promotion',
      component: SuccessfulSalesPromotion
    },
    {
      path: '/currency_fee',
      name: 'currency_fee',
      component: CurrencyFee
    },
    {
      path: '/show_my_family',
      name: 'show_my_family',
      component: ShowMyFamily
    },
    {
      path: '/transfer_list/:code',
      name: 'transfer_list',
      component: TransferList
    }
  ]
})
