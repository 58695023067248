import Vue from 'vue'
export default function (Vue) {
  Vue.mixin({
    methods: {
      subscribePublicChannels() {
        this.$websocket.subscribe(
          this.subscribeRefactoring({
            channel: 'www_home_market',
            lang: this.currentLang
          })
        )
        this.$websocket.subscribe(
          this.subscribeRefactoring({
            channel: 'www_home_trades_main_list',
            lang: this.currentLang
          })
        )
      },
      subscribePrivateChannels() {
        /*
        this.$websocket.subscribe(
          this.subscribeRefactoring({
            channel: 'member_account',
            market_id: this.$route.params.market_id,
            token: this.currentUserInfo.token,
            lang: this.currentLang
          })
        )
        */
      },
      unsubscribe(){
        this.$websocket.unsubscribeAllChannels()
      }
    },
  })
}
