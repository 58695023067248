import en from './en'
import jp from './jp'
import kr from './kr'
import zh from './zh'
import ru from './ru'
export default {
  en,
  zh,
  jp,
  kr,
  ru
}
