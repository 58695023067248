<template>
  <div style="box-sizing: border-box;background-color: var(--my-assets-bgcolor);min-width: var(--page-content-width)">
    <!-- <div class="pages-assets-balances-navigation-container" style="margin-top: 2%"> -->
      <div class="pages-assets-balances-asset-account" style="margin-top: 2%;">
        <div class="ant-tabs ant-tabs-top ant-tabs-line">
          <div class="assets_balc_navig_ctn_transfer_where" style="margin-top: 1%">
            <div class="block">
              <el-date-picker
                 v-model="seach_date"
                 type="datetimerange"
                 :picker-options="pickerOptions"
                 :range-separator="$t('to')"
                 :start-placeholder="$t('start_date')"
                 :end-placeholder="$t('end_date')"
                 align="right" style="background-color: var(--bind-phone-phone-bgcolor);">
              </el-date-picker>
            </div>
            <div class="transfer_input">
              <input type="text" v-model="transfer_initiator" name="" rows="1" :placeholder="$t('transfer_initiator')" style="overflow: hidden;width: 210px; height: 40px;background-color: var(--bind-phone-phone-bgcolor);border-radius: 6px;border: solid 1px var(--bind-phone-phone-bodercolor);line-height: 32px;padding-left: 9px;outline:none;color: var(--main-color);"></input>
            </div>
            <div class="transfer_input">
              <input type="text" v-model="transferred_user" name="" rows="1" :placeholder="$t('transferred_user')" style="overflow: hidden;width: 210px; height: 40px;background-color: var(--bind-phone-phone-bgcolor);border-radius: 6px;border: solid 1px var(--bind-phone-phone-bodercolor);line-height: 32px;padding-left: 9px;outline:none;color: var(--main-color);"></input>
            </div>
            <div>
              <button class="" tabindex="-1" type="button"  style="border: 0px;cursor: pointer;width: 92px;margin-top:5px;height: 32px;background-color: #006cdb;border-radius: 16px;color: #ffffff;" @click="loadInternalTransfers()">
                <span class="MuiButton-label">{{$t("search")}}</span>
              </button>
            </div>
          </div>
          <div class="showPre1">
            <div class="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content" style="margin-left: 0%;">
              <div role="tabpanel" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active">
                <div class="ant-row pages-assets-balances-coinlist-thead" style="background: var(--assets-new-label-bgcolor);">
                  <div class="ant-col ant-col-3" style="padding-left: 15px;width: 10%;padding-top: 2px;">{{$t("the_starting_time")}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 11%;padding-top: 2px;">{{$t("number_of_transfers")}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 18%;padding-top: 2px;">{{$t("Fee")}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 13%;padding-top: 2px;">{{$t("actual_arrival")}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px;">{{$t("transfer_initiator")}}</div>
                  <div class="ant-col ant-col-8" style="text-align: right; padding-right: 15px;width: 23%;padding-top: 2px;">{{$t("transferred_user")}}</div>
                </div>
                <div class="ant-row-flex pages-assets-balances-coinlist-trow" v-for="item in internal_transfers" style="padding: 0px 15px;" v-if = "internal_transfers.length > 0">
                  <div class="ant-col ant-col-3" style="padding-left: 15px;width: 10%;padding-top: 2px;">{{item.created_at}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 11%;padding-top: 2px;">{{item.total_amount}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 18%;padding-top: 2px;">{{item.fee}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 13%;padding-top: 2px;">{{item.actual_amount}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 25%;padding-top: 2px;">{{item.from_member_email}}</div>
                  <div class="ant-col ant-col-8" style="text-align: right; padding-right: 15px;width: 23%;padding-top: 2px;">{{item.to_member_email}}</div>
                </div>
                <div class="page">
                  <paging :dataAll="all" :dataCur="cur" :datanum="page_count" :dataDatanum="dataNum" @change="pageChange"></paging>
                  <div id="page_limit">
                    {{$t("every_page_shows")}}
                    <select class="fl" v-model="ProductActive"  @change="changeProduct($event)" style="color: #222">
                      <template v-for="(item,index) in productList">
                        <option :key="index" :value="item.id" selected="selected" v-if="currentPageCount === item.title">{{item.title}}</option>
                         <option :key="index" :value="item.id" v-else>{{item.title}}</option>
                      </template>
                    </select>
                  </div>
                </div>
                <div v-if = "internal_transfers.length < 0" style="height: 227px; text-align: center; margin-top: 3%">{{$t("no_data")}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
    <nav-footer  :language = "language"/>
  </div>
</template>
<style type="text/css" scoped>
 .pages-trade-orders-tableHeader{
    margin-top: 5px;
  }
  .page {
    height: 36px;
    margin: 40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .isShow{
    border-radius: 12px;
    margin: 0 auto;
    width: var(--page-content-width);
  }
  .coin_img{
    width:28px;
    height:28px;
    background: center center no-repeat no-repeat;
    background-size: cover;
  }
</style>
<script type="text/javascript">
import Vue from 'vue'
import NavFooter from '../../components/NavFooter'
import Paging from '../../components/paging'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    language: String,
    accountList: Array,
    isCertified: Boolean
  },
  data() {
    return {
      internal_transfers: [],
      all: '', //总页数
      cur: 1, //当前页码
      page_count: 20, //一页显示的数量  必须是奇数
      dataNum: '', //数据的数量
      productList:[{id:20,title:"20"},{id:50,title:"50"},{id:100,title:"100"},{id:200,title:"200"}],
      ProductActive: '',
      transfer_initiator: '',
      transferred_user: '',
      seach_date: '',
      seach_date_start: '',
      seach_date_end: '',
    }
  },
  created(){
    this.loadInternalTransfers()
  },
  computed: {
    pickerOptions: function() {
      return {
        cellClassName(time){
          return 'bginput';
        },
        shortcuts: [{
          text: this.$t('last_week'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('last_month'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('last_three_months'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  mounted() {
    this.ProductActive  = this.currentPageCount
  },
  methods: {
    p(s) {
      return s < 10 ? '0' + s : s
    },
    getYmdDate(date) {
      resDate = date.getFullYear() + '-' + this.p((date.getMonth() + 1)) + '-' + this.p(date.getDate())
      resTime = this.p(date.getHours()) + ':' + this.p(date.getMinutes()) + ':' + this.p(date.getSeconds())
      return resDate + ' ' + resTime;
    },

    loadInternalTransfers() {
      if (this.seach_date != '' && this.seach_date != null) {
        this.seach_date_start = this.getYmdDate(new Date(this.seach_date[0]))
        this.seach_date_end = this.getYmdDate(new Date(this.seach_date[1]))
      } else {
        this.seach_date_start = '';
        this.seach_date_end = '';
      }
      this.getUrl('/www/assets/internal_transfers', {
        code: this.$route.params.code,
        from_email: this.transfer_initiator,
        to_email: this.transferred_user,
        from_date: this.seach_date_start,
        to_date: this.seach_date_end,
        lang:this.currentLang,
        count: this.currentPageCount || 20,
        page: this.cur
      }).then((response) => {
        this.internal_transfers = response.data.internal_transfers
        this.dataNum = response.data["data_count"]
        this.all = Math.ceil(this.dataNum/this.currentPageCount)
      });
    },
    changeProduct(event) {
      this.ProductActive = event.target.value
      Vue.setStorageVariable({'page_count':this.ProductActive})
      this.page_count = this.ProductActive
      // 设为初始值
      this.cur = 1
      this.loadInternalTransfers()
    },
    pageChange: function(page) {
      this.cur = page
      this.loadInternalTransfers()
    },
  },
  components: {
    NavFooter,
    Paging
  },
}
</script>
