<template>
  <div class="pages-assets-balances-navigation-wrapper">
  	<div class="pages-assets-balances-navigation-container">
	    <div class="pages-assets-balances-asset-account">
        <div class="ant-tabs ant-tabs-top ant-tabs-line" style="text-align: center; height: 50px; padding-top: 15px;">
        	{{$t('currency_rate_description')}}
        </div>
      </div>
    </div>
  	<div class="pages-assets-balances-navigation-container">
	    <div class="pages-assets-balances-asset-account" style="margin-top: 15px;">
        <div class="ant-tabs ant-tabs-top ant-tabs-line">
          <div class="showPre1">
            <div>
        			<div style="width: 30%; margin: 10px 0px 0px 20px; padding: 20px 20px 20px 20px; border:1px solid #64686f; float: left;border-radius: 10px;">
        				<span>{{$t('transaction_rate')}}</span></br>
        				<span>{{$t('Transaction_deduct_received_assets')}}</span>
        			</div>
        			<div style="width: 30%; margin: 10px 0px 0px 40px; padding: 20px 20px 20px 20px; border:1px solid #64686f; float: left;border-radius: 10px;">
	        			<span>{{$t('recharge_rate')}}</span></br>
	        			<span>{{$t('free_admission')}}</span>
        			</div>
        			<div style="width: 30%; margin: 10px 20px 0px 0px; padding: 20px 20px 20px 20px; border:1px solid #64686f; float: right;border-radius: 10px;">
	        			<span>{{$t('withdrawal_rate')}}</span></br>
	        			<span>{{$t('withdrawal_service_fee_will_be_adjusted_regularly_according_to_the_actual_situation_of_the_block')}}</span>
        			</div>
        		</div>
            <div class="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content" style="margin-left: 0%;">
              <div role="tabpanel" class="ant-tabs-tabpane ant-tabs-tabpane-active">
                <div class="ant-row pages-assets-balances-coinlist-thead" style="background: var(--assets-new-label-bgcolor);">
                  <div class="ant-col ant-col-3" style="width: 20%;">{{$t("general_gertificate")}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 30%;">{{$t("full_name_of_tong_zheng")}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 30%;">{{$t("minimum_withdrawal_quantity")}}</div>
                  <div class="ant-col ant-col-3" style="text-align: right;width: 20%;">{{$t("service_charge_for_withdrawal")}}</div>
                </div>
                <div class="ant-row-flex pages-assets-balances-coinlist-trow" style="padding: 0px 15px;" v-for="item in currency_fee">
                  <div class="ant-col ant-col-3" style="text-align: left;width: 24.5%;">{{item.code}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 21%;">{{item.full_name}}</div>
                  <div class="ant-col ant-col-3" style="text-align: center;width: 38%;">{{item.min_withdraw_amount}}</div>
                  <div class="ant-col ant-col-3" style="text-align: right;width: 15%;">{{item.withdraw_fee_amount}}</div>
              	</div>
              </div>
            </div>
          </div>
        </div>
	    </div>
		</div>
  </div>
</template>
<script>
export default {
	data(){
    return {
    	currency_fee: []
    }
  },
  computed: {
  },
  mounted() {
   this.getWithdrawCurrencyFee();
  },
  components: {
  },
  methods: {
  	getWithdrawCurrencyFee(){
  		this.getUrl('/www/withdraw_currency_fee', {}, true,true).then((response) => {
  			this.currency_fee = response.data["currencies"]
  		});
  	},
  },
}
</script>