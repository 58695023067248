import pako from 'pako';
/*
 * 说明: 本方法用来与websocket做连接, 同步更新数据到vuex
 * 所以跟传统的websocket的调用有很明显区别.
 *
 * 传统: 一切都在websocket的 onmessage 中
 *
 * 本方法: 在该文件中定义了websocket的通用方法, 收到response之后(onmessage) ,把内容保存在vuex的store中
 * 在其他页面想调用的时候,直接读取vuex的状态即可.
 *
 * 引入用法:
 *
 * import ws from './utils/websocket'
 * Vue.use(ws, request_url.websocket_ws, {store: store})
 *
 * 1. 订阅:
 * this.$websocket.subscribe('test_channel')
 *
 * 2. 取消订阅:
 * this.$websocket.unsubscribe('test_channel')
 *
 * 3. 获取数据:
 * 需要在vuex/modules/websocket.js中手动添加
 * const state = {
 *   test_channel: {}
 * }
 * const mutations = {
 *   ['TEST_CHANNEL'] (state, data) {
 *     state.test_channel = data
 *   }
 * }
*/
export default {
  // install是一个钩子方法， 当某个 component被 new的时候，会被自动调用。
  install(Vue, connection, options) {
    let ws;
    let isWebsocketOpened = false;
    // let lastHeartBeat = new Date().getTime() ; //上一次心跳时间
    let reconnectTime=12000; // 每12秒检测一次连接状况
    if (!ws) {
      ws = new WebSocket(connection)
    }
    /**
     * 关键方法: 处理 onmessage返回的数据, 把它做binary的解析, 然后保存到vuex 的store中
     */
    let handleMessage = function(e) {
      // lastHeartBeat = new Date().getTime() //设置心跳
      if (e.data === '') {
        return
      }
      let blob = e.data;
      let reader = new FileReader();
      reader.readAsBinaryString(blob);
      reader.onload = function (evt) {
        let data = pako.inflate(evt.target.result, { to: 'string' })
        if (data && typeof(data) === 'string') {
          let responseBody = JSON.parse(data)
          // console.info(responseBody);
          // console.log(responseBody)
          // {"channel":"member_account·usdt·zh·c89df223bd1b58de72cec85e27db2f52a665ac7211885c0c91bd6763d09fc301","content":{"balance":"11954277.8903929470466619"}}
          passToStore(responseBody['channel'].split('·')[0], getRealContent(responseBody['content'], responseBody['channel']))
          // 例子： {"channel":"real_time_trades·arpausdt·en","content":{"data":[{"price":"0.007196","amount":"658.66","direction":"Sell","sell_or_buy":"bid","closing_time":"15:58:59"},
        }
      };
    }

    let getRealContent = function(content, channelInResponse){
      let result = content
      if(channelInResponse.split('·')[0] === "member_account"){
        let currencyCode = channelInResponse.split('·')[1]
        result[currencyCode] = content["balance"]
      }
      return result
    }

    // 检查断线
    let checkConnection = function() {
      // 如果ws 没有断开。就走下面。
      try {
        // 发一个空字符串就行了，服务器会执行echo操作(返回一个空字符串)，更好的做法是发 Ping Frame，可惜前端发不了
        ws.send('');
        // console.info('lastHeartBeat == %s', lastHeartBeat)
        // if ((new Date().getTime() - lastHeartBeat) > overtime) {
        //   reconnect();
        // }
      } catch(e) {
        // 如果ws 已经断开了，那么就直接重连
        console.error(e.stack)
        reconnect();
      }
    }
    let reconnect = function() {
      console.warn('websocket重连开始...')
      try {
        console.info("closing previous websocket")
        ws.close()
      } catch(e) {
        console.warn(e.stack)
      }

      ws = new WebSocket(connection)
      let new_websocket = new Socket()
      ws.onopen = function(e) {
        // ws.send('');
        console.info('重连频道列表：')
        console.log(websocket.subscriptionChannels)
        websocket.subscriptionChannels.forEach((channel)=>{
          new_websocket.subscribe(channel)
        })
        Vue.prototype.$websocket = new_websocket
      }
      ws.onmessage = function(e) {
        handleMessage(e)
      };
    }
    setInterval(checkConnection, reconnectTime); //每5秒查询一次
    /**
     * 关键方法: 保存websocket返回的内容到 store ( vuex)
     *
     */
    let passToStore = function(eventName, responseData) {
      let method = 'commit'
      let target = eventName.toUpperCase()
      // 例如   store['commit']('TEST_CHANNEL', "{data: order_book: ...}")
      // 该options来自于main.js的:
      //        Vue.use(ws, request_url.websocket_ws, {store: store})
      //        也就是: {store: store}['commit'](...)  ==   store.commit(...)
      //
      options.store[method](target, responseData)   // 等同于：  store.commit('ORDER_BOOKS', "{[...]}")
    }
    Socket = function() {
      //已经订阅的频道
      this.subscriptionChannels = []
      this.subscriptionChannelsProxy = new Proxy(this.subscriptionChannels, {
        apply: function(target, thisArg, argumentList) {
          return thisArg[target].apply(this, argumentList)
        },
        deleteProperty: function(target, property) {
          console.warn('Deleted index %s', property)
          return true
        },
        set: function(target, property, channel, receiver) {
          console.warn("set %s to %o", property, channel)
          console.warn('isWebsocketOpened = %s', isWebsocketOpened)
          if (isWebsocketOpened && property !== 'length') {
            let index = target.indexOf(target[property])
            if (index >= 0) {
              console.warn('已订阅的频道，无法重复订阅: ' + target[property])
            } else {
              try {
                // 这里用try的原因是：Uncaught (in promise) DOMException: Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.
                ws.send("subscribe " + channel);
              } catch(e) {
                // FIXME 这里需要等ws连接好之后，再send
                // 可以等2，3秒
              }
            }
          }
          target[property] = channel;
          return true
        }
      });
    }
    // 订阅方法
    Socket.prototype.subscribe = function(channel) {
      this.subscriptionChannelsProxy.push(channel)
    }
    // 取消订阅方法
    Socket.prototype.unsubscribe = function(channel) {
      if (!isWebsocketOpened) {
        return
      }
      let index = this.subscriptionChannels.indexOf(channel)
      if (index >= 0) {
        this.subscriptionChannelsProxy.splice(index, 1)
        ws.send("unsubscribe " + channel);
      } else {
        console.warn('未订阅的频道，无法取消订阅: ' + channel)
      }
    }
    Socket.prototype.unsubscribeAllChannels = function() {
      if (!isWebsocketOpened) {
        console.warn("== in unsubscribeAllChannels, Websocket is not connected!")
        return
      }
      this.subscriptionChannelsProxy.forEach((channel) => {
        ws.send("unsubscribe " + channel);
      })
      this.subscriptionChannels = []
    }
    let websocket = new Socket()

    // 初始化websocket
    websocket.ws = ws
    ws.onopen = function() {
      isWebsocketOpened = true
      console.warn('onopen: channels need to subscribe:')
      console.warn(websocket.subscriptionChannels)
      websocket.subscriptionChannels.forEach((channel) => {
        ws.send("subscribe " + channel);
      })
    }
    // 处理从服务器传递过来的数据
    ws.onmessage = function(e) {
      handleMessage(e)
    };
    Vue.prototype.$websocket = websocket
  }
}
