<template>
<div style="box-sizing: border-box;background-color: var(--my-assets-bgcolor);min-width: var(--page-content-width)">
  <currency-fee/>
  <nav-footer  :language = "language"/>
</div>
</template>
<style lang="scss">
/*@import url('../css/theme_assets.scss');
@import url('../css/layouts.d2940ff7.chunk.scss');
@import url('../css/umi.0d36cc22.scss');
@import url('../css/assets_other.scss');*/
</style>
<script>
import CurrencyFee from '../components/CurrencyFee'
import NavFooter from '../components/NavFooter'
export default {
  created(){
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  mounted() {
   
  },
  components: {
    CurrencyFee,
    NavFooter
  }
}
</script>