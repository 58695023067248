import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true; // 携带cookie

Vue.use(VueAxios, axios)

export default function (Vue) {
    Vue.mixin({
        methods: {
            getUrl(path, params) {
                let url = Vue.getValueByKey('api_path') + path
                //console.info(url)
                return new Promise((resolve, reject)=>{
                    this.$http.get(url, { params: params }).then((response) => {
                        //console.info(response.data)
                        if (response.data.code === 20004) {
                          this.clearSign();
                          location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
                        } else {
                          resolve(response)
                        }
                    }, (error) => {
                        //console.error(error)
                        reject(error)
                    });
                })

            },
            postUrl(path, params, noTip_success=false, noTip_error=false) {
                let url = Vue.getValueByKey('api_path') + path
                // console.info(url)
                return new Promise((resolve, reject)=>{
                    this.$http.post(url, params).then((response) => {
                        //console.info(response.data)
                        //if (response.data.code === 20004 && (path.indexOf('service_st_cookie_validate') < 0)) {
                        if (response.data.code === 20004 && this.$route.path != '/' && this.$route.path != '/asset_introduction' 
                          && this.$route.path != '/sales_promotion' && this.$route.path.split('/')[1] != 'show_sales_promotion' 
                          && this.$route.path != '/currency_fee') {
                          this.clearSign();
                          location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
                        } else {
                          //FIXME
                          if (response.data.code ===  20000) {
                            if (!noTip_success) {
                              this.$message({
                                message: response.data.message,
                                type: 'success'
                              })
                            }
                          } else {
                            if (!noTip_error) {
                              this.$message({
                                message: response.data.message,
                                type: 'error'
                              })
                            }
                          }
                          resolve(response)
                        }
                    }, (error) => {
                        // console.error(error)
                        reject(error)
                    });
                })
            },
            postGameUrl(path, params, noTip_success=false, noTip_error=false) {
                let url = Vue.getValueByKey('game_api_path') + path
                // console.info(url)
                return new Promise((resolve, reject)=>{
                    this.$http.post(url, params).then((response) => {
                        //console.info(response.data)
                        //if (response.data.code === 20004 && (path.indexOf('service_st_cookie_validate') < 0)) {
                        if (response.data.code === 20004 && this.$route.path != '/' && this.$route.path != '/asset_introduction' 
                          && this.$route.path != '/sales_promotion' && this.$route.path.split('/')[1] != 'show_sales_promotion') {
                          this.clearSign();
                          location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
                        } else {
                          //FIXME
                          if (response.data.code ===  20000) {
                            if (!noTip_success) {
                              this.$message({
                                message: response.data.message,
                                type: 'success'
                              })
                            }
                          } else {
                            if (!noTip_error) {
                              this.$message({
                                message: response.data.message,
                                type: 'error'
                              })
                            }
                          }
                          resolve(response)
                        }
                    }, (error) => {
                        // console.error(error)
                        reject(error)
                    });
                })
            }
        }
    })
}
