<template>
<section class="pages-ucenter-commonform-content" style="margin-top: 15px">
    <router-link to="/user_center" style="color: rgb(0, 108, 219);font-size: 16px;"><i class="el-icon-back"></i>{{$t("return")}}</router-link>
    <div class="auth_error_msg">{{authErrorMsg}}</div>
    <div class="">
        <div class="identity_header">
            <div class="check_auth_button" @click="checkAuth" :title="citizen">
                <span>{{citizen}}</span>
            </div>
            <div class="identity_tip" :title="please_bind_your_id_card_you_will_have_the_highest_authority_after_real_name_authentication_we_will_strictly_keep_your_identity_confidential">
              {{please_bind_your_id_card_you_will_have_the_highest_authority_after_real_name_authentication_we_will_strictly_keep_your_identity_confidential}}
            </div>
        </div>
        <div class="identity_content" style="padding-left: 25px">
            <div class="identity_progress_bar">
                <img :src="circle_one" @click="backUpload('circle_one')" style="cursor: pointer;" />
                <img :src="line_one" />
                <img :src="circle_two" @click="backUpload('circle_two')" style="cursor: pointer;" v-if="currentIdentAuth == 'chinese'"/>
                <img :src="line_two"/>
                <img :src="circle_three"  @click="backUpload('circle_three')"  style="cursor: pointer;" />
            </div>
            <div class="identity_content" v-if="!upload_one">
                <div class="identity_content_div">
                   <div class="char" >◆</div>
                   <img :src="document_front_img" width="340" height="213" />
                   <div class="identity_content_div_title">{{document_front}}</div>
                   <div class="identity_content_div_msg">（{{$t('the_file_must_be_less_than_2mb')}}）</div>
                   <el-upload
                      class="upload-demo"
                      :action="uploadUrl+ '?img_type=document_front_img&type=' + currentIdentAuth"
                      accept="image/png,image/jpg,image/jpeg"
                      :limit = "1"
                      ref="document_front_img"
                      :on-success="handleAvatarSuccess"
                      :beforeUpload="beforeAvatarUpload"
                      :on-change="changeFile"
                      >
                      {{$t("upload_image")}}
                   </el-upload>
                </div>
            </div>
            <div class="identity_content" v-if="!upload_two && upload_one && currentIdentAuth == 'chinese'" style="margin-left: 252px;">
                <div class="identity_content_div">
                   <div class="char" >◆</div>
                   <img :src="document_negative_img" width="340" height="213" />
                   <div class="identity_content_div_title">{{document_negative}}</div>
                   <div class="identity_content_div_msg">（{{$t('the_file_must_be_less_than_2mb')}}）</div>
                   <el-upload
                      class="upload-demo2"
                      :action="uploadUrl+ '?img_type=document_negative_img&type=' + currentIdentAuth"
                      accept="image/png,image/jpg,image/jpeg"
                      :limit = "1"
                      ref="document_negative_img"
                      :on-success="handleAvatarSuccess"
                      :beforeUpload="beforeAvatarUpload"
                      :on-change="changeFile"
                      >
                      {{$t("upload_image")}}
                   </el-upload>
                </div>
            </div>
            <div class="identity_content" v-if="!upload_three && upload_one && upload_two" style="margin-left: 506px;"  :style="{'margin-left':currentIdentAuth == 'overseas' ? '484px':'506px'}" >
                <div class="identity_content_div">
                   <div class="char" >◆</div>
                   <img :src="document_hand_held_img" width="323" height="114" v-if="currentIdentAuth == 'chinese'" />
                   <img :src="document_hand_held_img" width="340" height="213" v-if="currentIdentAuth == 'overseas'" />
                   <div class="identity_content_div_hand_title">{{$t('id_photo')}}</div>
                   <div class="identity_content_div_hand_msg">
                   {{document_all_the_information_on_the_id_card_is_clearly_visible_and_you_must_be_able_to_see_the_photo_of_the_id_number}},<br>
                   {{$t('need_to_be_exempted_from_the_crown_it_is_recommended_that_the_unfinished_hand_held_documentary_is_facial_features_clearly_visible_photos')}},<br>
                   {{$t('the_content_is_true_and_effective_and_must_not_be_modified_support_jpg_png_format_photo_the_size_does_not_exceed_2m')}}
                   </div>
                   <div class="identity_content_div_hand_title2">{{handheld_document}}</div>
                   <div class="identity_content_div_msg">（{{$t('the_file_must_be_less_than_2mb')}}）</div>
                   <el-upload
                      class="upload-demo3"
                      :action="uploadUrl+ '?img_type=document_hand_held_img&type=' + currentIdentAuth"
                      accept="image/png,image/jpg,image/jpeg"
                      :limit = "1"
                      ref="document_hand_held_img"
                      :on-success="handleAvatarSuccess"
                      :beforeUpload="beforeAvatarUpload"
                      :on-change="changeFile"
                      >
                      {{$t("upload_image")}}
                   </el-upload>
                </div>
            </div>
            <div class="identity_next">
                <button type="button" class="identity_next_button" style="outline:none;border: 0px" @click="nextUploadIdcard" :title="$t('confirm_binding')">
                    <span>{{$t("confirm_binding")}}</span>&nbsp;
                    <i class="el-icon-d-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</section>
</template>
<style type="text/css">
    .upload-demo{
        margin-top: 27px;
    }
    .upload-demo2{
        margin-top: 27px;
    }
    .upload-demo3{
        margin-top: 27px;
    }
    .auth_error_msg{
        margin-top: 15px;
        margin-bottom: 15px;
        color: var(--features-no-make-color);
    }
    .el-button--small:hover{
        background-color:rgba(0,0,0,0);
        border-color:rgba(0,0,0,0)
    }
    .el-button--primary{
        background-color:rgba(0,0,0,0);
        border-color:rgba(0,0,0,0)
    }
    .el-button--small{
        background-color: rgba(0,0,0,0)
    }
    .el-button--small{
        border: 0px solid red;
    }
    .identity_content_div_hand_title{
        font-size: 14px;
        padding-top: 5px;
        color: #9a9fb2;
    }
    .identity_content_div_hand_msg{
        padding-top: 4px;
        font-size: 12px;
        color: var(--auth-idcard-hand-color);
        width: 325px;
        text-align: left;
        padding-left: 35px;
    }
    .identity_content_div_hand_title2{
        padding-top: 8px;
        font-size: 16px;
        color: var(--auth-idcard-hand-color);
    }
    .char{
        color:var(--auth-idcard-bgcolor);
        font-size: 30px;
        position: relative;
        left: 0px;
        top: -23px;
    }
    .el-upload__input{
        opacity:0;
        position: absolute;
        margin-top: -33px;
    }
    .el-upload--text{
        display: inline-block;
        border-radius: 18px;
        padding: 4px 12px;
        overflow: hidden;
        color: #ffffff;
        text-decoration: none;
        text-indent: 0;
        line-height: 20px;
        width: 336px;
        height: 38px;
        line-height: 30px;
        background: -webkit-linear-gradient(left, rgb(118, 85, 233), rgb(79, 141, 233));
        margin-bottom: 3px;
    }
    .identity_next{
        margin-top: 5px;
        margin-left: 930px;
        color: #ffffff;
    }
    .identity_next_button{
        width: 190px;
        height: 46px;
        background-color: var(--auth-next-button-bgcolor);
        border-radius: 23px;
    }
    .identity_content_div_button{
        border-radius: 18px;
        background: -webkit-linear-gradient(left, #7655e9,#4f8de9);
        width: 336px;
        height: 40px;
        margin-top: 25px;
        margin-bottom: 10px;
    }
    .identity_content_div_msg{
        font-size: 14px;
        color: #9a9fb2;
        padding-top: 4px;
    }
    .identity_content_div_title{
        font-size: 16px;
        color: #0e1832;
        padding-top: 7px;
    }
    .identity_content_div img{
        margin-top: -34px;
    }
    .identity_content_div{
        width: 364px;
        /*height: 366px;*/
        border: solid 1px var(--auth-idcard-bodercolor);
        margin-left: 78px;
        margin-top: 18px;
        box-shadow: 0px 0px 46px 0px
        rgba(89, 92, 118, 0.24);
        border-radius: 8px;
        text-align: center;
        background-color: var(--auth-idcard-bgcolor);
    }
    .identity_progress_bar{
        padding-left: 245px;
        padding-top: 50px;
    }
    .identity_header{
        display: flex;
    }
    .identity_tip{
        background-color:var(--auth-headercontent-bgcolor);
        border-radius: 6px;
        border: solid 1px var(--auth-headercontent-bodercolor);
        text-align: center;
        color: var(--auth-headercontent-color);
        margin-left: 20px;
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-right: 18px;
        width: auto;
    }
    .check_auth_button{
        display: flex;
        background-color: var(--auth-header-bgcolor);
        border-radius: 6px;
        border: solid 1px var(--auth-header-bodercolor);
        text-align: center;
        cursor:pointer;
        color: var(--auth-header-color);
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 18px;
        padding-bottom: 18px;
        overflow: hidden;
    }

    .check_auth_button .identity_icon{
        font-size: 25px;
        line-height: 55px;
        color: #d9dde5;
    }
</style>
<script type="text/javascript">
import Vue from 'vue'
import { Loading } from 'element-ui'
export default {
  props: {
      // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)

  },

  data() {
     return {
        uploadUrl:Vue.getValueByKey('api_path') + '/www/upload/upload_img',
        upload_one:false,
        upload_two:false,
        upload_three:false,
        document_front_img_base64:'',
        document_front_img_url: '',
        document_negative_img_base64:'',
        document_negative_img_url: '',
        document_hand_held_img_base64: '',
        document_hand_held_img_url: ''
     }
  },
  mounted() {

  },
  computed: {
    authErrorMsg: function () {
        if(this.currentJudgeIsLoggedIn){
            if(this.currentUserInfo.auth_status == 4){
                return this.$t('reason_for_audit_failure')+' : '+this.currentUserInfo.auth_reason
            }else{
                return '';
            }
        }else{
            return '';
        }
    },
    document_front_img: function () {
        if(this.document_front_img_base64 != ''){
            return this.document_front_img_base64;
        }
        if(this.currentIdentAuth == "chinese"){
            if(this.currentTheme == 'light'){
                return "https://cdn.gda.top/image_after_2019_06/2040/id_card_front_write.png";
            }else{
                return "https://cdn.gda.top/image_after_2019_06/2039/id_card_front_black.png";
            }
        }else{
            if(this.currentTheme == 'light'){
                return "https://cdn.gda.top/image_after_2019_06/2048/passport_front_write.png";
            }else{
                return "https://cdn.gda.top/image_after_2019_06/2034/passport_front_black.png";
            }
        }
    },
    document_negative_img: function () {
        if(this.document_negative_img_base64 != ''){
            return this.document_negative_img_base64;
        }
        if(this.currentIdentAuth == "chinese"){
            if(this.currentTheme == 'light'){
                return "https://cdn.gda.top/image_after_2019_06/2050/passport_negative_write.png";
            }else{
                return "https://cdn.gda.top/image_after_2019_06/2049/passport_negative_black.png";
            }

        }else{
            if(this.currentTheme == 'light'){
                return "https://cdn.gda.top/image_after_2019_06/2050/passport_negative_write.png";
            }else{
                return "https://cdn.gda.top/image_after_2019_06/2049/passport_negative_black.png";
            }
        }
    },
    document_hand_held_img: function () {
        if(this.document_hand_held_img_base64 != ''){
            return this.document_hand_held_img_base64;
        }
        if(this.currentIdentAuth == "chinese"){
            if(this.currentTheme == 'light'){
                return "https://cdn.gda.top/image_after_2019_06/2035/hand_held_id_card_write.png";
            }else{
                return "https://cdn.gda.top/image_after_2019_06/2033/hand_held_id_card_black.png";
            }
        }else{
            if(this.currentTheme == 'light'){
                return "https://cdn.gda.top/image_after_2019_06/2038/hand_held_passport_write.png";
            }else{
                return "https://cdn.gda.top/image_after_2019_06/2037/hand_held_passport_black.png";
            }
        }
    },
    document_front: function (){
        if(this.currentIdentAuth == "chinese"){
            return this.$t('id_card_front');
        }else{
            return this.$t('passport_front');
        }
    },
    document_negative: function (){
        if(this.currentIdentAuth == "chinese"){
            return this.$t('id_card_negative');
        }else{
            return this.$t('passport_negative');
        }
    },
    document_all_the_information_on_the_id_card_is_clearly_visible_and_you_must_be_able_to_see_the_photo_of_the_id_number: function (){
        if(this.currentIdentAuth == "chinese"){
            return this.$t('all_the_information_on_the_id_card_is_clearly_visible_and_you_must_be_able_to_see_the_photo_of_the_id_number');
        }else{
            return this.$t('all_the_information_on_the_passport_is_clearly_visible_and_you_must_be_able_to_see_the_photo_of_the_id_number');
        }
    },
    handheld_document: function (){
        if(this.currentIdentAuth == "chinese"){
            return this.$t('hand_held_identity_card');
        }else{
            return this.$t('handheld_passport');
        }
    },
    please_bind_your_id_card_you_will_have_the_highest_authority_after_real_name_authentication_we_will_strictly_keep_your_identity_confidential: function (){
        if(this.currentIdentAuth == "chinese"){
            return this.$t('please_bind_your_id_card_you_will_have_the_highest_authority_after_real_name_authentication_we_will_strictly_keep_your_identity_confidential')+'.';
        }else{
            return this.$t('please_bind_your_passport_you_will_have_the_highest_authority_after_real_name_authentication_we_will_strictly_keep_your_identity_confidential')+'.';
        }
    },
    circle_one: function (){
        if(this.document_front_img_url == ''){
            return "https://cdn.gda.top/image_after_2019_06/2036/circle_no_check.png";
        }else{
            return "https://cdn.gda.top/image_after_2019_06/2032/circle_check.png";
        }
    },
    line_one: function (){
        if(this.currentIdentAuth == "chinese"){
            if(this.document_negative_img_url == ''){
                return "https://cdn.gda.top/image_after_2019_06/2045/line_no_check.png";
            }else{
                return "https://cdn.gda.top/image_after_2019_06/2044/line_check.png";
            }
        }else{
            if(this.document_hand_held_img_url == ''){
                return "https://cdn.gda.top/image_after_2019_06/2045/line_no_check.png";
            }else{
                return "https://cdn.gda.top/image_after_2019_06/2044/line_check.png";
            }
        }
    },
    circle_two: function (){
        if(this.document_negative_img_url == ''){
            return "https://cdn.gda.top/image_after_2019_06/2036/circle_no_check.png";
        }else{
            return "https://cdn.gda.top/image_after_2019_06/2032/circle_check.png";
        }
    },
    line_two: function (){
        if(this.document_hand_held_img_url == ''){
            return "https://cdn.gda.top/image_after_2019_06/2045/line_no_check.png";
        }else{
            return "https://cdn.gda.top/image_after_2019_06/2044/line_check.png";
        }
    },
    circle_three: function (){
        if(this.document_hand_held_img_url == ''){
            return "https://cdn.gda.top/image_after_2019_06/2036/circle_no_check.png";
        }else{
            return "https://cdn.gda.top/image_after_2019_06/2032/circle_check.png";
        }
    },
    citizen: function () {
        if(this.currentIdentAuth == "chinese"){
            return this.$t('switch_to_overseas_citizen');
        }else{
            return this.$t('switch_to_chinese_citizen');
        }
    }
  },
  methods: {
    changeFile(file, fileList) {
        if(file.response){
            var This = this;
            var reader = new FileReader();
            reader.readAsDataURL(file.raw);
            that = this
            reader.onload = function(e){
                if(file.response.type == 'document_front_img'){
                    that.document_front_img_base64 = this.result
                    that.$refs.document_front_img.clearFiles();
                    if(that.currentIdentAuth == "chinese"){
                        if(that.document_negative_img_url == '' && that.document_hand_held_img_url == ''){
                            that.upload_one = true;
                            that.upload_two = false;
                            that.upload_three = true;
                        }
                    }else{
                         if(that.document_negative_img_url == '' && that.document_hand_held_img_url == ''){
                            that.upload_one = true;
                            that.upload_two = true;
                            that.upload_three = false;
                        }
                    }

                }else if(file.response.type == 'document_negative_img'){
                    that.document_negative_img_base64 = this.result
                    that.$refs.document_negative_img.clearFiles();
                    if(that.document_front_img_url != '' && that.document_hand_held_img_url == ''){
                        that.upload_one = true;
                        that.upload_two = true;
                        that.upload_three = false;
                    }
                }else if(file.response.type == 'document_hand_held_img'){
                    that.document_hand_held_img_base64 = this.result
                    that.$refs.document_hand_held_img.clearFiles();
                }
            }
        }
    },
    handleAvatarSuccess (res, file) {
        if(file.response){
            if(file.response.type == 'document_front_img'){
                this.document_front_img_url = res.url;
            }else if(file.response.type == 'document_negative_img'){
                this.document_negative_img_url = res.url;
            }else if(file.response.type == 'document_hand_held_img'){
                this.document_hand_held_img_url = res.url;
            }
        }
    },
    beforeAvatarUpload(file) {
      var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
      // const extension = testmsg === 'pdf' ||  testmsg === 'PDF';
      const extension = testmsg === 'jpg' ||  testmsg === 'JPG' ||  testmsg === 'png' ||  testmsg === 'PNG' || testmsg === 'jpeg' || testmsg === 'JPEG';
        const isLt2M = file.size / 1024 / 1024 < 2
        if(!extension ) {
          this.$message({
            message: this.$t('upload_files_can_only_be_in_jpg_or_png_format')+'!',
            type: 'warning'
          })
          return false;//必须加上return false; 才能阻止
        }
        if(!isLt2M) {
          this.$message({
            message: this.$t('upload_file_size_cannot_exceed_2MB'),
            type: 'warning'
          })
         return false;
        }
        return extension ||  isLt2M
    },
    backUpload(back){
        if(back == 'circle_one'){
            this.upload_one = false;
            this.upload_two = false;
            this.upload_three = false;
        }else if(back == 'circle_two'){
            if(this.document_front_img_url != ''){
                this.upload_one = true;
                this.upload_two = false;
            }
        }else if(back == 'circle_three'){
            if(this.currentIdentAuth == "chinese"){
                if(this.document_front_img_url != '' && this.document_negative_img_url != ''){
                    this.upload_one = true;
                    this.upload_two = true;
                    this.upload_three = false;
                }
            }else{
                if(this.document_front_img_url != ''){
                    this.upload_one = true;
                    this.upload_two = true;
                    this.upload_three = false;
                }
            }

        }
    },
    nextUploadIdcard(){
        if(this.currentIdentAuth == "chinese"){
            if(this.upload_one == false){
                if(this.document_front_img_url == ''){
                    this.$message({
                        message: this.$t('please_upload_the_front_photo_of_your_id_card_first'),
                        type: 'warning'
                    })
                    return false;
                }
                this.upload_one = true;
            }else if(this.upload_two == false){
                if(this.document_negative_img_url == ''){
                    this.$message({
                        message: this.$t('please_upload_the_reverse_photo_of_your_id_card_first'),
                        type: 'warning'
                    })
                    return false;
                }
                this.upload_two = true;
            }else{
                if(this.document_front_img_url == ''){
                    this.$message({
                        message: this.$t('please_upload_the_front_photo_of_your_id_card_first'),
                        type: 'warning'
                    })
                    return false;
                }
                if(this.document_negative_img_url == ''){
                    this.$message({
                        message: this.$t('please_upload_the_reverse_photo_of_your_id_card_first'),
                        type: 'warning'
                    })
                    return false;
                }
                if(this.document_hand_held_img_url == ''){
                    this.$message({
                        message: this.$t('please_upload_your_id_photo_first'),
                        type: 'warning'
                    })
                    return false;
                }
                var loadingInstance = Loading.service({fullscreen: true, text: "上传中" })
                this.submitAuth();
                //this.upload_three = true;
            }
        }else{
            if(this.upload_one == false){
                if(this.document_front_img_url == ''){
                    this.$message({
                        message: this.$t('please_upload_the_front_photo_of_your_passport_first'),
                        type: 'warning'
                    })
                    return false;
                }
                this.upload_one = true;
                this.upload_two = true;
            }else{
                if(this.document_front_img_url == ''){
                    this.$message({
                        message: this.$t('please_upload_the_front_photo_of_your_passport_first'),
                        type: 'warning'
                    })
                    return false;
                }
                if(this.document_hand_held_img_url == ''){
                    this.$message({
                        message: this.$t('please_upload_your_passport_photo_first'),
                        type: 'warning'
                    })
                    return false;
                }
                var loadingInstance = Loading.service({fullscreen: true, text: "上传中" })
                this.submitAuth();
                //this.upload_three = true;
            }
        }
        loadingInstance.close();
    },
    submitAuth(){
        this.postUrl('/www/user_center/identity_authentication', {
            lang:this.currentLang,
            auth_type: this.currentIdentAuth,
            document_front_img_url: this.document_front_img_url,
            document_negative_img_url: this.document_negative_img_url,
            document_hand_held_img_url: this.document_hand_held_img_url
         }).then((response) => {
            if(response.data.code == 20000){
              //重新加载用户数据
              this.getMemberInfo();
              this.$router.push({path:'/user_center'}).catch(data => {  })
            }
         });
    },
    checkAuth(){
        if(this.currentIdentAuth == "chinese"){
            //护照不显示 证件反面照片
             Vue.setStorageVariable({'ident_auth': 'overseas'});
        }else{
             Vue.setStorageVariable({'ident_auth': 'chinese'});
        }
       location.reload();
    },
  }
}
</script>
