<template>
  <div>
    <el-dialog :visible.sync="currentOperationValidationShow" title='请填写验证信息' width="680px" :show-close=false center>
      <div class="dialogDiv" style="margin: 0px 100px 0 95px;">
        <div class="pages-ucenter-bind-phone-index-captcha">
          <div class="ant-row ant-form-item" v-if="currentIsMobileBound">
            <div class="ant-col ant-form-item-label">
              <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('please_enter_the_phone_verification_code')">{{$t("please_enter_the_phone_verification_code")}}</label></div>
            <div class="ant-col ant-form-item-control-wrapper">
              <div class="ant-form-item-control" >
                <span class="ant-form-item-children">
                  <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                    <span class="ant-input-wrapper ant-input-group">
                      <input type="text" class="ant-input" style="width: 320px;" v-model="mobileCode" @keyup="submitButton()" maxlength="6" onkeyup="this.value=this.value.replace(/[^0-9.]+/g,'')">
                      <button type="button" class="ant-btn ant-btn-primary" :disabled="phoneSmsTimeShow" @click="phoneMsmSendOld" style="background-color: #006cdb;opacity: 1;font-weight:normal;border: 0px; margin-left: 10px; width: 130px;height: 44px;">
                          <span style="font-size: 14px;">{{phoneMsmButton}}</span>
                      </button>
                    </span>
                    <span class="error" v-if="!currentMobileCodeValidation">{{$t('mobile_code_error')}}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="ant-row ant-form-item" v-if="currentIsEmailBound">
            <div class="ant-col ant-form-item-label">
              <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('please_enter_the_email_verification_code')">{{$t("please_enter_the_email_verification_code")}}</label></div>
            <div class="ant-col ant-form-item-control-wrapper">
              <div class="ant-form-item-control" >
                <span class="ant-form-item-children">
                  <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                    <span class="ant-input-wrapper ant-input-group">
                      <input type="text" class="ant-input" style="width: 320px;" v-model="emailCode" @keyup="submitButton()" maxlength="6" onkeyup="this.value=this.value.replace(/[^0-9.]+/g,'')">
                      <button type="button" class="ant-btn ant-btn-primary" :disabled="emailCodeTimeShow" @click="emailMsmSendOldbind()" style="background-color: #006cdb;opacity: 1;font-weight:normal;border: 0px; margin-left: 10px; width: 130px;height: 44px;">
                          <span style="font-size: 14px;">{{emailCodeButton}}</span>
                      </button>
                    </span>
                    <span class="error" v-if="!currentEmailCodeValidation">{{$t('email_code_error')}}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="ant-row ant-form-item" v-if="currentIsOtpBinded">
            <div class="ant-col ant-form-item-label">
              <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('please_enter_the_google_verification_code')">{{$t("please_enter_the_google_verification_code")}}</label></div>
            <div class="ant-col ant-form-item-control-wrapper">
              <div class="ant-form-item-control" >
                <span class="ant-form-item-children">
                  <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                    <span class="ant-input-wrapper ant-input-group" style="margin-left: -15px;">
                      <input type="text" class="ant-input" placeholder="e.g. 123456" style="width: 320px;" v-model="otpCode" @keyup="submitButton()" maxlength="32" onkeyup="this.value=this.value.replace(/[^0-9.]+/g,'')" autocomplete="off">
                    </span>
                    <span class="error" v-if="!currentOtpCodeValidation">{{$t('otp_code_error')}}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="ant-row ant-form-item" v-if="currentIsAssetsPasswordBound">
            <div class="ant-col ant-form-item-label">
              <label for="smsCode" class="ant-form-item-required ant-form-item-no-colon" :title="$t('old_fund_password')">{{$t("old_fund_password")}}</label></div>
            <div class="ant-col ant-form-item-control-wrapper">
              <div class="ant-form-item-control" >
                <span class="ant-form-item-children">
                  <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                    <span class="ant-input-wrapper ant-input-group" style="margin-left: -15px;">
                      <input type="password" class="ant-input" style="width: 320px;" v-model="assetsPassword" @keyup="submitButton()" maxlength="32" autocomplete="new-password">
                    </span>
                    <span class="error" v-if="!currentAssetsPasswordValidation">{{currentInputAssetsPasswordTimes}}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <span slot="footer" class="dialog-footer">
          <el-button @click="submitOperationValidation" :disabled = "submitButtonDisabled" style="background-color: #5CACEE;">
            {{ $t('determine') }}
          </el-button>
        </span>
        <span slot="footer" class="dialog-footer" style="margin-left: 15px;">
          <el-button @click="closeOperationValidation"  style="background-color: #5CACEE;">
            {{ $t('cancel') }}
          </el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>           
  .error {
    color: red;
  }
  .secondary-title{
    margin-top: 5px;
  }
</style>
<script>
import Vue from 'vue'
import i18n from '../i18n/i18n'
import store from '../vuex/store'
export default {
  data() {
    return {
      mobileCode: "",
      emailCode: "",
      otpCode: "",
      assetsPassword: "",
      msmErrorMobileMsgOld: "",
      msmErrorEmailMsg: "",
      submitButtonDisabled: true,
      phoneSmsTime: 60,
      phoneSmsTimeShow: false,
      emailCodeTime: 60,
      emailCodeTimeShow: false,
      routerPath: ["fund_password", "rwd_assets", "editFundPwd"]
    }
  },
  created() {
    this.clearInput()
  },
  computed:{
    phoneMsmButton(){
      if(this.phoneSmsTime > 0 && this.phoneSmsTimeShow){
        return "("+(this.phoneSmsTime )+"S)";
      }else{
        return this.$t('click_to_get');
      }
    },

    emailCodeButton(){
      if(this.emailCodeTime > 0 && this.emailCodeTimeShow){
        return "("+(this.emailCodeTime )+"S)";
      }else{
        return this.$t('click_to_get');
      }
    },
  },

  methods: {
    clearInput(){
      this.mobileCode = ""
      this.emailCode = ""
      this.otpCode = ""
      this.assetsPassword = ""
      this.emailCodeTime = 60
      this.emailCodeTimeShow = false
      Vue.setGlobalVariable({'email_code_validation': true});
      Vue.setGlobalVariable({'mobile_code_validation': true});
      Vue.setGlobalVariable({'assets_password_validation': true});
      Vue.setGlobalVariable({'otp_code_validation': true});
      Vue.setGlobalVariable({'operation_validation_show': false});
      clearInterval(this.timer2)
    },

    closeOperationValidation(){
      this.clearInput()
    },
    submitOperationValidation(){
      action = "operation_validation"
      if(this.routerPath.includes(this.$route.path.split("/")[1])){
        action = 'account_operation_validation'
      }
      
      this.postUrl('/www/user_center/' + action, {
        mobile_code: this.mobileCode,
        email_code: this.emailCode,
        assets_password: this.assetsPassword,
        otp_code: this.otpCode,
        operation_type: this.currentOperationType
      }, true).then((response) => {
        data = response.data
        if(data.result.includes(false)){
          Vue.setGlobalVariable({'mobile_code_validation': data.result[0]});
          Vue.setGlobalVariable({'email_code_validation': data.result[1]});
          Vue.setGlobalVariable({'assets_password_validation': data.result[2]});
          Vue.setGlobalVariable({'otp_code_validation': data.result[3]});
          Vue.setGlobalVariable({'input_assets_password_times': data.result[4]});
          Vue.setGlobalVariable({'operation_validation_show': true});
          return
        }
        Vue.setGlobalVariable({'operation_validation_show': false});
        if(this.currentOperationType == "unbindMobile"){
          // 解绑手机
          this.submitBindPhoneUnbind();
        }else if(this.currentOperationType == "bindMobile"){
          // 修改绑定手机
          this.submitBindPhone();
        }else if(this.currentOperationType == "unbindEmail"){
          // 解绑邮箱
          this.submitBindEmailUnbind();
        }else if(this.currentOperationType == "bindEmail"){
          // 修改绑定邮箱
          this.submitBindEmail();
        }else if(this.currentOperationType == "editLoginPassword"){
          // 修改密码
          this.editLoginPwd();
        }else if(this.currentOperationType == "editFundPwd"){
          // 修改资金密码
          this.editFundPwd();
        }else if(this.currentOperationType == "unbund_google_auth"){
          this.submitGoogleAuthUnBind()
        }else if(this.currentOperationType == "bund_google_auth"){
          this.submitSysTemGoogleAuthBind()
        }else if(this.currentOperationType == "rwd_assets"){
          this.submitTransfer()
        }else if(this.currentOperationType == "Withdraw"){
          this.submitWithDraw()
        }else if(this.currentOperationType == "unlockFundPwd"){
          this.thawing_assets_password()
        }
        this.clearInput()
      }, (error) => {
        reject(error)
      })
    },

    closeOperationValidation(){
      Vue.setGlobalVariable({'operation_validation_show': false});
      this.clearInput()
    },

    phoneMsmSendOld() {
      //发送原手机验证码
       this.postUrl('/www/user_center/send_bind_old_phone_code', {
          lang:this.currentLang,
          operation_type: this.currentOperationType
       }).then((response) => {
        if(response.data.code == 20000){
          //验证码发送成功
          this.phoneSmsTimeShow = true;
          this.timer1Old = setInterval( () => {
            if(this.phoneSmsTime > 1){
               this.phoneSmsTime -= 1;
            }else{
              //重置
              clearInterval(this.timer1Old)
              this.phoneSmsTime = 60;
              this.phoneSmsTimeShow = false;
            }
  　　　　  }, 1000);
        }else{
          this.phoneSmsTimeShow = false;
        }
     });
  　},

    emailMsmSendOldbind() {
      this.emailMsmIsAbleUnbind = true;
       //发送邮箱验证码
       this.postUrl('/www/user_center/send_bind_old_email_code', {
          lang:this.currentLang,
          operation_type: this.currentOperationType
       }).then((response) => {
          if(response.data.code == 20000){
            //验证码发送成功
            this.emailCodeTimeShow = true;
            this.timer2 = setInterval( () => {
              if(this.emailCodeTime > 1){
                 this.emailCodeTimeShow = true;
                 this.emailCodeTime -= 1;
             }else{
                 //重置
                 this.emailCodeTime = 60;
                 this.emailCodeTimeShow = false;
                 clearInterval(this.timer2)
             }
    　　　　}, 1000);
          }else{
            this.emailCodeTimeShow = false;
          }
       });
　　 },

    submitButton(){
      if((this.currentIsMobileBound && this.mobileCode == "") || (this.currentIsEmailBound && this.emailCode == "") || (this.currentIsOtpBinded && this.otpCode == "") || (this.currentIsAssetsPasswordBound && this.assetsPassword == "")){
        this.submitButtonDisabled = true
      }else{
        this.submitButtonDisabled = false
      }
    },

    submitBindPhone(){
      this.postUrl('/www/user_center/bind_mobile', {
          lang:this.currentLang,
          mobile: this.currentNewPhone,
          mobile_code: this.mobileCode,
          email_code: this.emailCode,
          otp_code: this.otpCode,
          operation_type: this.currentOperationType
       }).then((response) => {
          if(response.data.code == 20000){
            //重新加载用户数据
            this.getMemberInfo();
            this.$router.push({path:'/user_center'}).catch(data => {  })
          }
       });
    },

    submitBindPhoneUnbind(){
      this.postUrl('/www/user_center/unbind_mobile', {
          lang:this.currentLang,
          mobile_code: this.mobileCode,
          email_code: this.emailCode,
          otp_code: this.otpCode,
          assets_password: this.assetsPassword,
          operation_type: this.currentOperationType
       }).then((response) => {
          if(response.data.code == 20000){
            //重新加载用户数据
            this.getMemberInfo();
            this.$router.push({path:'/user_center'}).catch(data => {  })
          }
       });
    },

    submitBindEmail: function () {
      this.postUrl('/www/user_center/bind_email', {
          lang:this.currentLang,
          email: this.currentNewEmail,
          mobile_code: this.mobileCode,
          email_code: this.emailCode,
          otp_code: this.otpCode,
          assets_password: this.assetsPassword,
          operation_type: this.currentOperationType
       }).then((response) => {
          if(response.data.code == 20000){
            //重新加载用户数据
            this.getMemberInfo();
            this.$router.push({path:'/user_center'}).catch(data => {  })
          }
       });
    },

    submitBindEmailUnbind() {
      this.postUrl('/www/user_center/unbind_email', {
          lang:this.currentLang,
          mobile_code: this.mobileCode,
          email_code: this.emailCode,
          otp_code: this.otpCode,
          assets_password: this.assetsPassword,
          operation_type: this.currentOperationType
       }).then((response) => {
          if(response.data.code == 20000){
            //重新加载用户数据
            this.getMemberInfo();
            this.$router.push({path:'/user_center'}).catch(data => {  })
          }
       });
    },

    editLoginPwd(){
      this.postUrl('/www/user_center/edit_login_password', {
          lang:this.currentLang,
          new_password: this.currentnewPwd,
          new_password_repeat: this.currentnewPwdRepeat,
          mobile_code: this.mobileCode,
          email_code: this.emailCode,
          otp_code: this.otpCode,
          assets_password: this.assetsPassword,
          operation_type: this.currentOperationType
       }).then((response) => {
          if(response.data.code == 20000){
            //重新加载用户数据
            this.getMemberInfo();
            this.$router.push({path:'/user_center'}).catch(data => {  })
          }
       });
    },

    editFundPwd(){
      // if(this.is_bind_phone){
      //   type = 'mobile';
      // }else{
      //   type = 'email';
      // }
      this.postUrl('/www/user_center/edit_asset_password', {
          lang:this.currentLang,
          // code: this.msm,
          // type: type,
          old_fund_password: this.assetsPassword,
          new_password: this.currentnewFundPassword,
          new_password_repeat: this.currentnewFundPasswordRepeat,
          mobile_code: this.mobileCode,
          email_code: this.emailCode,
          otp_code: this.otpCode,
          assets_password: this.assetsPassword,
          operation_type: this.currentOperationType
       }).then((response) => {
          if(response.data.code == 20000){
            //重新加载用户数据
            this.getMemberInfo();
            this.$router.push({path:'/user_center'}).catch(data => {  })
          }
       });
    },

    submitGoogleAuthUnBind: function () {
      this.postUrl('/www/user_center/disable_google_auth', {
          lang:this.currentLang,
          mobile_code: this.mobileCode,
          email_code: this.emailCode,
          otp_code: this.otpCode,
          operation_type: this.currentOperationType
      }).then((response) => {
          if(response.data.code == 20000){
            this.getMemberInfo();
            this.$router.push({path:'/user_center'}).catch(data => {  })
          }
      });
    },

    submitSysTemGoogleAuthBind(){
      this.postUrl('/www/user_center/open_google_auth', {
          lang:this.currentLang,
          mobile_code: this.mobileCode,
          email_code: this.emailCode,
          otp_code: this.otpCode,
          operation_type: this.currentOperationType
      }).then((response) => {
          if(response.data.code == 20000){
            this.getMemberInfo();
            this.$router.push({path:'/user_center'}).catch(data => {  })
          }
      });
    },

    submitTransfer(){
      this.postUrl('/www/assets/transfer', {
        lang: this.currentLang,
        code: this.$route.params.code,
        amount: this.currentTransferAmount,
        to_member_email: this.currentEmailAddressOfTheTransferredUser,
        mobile_code: this.mobileCode,
        email_code: this.emailCode,
        otp_code: this.otpCode,
        assets_password: this.assetsPassword,
        operation_type: this.currentOperationType
      }).then((response) => {
        if(response.data.code == 20000){
          // this.transfer_amount = '';
          // this.emailAddressOfTheTransferredUser = '';
          // this.fund_pwd = '';
          // this.actual_arrival = '--';
          this.$emit('reload-data');
        }
      });
    },

    submitWithDraw() {
      this.postUrl('/www/assets/withdrawal', {
        lang: this.currentLang,
        code: this.$route.params.code,
        amount: this.currentWithdrawalAmount,
        to_address: this.currentWithdrawToAddress,
        description: this.currentDescription,
        mobile_code: this.mobileCode,
        email_code: this.emailCode,
        otp_code: this.otpCode,
        assets_password: this.assetsPassword,
        operation_type: this.currentOperationType
      }).then((response) => {
      }, (error) => {
      });
    },

    thawing_assets_password(){
      this.postUrl('/www/assets/thawing_assets_password', {
        lang: this.currentLang,
        mobile_code: this.mobileCode,
        email_code: this.emailCode,
        otp_code: this.otpCode,
        operation_type: this.currentOperationType
      }).then((response) => {
        this.getMemberInfo()
      }, (error) => {
      });
    }

  },
}
</script>