<template>
	<div class="pages-assets-balances-navigation-tabs" style="margin-top: 10px">
	    <ul class="pages-assets-balances-navigation-tab" style="width: auto">
	        <li @click="$emit('change-type', 'recharge')" :class="['recharge' === faceValue ? 'pages-assets-balances-navigation-active' : null ]" v-if="currency.is_depositable">{{$t('recharge')}}</li>
          <li @click="$emit('change-type', 'withdrawal')" :class="['withdrawal' === faceValue ? 'pages-assets-balances-navigation-active' : null ]" v-if="currency.is_withdrawable">{{$t('withdrawal')}}</li>
          <li @click="$emit('change-type', 'transfer')" :class="['transfer' === faceValue ? 'pages-assets-balances-navigation-active' : null ]" v-if="currency.is_transfered">{{$t('transfer_within_the_tation')}}</li>
	    </ul>
	</div>
</template>
<script type="text/javascript">
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    faceValue: String,
    currency: Object
  },
  data() {   
     return {

     }
  },
  computed: {
  }
}
</script>