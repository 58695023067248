<template>
<div class="pages-ucenter-commonform-wrapper">
  <edit-password-title :language = "language"/>
  <!-- <edit-password-header-text :language = "language"/> -->
  <edit-password-content :language = "language" />
  <operation-validation></operation-validation>
</div>
</template>
<script>
import EditPasswordTitle from '../FundPassword/EditPasswordTitle'
import EditPasswordContent from '../FundPassword/EditPasswordContent'
import EditPasswordHeaderText from '../FundPassword/EditPasswordHeaderText'
import OperationValidation from '../OperationValidation'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)

  },
  data() {
     return {
        
     }
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  mounted() {
    
  },
  methods: {

  },
  components: {
    EditPasswordTitle,
    EditPasswordContent,
    EditPasswordHeaderText,
    OperationValidation
  }
}
</script>
