//en.js
import enLocale from 'element-ui/lib/locale/lang/en'
import enJson from '../locales/en.json'
console.info(enJson["en"])
const en = {
  ...enJson["en"],
  ...enLocale
}

export default en
