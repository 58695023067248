<template>
  <div class="pages-assets-balances-navigation-container isShow"   v-show="faceValue == 'transfer'">
    <div class="ant-row-flex" style="height: auto;margin-left: -10px; margin-right: -10px;border-radius: 4px;margin: 0 auto;width: var(--page-content-width);background-color: var(--assets-new-box-bg);">
      <div class="assets_balc_navig_ctn_transfer_title">
        {{$t('what_you_currently_have')}}{{currency.code}}
      </div>
      <div class="assets_balc_navig_ctn_transfer_content">
        <div class="assets_balc_navig_ctn_transfer_insert">
          <div class="assets_balc_navig_ctn_transfer_quota">
            <div class="assets_balc_navig_ctn_transfer_top assets_balc_navig_ctn_transfer_label" style="margin-top: 20px;">
              <div class="assets_balc_navig_ctn_transfer_label_text" style="font-size:16px;">{{$t('available_balance')}}</div>
              <div class="assets_balc_navig_ctn_transfer_label_value" style="font-size: 16px">{{available_balance}} {{currency.code}}</div>
            </div>
            <div class="assets_balc_navig_ctn_transfer_bottom">
              <div class="assets_balc_navig_ctn_transfer_right assets_balc_navig_ctn_transfer_label">
                <div class="assets_balc_navig_ctn_transfer_label_text" style="font-size:16px;">{{$t('frozen_amount')}}</div>
                <div class="assets_balc_navig_ctn_transfer_label_value" style="font-size: 16px">{{locked_balance}} {{currency.code}}</div>
              </div>
            </div>
          </div>
          <form style="margin-top: 30px;margin-left: 20px">
            <div class="ant-col ant-form-item-label">
              <label for="newPassword" class="" style="color: var(--assets-label-color);" :title="$t('number_of_transfers')">{{$t("number_of_transfers")}}</label>
            </div>
            <div class="pages-assets-balances-withdraw-addressWrap">
              <div class="pages-assets-balances-withdraw-address">
                <div class="">
                  <div class="">
                    <div class="">
                      <div style="background-color: var(--bind-phone-phone-bgcolor);overflow: hidden;width: 655px;height: auto;border-radius: 6px;border: solid 1px var(--bind-phone-phone-bodercolor);line-height: 32px;padding-left: 9px;outline:none;outline: none;display: flex;justify-content: space-between;">
                        <input type="text" name="" rows="1" placeholder="e.g.100.1234" style="overflow: hidden;width: 545px;height: 50px;background-color: var(--bind-phone-phone-bgcolor);border-radius: 6px;border: solid 0px var(--bind-phone-phone-bodercolor);line-height: 32px;outline:none;color: var(--main-color);" v-model="transfer_amount" @keyup="inputActualArrival"></input>
                        <div style="width: 100px;text-align: right;margin-right: 15px;color: #006cdb;cursor:pointer;line-height: 50px;"  @click="chooseAll">{{$t('extract_all')}}</div>
                      </div>
                    </div>
                    <div class="ant-form-explain" style="color: #df4e4e;" v-if="isShowAccountError">{{accountErrorMsg}}</div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="ant-row ant-form-item" :class="[isShowErrorEmailAddressOfTheTransferredUserClass ? 'ant-form-item-with-help' : null ]">
              <div class="ant-col ant-form-item-label">
                <label for="newPassword" class="" style="color: var(--assets-label-color);" :title="$t('email_address_of_the_transferred_user')">{{$t("email_address_of_the_transferred_user")}}</label>
              </div>
              <div class="ant-col ant-form-item-control-wrapper">
                <div class="ant-form-item-control"  :class="[isShowErrorEmailAddressOfTheTransferredUserClass ? 'has-error' : null ]">
                  <span class="ant-form-item-children">
                    <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                      <span class="ant-input-wrapper ant-input-group transfer_input">
                        <input type="text" id="emailAddressOfTheTransferredUser" class="ant-input ant-input-lg" :placeholder="$t('email_address_of_the_transferred_user')" style="margin-left: 0px;overflow: hidden;width: 655px;height: 50px;line-height: 32px;padding-left: 9px;outline:none;color: var(--main-color);" v-model="emailAddressOfTheTransferredUser"  maxlength="50">
                      </span>
                    </span>
                  </span>
                  <div class="ant-form-explain" v-if="isShowErrorEmailAddressOfTheTransferredUserDiv">{{emailAddressOfTheTransferredUserErrorMsg}}</div>
                </div>
              </div>
            </div>
            <br>
            <div class="pages-assets-balances-withdraw-totalFee" style="width: 87%;margin-top: 5px;">
              <div>{{$t("handling_fee")}}：{{calculationFee(currency.internal_transfer_fee, transfer_amount)}}</div>
            </div>
            <br>
            <div class="ant-col ant-form-item-label">
              <label for="newPassword" class="" style="color: var(--assets-label-color);" :title="$t('actual_arrival')">{{$t("actual_arrival")}}</label>
            </div>
            <div class="pages-assets-balances-withdraw-addressWrap">
              <div class="pages-assets-balances-withdraw-address">
                <div class="">
                  <div class="transfer_input">
                    <input type="text" name="" disabled="disabled" v-model="actual_arrival" rows="1" style="overflow: hidden;width: 655px;height: 50px;background-color: var(--bind-phone-phone-bgcolor);border-radius: 6px;border: solid 1px var(--bind-phone-phone-bodercolor);line-height: 32px;padding-left: 9px;outline:none;color: var(--main-color);"></input>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="pages-ucenter-commonform-handle"  style="margin-top: 40px">
              <button type="button" @click="submitTransfer"  :disabled="submitIsAbleUnbind"  class="ant-btn ant-btn-primary"  style="width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, rgba(135, 106, 255, 1), rgba(91, 195, 255, 1));opacity:1;font-weight: normal;"  :title="$t('confirm_the_transfer')" >
                <span>{{$t("confirm_the_transfer")}}</span>
              </button>
            </div>
          </form>
          <div class="assets_balc_navig_ctn_transfer_list">
            <div class="assets_balc_navig_ctn_transfer_where">
              <div class="block">
                <el-date-picker
                   v-model="seach_date"
                   type="datetimerange"
                   :picker-options="pickerOptions"
                   :range-separator="$t('to')"
                   :start-placeholder="$t('start_date')"
                   :end-placeholder="$t('end_date')"
                   align="right" style="background-color: var(--bind-phone-phone-bgcolor);">
                </el-date-picker>
              </div>
              <div class="transfer_input">
                <input type="text" v-model="transfer_initiator" name="" rows="1" :placeholder="$t('transfer_initiator')" style="overflow: hidden;width: 210px; height: 40px;background-color: var(--bind-phone-phone-bgcolor);border-radius: 6px;border: solid 1px var(--bind-phone-phone-bodercolor);line-height: 32px;padding-left: 9px;outline:none;color: var(--main-color);"></input>
              </div>
              <div class="transfer_input">
                <input type="text" v-model="transferred_user" name="" rows="1" :placeholder="$t('transferred_user')" style="overflow: hidden;width: 210px; height: 40px;background-color: var(--bind-phone-phone-bgcolor);border-radius: 6px;border: solid 1px var(--bind-phone-phone-bodercolor);line-height: 32px;padding-left: 9px;outline:none;color: var(--main-color);"></input>
              </div>
              <div>
                <button class="" tabindex="-1" type="button"  style="border: 0px;cursor: pointer;width: 92px;margin-top:5px;height: 32px;background-color: #006cdb;border-radius: 16px;color: #ffffff;" @click="$emit('check-transfer-data',seach_date,transfer_initiator,transferred_user)">
                  <span class="MuiButton-label">{{$t("search")}}</span>
                </button>
              </div>
            </div>
            
            <div class="assets_balc_navig_ctn_transfer_list_content">
              <div class="pages-ucenter-commonform-handle"  style="margin-top: 40px">
                <span style="display: inline-block"><h3>{{$t('transfer_records')}}</h3></span>
                <span style="display: inline-block; float: right;"><h3>
                <a @click="list()">{{$t('see_more')}}</a></h3></span>
              </div>
              <div class="assets_balc_navig_ctn_transfer_history_table_row">
                <span style="text-align: left;min-width: 14%">
                  {{$t('the_starting_time')}}
                </span>
                <span style="min-width: 12%;">
                  {{$t('number_of_transfers')}}
                </span>
                <span style="min-width: 8%;">
                  Fee
                </span>
                <span style="min-width: 14%;">
                  {{$t('actual_arrival')}}
                </span>
                <span style="min-width: 25%;">
                  {{$t('transfer_initiator')}}
                </span>
                <span style="min-width: 25%;">
                  {{$t('transferred_user')}}
                </span>
              </div>
              <div class="assets_balc_navig_ctn_transfer_history_table_row" v-for = "item in internalTransfers">
                <span style="text-align: left;min-width: 14%">
                  {{item.created_at}}
                </span>
                <span style="min-width: 12%;">
                  {{item.total_amount}}
                </span>
                <span style="min-width: 8%;">
                  {{item.fee}}
                </span>
                <span style="min-width: 14%;">
                  {{item.actual_amount}}
                </span>
                <span style="min-width: 25%;">
                  {{item.from_member_email}}
                </span>
                <span style="min-width: 25%;">
                  {{item.to_member_email}}
                </span>
              </div>
            </div>
            <br><br>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<style type="text/css" >
.transfer_input input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: var(--assets-label-color);
  font-size: 14px;
}
.el-range-input{
  background-color: var(--bind-phone-phone-bgcolor);
  border:1px solid var(--bind-phone-phone-bgcolor);
}
.el-date-editor--datetimerange{
  background-color: var(--bind-phone-phone-bgcolor);
  border:1px solid var(--bind-phone-phone-bgcolor);
}
.assets_balc_navig_ctn_transfer_list_content{
  width: 1120px;
  margin-left: 36px;
  margin-top: 30px;
  color: var(--main-color);
  font-size: 12px;
}
.assets_balc_navig_ctn_transfer_history_table_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  font-size: 12px;
}
.assets_balc_navig_ctn_transfer_history_table_row span:first-child {
  width: 15%;
}
.assets_balc_navig_ctn_transfer_history_table_row span:nth-child(n) {
  width: 20%;
}
.assets_balc_navig_ctn_transfer_history_table_row span:last-child {
  width: 25%;
  text-align: right;
}
.assets_balc_navig_ctn_transfer_where{
  display: flex;
  justify-content:space-around;
  width:var(--page-content-width)
}
.assets_balc_navig_ctn_transfer_list{
  margin-top: 36px;
  width:var(--page-content-width)
}
.assets_balc_navig_ctn_transfer_label_text{
  color: var(--assets-label-color);
}
.assets_balc_navig_ctn_transfer_label_value{
  color: var(--main-color);
  font-size: 18px;
}
.assets_balc_navig_ctn_transfer_label{
  margin-top: 16px;
  margin-left: 21px;
  display: flex;
  flex-direction:column;
}
.assets_balc_navig_ctn_transfer_title{
  font-size: 18px;
  height: 71px;
  line-height: 70px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid var(--assets-transfer-title-boderbuttum-color);
  color: var(--main-color);
}
.assets_balc_navig_ctn_transfer_bottom{
  display: flex;
  justify-content: space-between;
  width: 390px;
}
.assets_balc_navig_ctn_transfer_quota{
  display: flex;
  flex-direction:column;
}
.assets_balc_navig_ctn_transfer_bginput span{
  background-color: var(--bind-phone-phone-bgcolor);
}
</style>
<script type="text/javascript">
import
Vue from 'vue'
import {Decimal} from 'decimal.js';
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    faceValue: String,
    language: String,
    is_have_payment_address: Boolean,
    paymentAddress: Object,
    currency: Object,
    internalTransfers: Array,
    available_balance: String,
    locked_balance: String
  },
  data() {
    return {
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      seach_date: '',
      transfer_amount: '',
      actual_arrival: '--',
      isShowAccountError: false,
      accountErrorMsg: '',
      isShowErrorEmailAddressOfTheTransferredUserClass: false,
      isShowErrorEmailAddressOfTheTransferredUserDiv: false,
      emailAddressOfTheTransferredUserErrorMsg: '',
      emailAddressOfTheTransferredUser: '',
      isShowErrorFundPwdClass: false,
      isShowErrorFundPwdDiv: false,
      fundPwdErrorMsg: '',
      fund_pwd: '',
      transfer_initiator: '',
      transferred_user: ''
    }
  },
  computed: {
    submitIsAbleUnbind: function(){
      if(!this.isShowErrorEmailAddressOfTheTransferredUserClass && !this.isShowErrorEmailAddressOfTheTransferredUserDiv && !this.isShowErrorFundPwdClass && !this.isShowErrorFundPwdDiv && !this.isShowAccountError  && this.transfer_amount != '' && this.emailAddressOfTheTransferredUser != ''){
        return false;
      }else{
        return true;
      }
    },
    pickerOptions: function() {
      return {
        cellClassName(time){
          return 'bginput';
        },
        shortcuts: [{
          text: this.$t('last_week'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('last_month'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$t('last_three_months'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  methods: {
    list(){
      window.open('/transfer_list/' + this.$route.params.code);
    },
    calculationFee: function(fee, count){
      return (Number(fee)*Number(count)).toFixed(this.currency.internal_transfer_scale)
    },
    submitTransfer: function(){
      if(!this.currentUserInfo.is_bind_assets_password){
        this.$message({
          message: this.$t('you_have_not_set_a_fund_password'),
          type: 'error'
        })
        return
      }
      if(!this.currentUserInfo.is_able_to_transfer){
        this.$message({
          message: this.$t('is_not_allowed'),
          type: 'error'
        })
        return
      }
      this.postUrl('/www/user_center/reset_asset_password_locked', {
        lang: this.currentLang
      }).then((response) => {
        if(response.data.code == 20000){
          this.OperationValidation('rwd_assets')
          Vue.setGlobalVariable({
            'transfer_amount': this.transfer_amount
          });
          Vue.setGlobalVariable({
            'email_address_of_the_transferred_user': this.emailAddressOfTheTransferredUser
          });
        }
      });
    },
    inputFundPwd: function(){
      this.fund_pwd = this.fund_pwd.replace(/[\u4e00-\u9fa5]/ig,'');
      let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,32}$/;
      if (this.fund_pwd == '') {
        //不合法 不可为空
        this.fundPwdErrorMsg = this.$t('please_enter_your_password');
        this.isShowErrorFundPwdClass = true;
        this.isShowErrorFundPwdDiv = true;
      }else if(this.fund_pwd.length < 8 || this.fund_pwd.length > 32){
        //不合法 格式错误
        this.fundPwdErrorMsg = this.$t('please_enter_an_8_32_password');
        this.isShowErrorFundPwdClass = true;
        this.isShowErrorFundPwdDiv = true;
      }else if(!reg.test(this.fund_pwd)){
        this.fundPwdErrorMsg = this.$t('password_must_contain_letters_and_numbers');
        this.isShowErrorFundPwdClass  = true;
        this.isShowErrorFundPwdDiv = true;
      }else{
        //正确
        this.isShowErrorFundPwdClass = false;
        this.isShowErrorFundPwdDiv = false;
      }
    },
    // inputEmailAddressOfTheTransferredUser: function(){
    //   this.emailAddressOfTheTransferredUser = this.emailAddressOfTheTransferredUser.replace(/[\u4e00-\u9fa5]/ig,'');
    //   let reg = /^([a-zA-Z0-9_\.\@\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    //   if (this.emailAddressOfTheTransferredUser == "") {
    //     //不合法 不可为空
    //     this.emailAddressOfTheTransferredUserErrorMsg = this.$t("please_input_your_email");
    //     this.isShowErrorEmailAddressOfTheTransferredUserClass = true;
    //     this.isShowErrorEmailAddressOfTheTransferredUserDiv = true;
    //   } else if (this.emailAddressOfTheTransferredUser.length <= 10 || !reg.test(this.emailAddressOfTheTransferredUser)) {
    //     //不合法 格式错误
    //     this.emailAddressOfTheTransferredUserErrorMsg = this.$t("email_address_format_is_incorrect");
    //     this.isShowErrorEmailAddressOfTheTransferredUserClass = true;
    //     this.isShowErrorEmailAddressOfTheTransferredUserDiv = true;
    //   } else {
    //     //正确
    //     this.isShowErrorEmailAddressOfTheTransferredUserClass = false;
    //     this.isShowErrorEmailAddressOfTheTransferredUserDiv = false;
    //   }
    // },
    chooseAll: function(){
      if(new Decimal(this.available_balance).lte(new Decimal(0))){
        this.$message.closeAll();
        this.$message({
          message: this.$t('insufficient_balance'),
          type: 'warning'
        })
        return false;
      }else{
        this.transfer_amount = this.available_balance;
        this.inputActualArrival();
      }
    },
    inputActualArrival: function(){
      this.transfer_amount = this.transfer_amount.replace(/[^0-9.]/g,'');
      if(this.transfer_amount != ''){
        if(this.transfer_amount.substring(0,1) == '0' && this.transfer_amount.substring(1,2) != '' && this.transfer_amount.substring(1,2) != '.' ){
          this.transfer_amount = this.transfer_amount.substr(1);
        }
        if(this.transfer_amount == '.'){
          this.transfer_amount = "0."
        }
        transfer_amount_number = 0
        transfer_amount_number = Number(this.transfer_amount.split(".")[0]);
        if(this.transfer_amount.split(".")[1]){
          if(this.transfer_amount.split(".")[1].length > this.currency.internal_transfer_scale){
            this.transfer_amount = String(transfer_amount_number) + '.' +this.transfer_amount.split(".")[1].slice(0,this.transfer_amount.split(".")[1].length-1)
          }
        }
        if((this.transfer_amount.split('.')).length-1 > 1){
          //去除多余的小数点
          this.transfer_amount = String(transfer_amount_number) + '.' +this.transfer_amount.split(".")[1].slice(0,this.transfer_amount.split(".")[1].length-1)
        }
        if(new Decimal(this.transfer_amount).gt(new Decimal(this.available_balance))){
          this.transfer_amount =  this.available_balance;
        }
        // 手续费
        internal_transfer_fee = new Decimal(this.transfer_amount).mul(new Decimal(this.currency.internal_transfer_fee))
        if(new Decimal(this.transfer_amount).lte(new Decimal(internal_transfer_fee))){
          this.$message.closeAll();
          this.$message({
            message: this.$t('the_transfer_amount_must_be_greater_than_the_handling_fee'),
            type: 'warning'
          })
          this.isShowAccountError = true;
          this.accountErrorMsg = this.$t('the_transfer_amount_must_be_greater_than_the_handling_fee')
          this.actual_arrival = '--';
          return false;
        }else{
          this.isShowAccountError = false;
          this.accountErrorMsg = '';
          this.$message.closeAll();
          this.actual_arrival = new Decimal(this.transfer_amount).sub(new Decimal(internal_transfer_fee))
        }
      }else{
        this.isShowAccountError = true;
        this.accountErrorMsg = this.$t('please_enter_the_amount_of_transfer')
        this.$message.closeAll();
        this.actual_arrival = '--';
        return false;
      }
      if(this.transfer_amount <= 0){
        this.isShowAccountError = true;
        this.accountErrorMsg = this.$t('please_enter_the_amount_of_transfer')
        this.actual_arrival = '--';
        return false;
      }
    }
  }
}
</script>
