<template>
	<div style="background:#FAFCFE;">
		<div class="footer_home" :style="{ 'margin-top': (this.$route.path == '/' ? '' : '100px') }">
			<div class="box_left">
				<div class="logo_img">
					<img :src="logo" height="46">
				</div>
				<div class="footer_text">
					Copyright © 2024 {{ website_name }} All Rights Reserved
				</div>
        <!--
				<div class="trand">
					<a href="https://twitter.com/" rel="noopener noreferrer" target="_blank" style="color: #47426e;"><i
							class="iconfont icon-twitter" style="font-size: 22px"></i></a>
					<a href="https://t.me/" rel="noopener noreferrer" target="_blank" style="color: #47426e;"><i
							class="iconfont icon-TelegramEn" style="font-size: 19px"></i></a>
					<a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank"
						style="color: #47426e;"><i class="iconfont icon-facebookfacebook52" style="font-size: 19px"></i>
					</a>
				</div>
        -->
        <div class="trand">
          <a href="https://twitter.com/" rel="noopener noreferrer" target="_blank" style="color: #47426e;">
            <img src="https://cdn.gda.top/files/other/17302654394a7.png" alt="Twitter" style="width: 40px; height: 40px;">
          </a>
          <a href="https://t.me/" rel="noopener noreferrer" target="_blank" style="color: #47426e;">
            <img src="https://cdn.gda.top/files/other/17302654462b6.png" alt="Telegram" style="width: 40px; height: 40px;">
          </a>
          <a href="https://www.facebook.com/" rel="noopener noreferrer" target="_blank" style="color: #47426e;">
            <img src="https://cdn.gda.top/files/other/1730265554235.png" alt="Facebook" style="width: 40px; height: 40px;">
          </a>
        </div>
			</div>
			<div class="box_right">
				<div class="on" style="margin-right: 20px; width: 150px">
          <div style="width: 122px; height: 40px; font-weight: 600; font-size: 28px; color: #1F1F1F; line-height: 40px; text-align: left; margin-bottom: 34px">{{ $t('on') }}GDA</div>
					<div style="margin-bottom: 14px;"><a :href="about_coiex" target="_blank" class="footer_right_small_text">{{ $t('about') }}{{ currentHomeText }}</a></div>
					<div style="margin-bottom: 14px;"><a :href="vue_cms_url" target="_blank" class="footer_right_small_text">{{ $t('announcement_center') }}</a></div>
					<div style="margin-bottom: 14px;"><a :href="contact_us" target="_blank" class="footer_right_small_text">{{ $t('contact_us') }}</a></div>
					<div><a :href="job_career" target="_blank" class="footer_right_small_text">{{ $t('career_opportunities') }}</a></div>
				</div>
				<div class="on" style="margin-right: 20px; width: 150px">
          <div style="width: 122px; height: 40px; font-weight: 600; font-size: 28px; color: #1F1F1F; line-height: 40px; text-align: left; margin-bottom: 34px">{{ $t('service') }}</div>
					<div style="margin-bottom: 14px;"><a :href="new_exchange" target="_blank" class="footer_right_small_text">{{ $t('apply_for_listing') }}</a></div>
					<div style="margin-bottom: 14px;"><a href="javascript:;" @click="go_digital_assets()" class="footer_right_small_text">{{ $t('asset_introduction') }}</a></div>
					<div><a href="javascript:;" @click="go_currency_fee()" class="footer_right_small_text">{{ $t('rate_standard') }}</a></div>
					<!-- <div><a href="javascript:;" >{{$t('api_documentation')}}</a></div> -->
				</div>
				<div class="terms" style="margin-right: 20px; width: 150px;">
          <div style="width: 122px; height: 40px; font-weight: 600; font-size: 28px; color: #1F1F1F; line-height: 40px; text-align: left; margin-bottom: 34px">{{ $t('terms') }}</div>
					<div style="margin-bottom: 14px;"><a :href="privacy_policy" target="_blank" class="footer_right_small_text">{{ $t('privacy_policy') }}</a></div>
					<div style="margin-bottom: 14px;"><a :href="legal_anouncement" target="_blank" class="footer_right_small_text">{{ $t('legal_notices') }}</a></div>
					<div style="margin-bottom: 14px;"><a :href="prevent_fishing" target="_blank" class="footer_right_small_text">{{ $t('prevent_phishing_attacks') }}</a></div>
					<div><a :href="prevent_virus" target="_blank" class="footer_right_small_text">{{ $t('prevent_virus_trojans') }}</a></div>
				</div>
				<div class="help" style="margin-right: 20px; width: 150px;">
          <div style="width: 122px; height: 40px; font-weight: 600; font-size: 28px; color: #1F1F1F; line-height: 40px; text-align: left; margin-bottom: 34px">{{ $t('help') }}</div>
					<div style="margin-bottom: 14px;"><a :href="how_to_register" target="_blank" class="footer_right_small_text">{{ $t('how_to_register') }}</a></div>
					<div style="margin-bottom: 14px;"><a :href="retrieve_password" target="_blank" class="footer_right_small_text">{{ $t('retrieve_password') }}</a></div>
					<div style="margin-bottom: 14px;"><a :href="how_to_crypto_crypto_trade" target="_blank" class="footer_right_small_text">{{ $t('how_to_trade') }}</a></div>
					<div><a :href="how_to_deposit_crypto" target="_blank" class="footer_right_small_text">{{ $t('how_to_charge_coins') }}</a></div>
				</div>
			</div>
		</div>
	</div>
</template>
<style type="text/css">

/*.help{
		float: right;
		margin-left: 70px;
	}
	.terms{
		float: left;
		margin-left: 70px;
	}*/
.footer_home {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
  /*height: 475px;*/
}

.box_left {
  margin-left: auto;
	color: #ffffff;
  margin-right: auto
}

.box_right {
	color: #ffffff;
	display: flex;
	justify-content: space-between;
	margin-top: 55px;
	margin-right: 200px;
	width: 40%;
  word-wrap: break-word;
}

.logo_img {
	margin-top: 59px;
	margin-left: 68px;
}

.trand {
	margin-top: 52px;
	margin-left: 53px;
}

.trand a {
	margin-left: 15px;
}

.footer_text {
	margin-left: 65px;
  margin-top: 25px;
  width: 390px;
  color: #666666;
  line-height: 33px;
  word-wrap: break-word;
  font-size: 16px;
}

.footer_right_small_text {
  width: 99px;
  height: 33px;
  color: #666666;
  line-height: 33px;
  text-align: left;
  font-size: 16px;
}
</style>
<script type="text/javascript">
import Vue from 'vue'
import { black_logo, website_name } from '../application'
export default {
	name: 'Footer',
	props: {
		// 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
		language: String
	},
	data() {
		return {
			vue_cms_url: '',
			logo: black_logo,
			website_name: website_name,
			about_coiex: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/NzY=?lang=' + this.language,
			contact_us: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/MTE=?lang=' + this.language,
			job_career: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/MTY4?lang=' + this.language,
			new_exchange: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/MTM==?lang=' + this.language,
			privacy_policy: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/NjA=?lang=' + this.language,
			legal_anouncement: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/ODE=?lang=' + this.language,
			prevent_fishing: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/Ng==?lang=' + this.language,
			prevent_virus: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/Nw==?lang=' + this.language,
			how_to_register: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/Mg==?lang=' + this.language,
			retrieve_password: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/Mw==?lang=' + this.language,
			how_to_crypto_crypto_trade: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/NA==?lang=' + this.language,
			how_to_deposit_crypto: Vue.getValueByKey('vue_cms_path') + '/home_article_detail/NQ==?lang=' + this.language
		}
	},
	mounted() {
		this.vue_cms_url = Vue.getValueByKey('vue_cms_path') + '?lang=' + this.currentLang;
	},
	methods: {
		go_digital_assets() {
			this.$router.push({ path: '/asset_introduction' }).catch(data => { })
		},
		go_currency_fee() {
			this.$router.push({ path: '/currency_fee' }).catch(data => { })
		}
	},
}
</script>
