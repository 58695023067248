<template>
<div style="box-sizing: border-box;background-color: var(--my-assets-bgcolor);min-width: var(--page-content-width)">
     <page-uc-edit-password/>
     <nav-footer  :language = "language"/>
</div>
</template>
<style lang="scss">
@import url('../css/layouts.956413c0.chunk.scss');
@import url('../css/umi.0d36cc22.scss');
</style>
<script>
import PageUcEditPassword from '../components/UcEditPassword/PageUcEditPassword'
import NavFooter from '../components/NavFooter'
export default {
  created(){
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  components: {
    PageUcEditPassword,
    NavFooter
  }
}
</script>

