<template>
  <div id="app" :class="currentTheme + '_theme'"  :style="{'height': (this.$route.path=='/invite_qrcode' ? '100%':'')}">
  <meta name="referrer" content="no-referrer">
    <el-container :style="{'height': (this.$route.path=='/invite_qrcode' ? '100%':'')}">
      <el-header :style="{'height': (this.$route.path=='/' ? '0px':'auto')}">
        <nav-header v-if="this.$route.path != '/' && this.$route.path != '/invite_qrcode' && this.$route.path != '/activity'"/><!-- 不是首页加载 -->
        <home-nav-header v-if="this.$route.path == '/'  && this.$route.path != '/invite_qrcode' && this.$route.path != '/activity'"/><!-- 首页加载 -->
      </el-header>
      <router-view/>
      <!--
      <el-footer class="bottom_footer">
        <bottom-footer/>
      </el-footer>
      -->
    </el-container>
  </div>

</template>
<script>
import Vue from 'vue'
import NavHeader from './components/NavHeader'
import HomeNavHeader from './components/HomeNavHeader'
import BottomFooter from './components/Footer'
import store from './vuex/store'
import serviceStValidate from './mixins/service_st_validate'
import i18n from './i18n/i18n'
export default {
  mixins: [serviceStValidate],
  name: 'App',
  created(){
    this.initProject()
    this.judgeIsLoggedIn().then(()=>{
    })
    this.subscribePublicChannels()
  },
  watch: {
      //仅仅是app.vue做完判断的时候回调
      currentJudgeIsNotLoggedIn(newVal) {
        if (newVal) {
         if(this.$route.path !== '/' && this.$route.path !== '/asset_introduction' && this.$route.path !== '/sales_promotion' 
          && this.$route.path.split('/')[1] !== 'show_sales_promotion' && this.$route.path !== '/currency_fee'){
           this.clearSign();
           location.href = Vue.getValueByKey('cas_path')+'/sign_in?service=' + window.location.href.split('?')[0] + '&lang=' + this.currentLang;
         }
        }
      },
  },
  data() {
    return {
      currentRoute: ''
    }
  },
  components: {
    NavHeader,
    BottomFooter,
    HomeNavHeader
  }
}
</script>
<style lang="scss">
@import url('./application.scss');
@import url('./css/umi.scss');
.container {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}
@import url('./css/vendor.scss');
@import url('./css/font.scss');
@import url('./css/theme.scss');
@import url('./css/layout.scss');
#app{
  background-color: var(--app-bgcolor);
}
.el-header {
  padding: 0;
}
.bottom_footer {
  height: 350px !important;
  background-color: #1e194a;
}
* {
  margin: 0;
  padding: 0;
}
a{
  color: #0397e3;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
li.el-select-dropdown__item {
  font-size: 12px;
}
</style>
