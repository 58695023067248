<template>
<div   @click="hideAlert">
  <div class="pages-ucenter-commonform-wrapper">
    <api-management-title :language = "language"/>
    <api-management-content :language = "language" :isHideApiType = "isHideApiType" :apiType = "apiType" :changedApiType = "changedApiType"  @check-api-type="checkApiType" @show-api-type-select="showApiTypeSelect"/>
  </div>
</div>
</template>
<style type="text/css">

</style>
<script>
import ApiManagementTitle from '../UcApiManagement/ApiManagementTitle'
import ApiManagementContent from '../UcApiManagement/ApiManagementContent'
export default {

  data() {
     return {
        isHideApiType:true,
        apiType:'',
     }
  },
  computed: {
    changedApiType: function () {
        if(this.apiType == ''){
            return this.$t('spot');
        }else{
            return this.apiType;
        }
    },
    language: function () {
      return this.currentLang;
    }
  },
  methods: {
    hideAlert(){
      if(this.isHideApiType == false){
        this.isHideApiType = true;
      }
    },
    checkApiType(item){
        this.apiType = item.name;
        this.isHideApiType = true;
    },
    showApiTypeSelect(){
        this.isHideApiType = !this.isHideApiType;
    },
  },
  mounted() {
    
  },
  components: {
   ApiManagementTitle,
   ApiManagementContent
  }
}
</script>