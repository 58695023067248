<template>
  <div class="pages-assets-balances-navigation-container"  v-show="faceValue == 'withdrawal'" >
    <div class="ant-row-flex" style="margin-left: -10px;">
      <div class="ant-col ant-col-16" style="padding-left: 10px; padding-right: 10px;width: 60.666667%;">
        <div class="pages-assets-balances-withdraw-main"  style="border: 1px solid rgba(255,255,255,0);background-color: var(--assets-new-box-bg);margin-top: 0px;">
          <div class="pages-assets-balances-coinsearch-wrap">
            <span style="background-image: url(&quot;/api/file/download/F20190402135101606U2BkOHXvHA6cEy&quot;);">
              <i class="pages-assets-balances-coinsearch-icon" style="background-image: url(&quot;/api/file/download/F20190402135101606U2BkOHXvHA6cEy&quot;);"></i>
              <span>{{currency.code}}</span>&nbsp;
              <em style="color: #006cdb;">({{currency.full_name}})</em>
            </span>
          </div>
          <div class="pages-assets-balances-withdraw-content">
            <div class="pages-assets-balances-withdraw-usable withdraw-comment" v-show="isShowWithdrawComment">
              <label for="" style="font-size:14px;color: #006cdb">{{$t("withdraw_comment")}}: {{withdraw_comment}}</label>
            </div>
            <div class="pages-assets-balances-withdraw-usable">
              <label for="" style="font-size:16px;">{{$t("available_balance")}}</label>
              <p style="font-size: 16px">{{available_balance}} {{currency.code}}</p>
            </div>
            <div class="ant-row">
              <div class="ant-col ant-col-14">
                <label for="" style="font-size:16px;">{{$t("frozen_amount")}}</label>
                <p style="color: var(--main-color);font-size: 16px">{{locked_balance}} {{currency.code}}</p>
              </div>
            </div>
            <form>
              <div class="ant-row ant-form-item" :class="[isShowErrorCurrencyAddressClass ? 'ant-form-item-with-help' : null ]">
                <div class="ant-col ant-form-item-label" :style="{'line-height': $route.params.code == 'bch' ? '20px' : '39.9999px'}">
                  <label class="" style="color: var(--assets-label-color);" :title="$t('currency_address')">{{$t("currency_address")}}</label>
                </div>
                <div>
                  <span style="color: red;font-size: 10px;" v-if="$route.params.code == 'bch'" :title="$t('bch_withdrawal_does_not_support_the_old_address_format_please_be_sure_to_check_the_withdrawal_address_information_carefully_before_the_withdrawal_operation')">({{$t('bch_withdrawal_does_not_support_the_old_address_format_please_be_sure_to_check_the_withdrawal_address_information_carefully_before_the_withdrawal_operation')}})</span>
                </div>
                <div class="ant-col ant-form-item-control-wrapper">
                  <div class="ant-form-item-control"  :class="[isShowErrorCurrencyAddressClass ? 'has-error' : null ]">
                    <span class="ant-form-item-children">
                      <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                        <span class="ant-input-wrapper ant-input-group currency_address_input">
                          <input type="text" id="currency_address" class="ant-input ant-input-lg" :placeholder="$t('be_sure_not_to_make_a_mistake_otherwise_you_cannot_retrieve_it_be_sure_to_copy_and_paste_in')" style="margin-left: 0px;overflow: hidden;width: 655px;height: 50px;line-height: 32px;padding-left: 9px;outline:none;color: var(--main-color);"  maxlength="50" v-model="currency_address" @keyup="inputCurrencyAddress()">
                        </span>
                      </span>
                    </span>
                    <div class="ant-form-explain" v-if="isShowErrorCurrencyAddressDiv">{{currencyAddressErrorMsg}}</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="pages-assets-balances-withdraw-addressWrap">
                <div class="pages-assets-balances-withdraw-address">
                  <div class="">
                    <div class="">
                      <div style="background-color: var(--bind-phone-phone-bgcolor);overflow: hidden;width: 655px;height: auto;border-radius: 6px;border: solid 1px var(--bind-phone-phone-bodercolor);line-height: 32px;padding-left: 9px;outline:none;outline: none;">
                        <div style="display: flex;justify-content:space-between;">
                          <div style="color: var(--assets-label-color);">{{$t("withdrawal_amount")}}</div>
                          <div style="padding-right: 8px;color: #006cdb;cursor:pointer" @click="chooseAll">{{$t("choose_all")}}</div>
                        </div>
                        <textarea  rows="1" v-model="withdrawal_amount" style="overflow: hidden;width: 644px;height: 30px;background-color: var(--bind-phone-phone-bgcolor);border-radius: 6px;border: solid 0px #e1e3ed;line-height: 30px;padding-left: 9px;outline:none;outline: none;color: var(--main-color);" @keyup="inputActualArrival" ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pages-assets-balances-withdraw-totalFee" style="width: 96.5%;margin-top: 4px;">
                <div>{{$t("handling_fee")}}：{{currency.withdraw_fee_amount}}</div>
                <div>{{$t("actual_arrival")}}：{{actual_arrival}}</div>
              </div>
              <div class="ant-form-explain" style="color: #df4e4e;" v-if="isShowAccountError">{{accountErrorMsg}}</div>
              <br>
              <div class="pages-assets-balances-withdraw-addressWrap">
                <div class="pages-assets-balances-withdraw-address">
                  <div class="">
                    <div class="currency_address_input">
                      <input rows="1" :placeholder="$t('cash_withdrawal_instructions_optional')" style="overflow: hidden;width: 655px;height: 50px;background-color: var(--bind-phone-phone-bgcolor);border-radius: 6px;border: solid 1px var(--bind-phone-phone-bodercolor);line-height: 32px;padding-left: 9px;outline:none;color: var(--main-color);" v-model="description" maxlength="100"></input>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <!-- <div class="ant-row ant-form-item" :class="[isShowErrorFundPwdClass ? 'ant-form-item-with-help' : null ]">
                <div class="ant-col ant-form-item-label">
                  <label for="newPassword" class="" style="color: var(--assets-label-color);" :title="$t('fund_password')">{{$t("fund_password")}}</label>
                </div>
                <div class="ant-col ant-form-item-control-wrapper">
                  <div class="ant-form-item-control"  :class="[isShowErrorFundPwdClass ? 'has-error' : null ]">
                    <span class="ant-form-item-children">
                      <span class="ant-input-group-wrapper ant-input-group-wrapper-lg">
                        <span class="ant-input-wrapper ant-input-group currency_address_input">
                          <input type="password" id="fund_pwd1" class="ant-input ant-input-lg"  style="margin-left: 0px;overflow: hidden;width: 655px;height: 50px;line-height: 32px;padding-left: 9px;outline:none;color: var(--main-color);" v-model="fund_pwd" @keyup="inputFundPwd()" maxlength="32">
                        </span>
                      </span>
                    </span>
                    <div class="ant-form-explain" v-if="isShowErrorFundPwdDiv">{{fundPwdErrorMsg}}</div>
                  </div>
                </div>
              </div> -->
              <div class="pages-assets-balances-withdraw-submit">
                <button type="button" class="ant-btn ant-btn-primary" style="width: 374px;height: 40px;background-color: #006cdb;border-radius: 26px;color: #ffffff;border: 0px" :disabled="submitIsAbleUnbind"  @click="submitCawi" :title="$t('submit')">
                  <span>{{$t("submit")}}</span>
                </button>
              </div>
            </form>
            <div class="pages-assets-balances-withdraw-info">
              <div class="pages-assets-balances-withdraw-item">
                <i class="iconfont icon-zhuyi"></i>
                <div class="pages-assets-balances-withdraw-desc">
                  <p>{{$t("the_minimum_withdrawal_amount")}} {{currency.min_withdraw_amount}} {{currency.code}}</p>
                  <p>{{$t("please_do_not_withdraw_directly_to_the_crowdfunding_or_ico_address_we_will_not_handle_the_issuance_of_future_passes")}}.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ant-col ant-col-8" style="width: 39.333333%;">
        <div class="pages-assets-balances-recordlately-wrap" style="border: 1px solid rgba(255,255,255,0);background-color: var(--assets-new-box-bg);margin-top: 0px;">
          <div class="pages-assets-balances-recordlately-header" style="padding: 0 10px;">
            <h3>{{$t('recent_withdrawal_record')}}</h3>
            <a class="pages-assets-balances-recordlately-extra"><!-- {{$t("all_records")}} --></a>
          </div>
          <div class="ant-table-wrapper">
            <div class="ant-spin-nested-loading">
              <div class="ant-spin-container">
                <div class="ant-table ant-table-default ant-table-empty ant-table-scroll-position-left">
                  <div class="ant-table-content">
                    <div class="ant-table-body">
                      <div class="">
                        <div class="ant-table-tbody" style="background-color: var(--assets-rwd-home-list);">
                          <tr>
                            <th class="" style="background-color: var(--assets-rwd-home-list);border-top-left-radius: 0px;width: 16%;">
                              <span class="ant-table-header-column">
                                <div>
                                  <span class="ant-table-column-title">{{$t("type_of_pass")}}</span>
                                  <span class="ant-table-column-sorter"></span>
                                </div>
                              </span>
                            </th>
                            <th class="ant-table-align-center" style="text-align: center;background-color: var(--assets-rwd-home-list);width: 16%;">
                              <span class="ant-table-header-column">
                                <div>
                                  <span class="ant-table-column-title">{{$t("status")}}</span>
                                  <span class="ant-table-column-sorter"></span>
                                </div>
                              </span>
                            </th>
                            <th class="ant-table-align-center" style="text-align: center;background-color: var(--assets-rwd-home-list);width: 39%;">
                              <span class="ant-table-header-column">
                                <div>
                                  <span class="ant-table-column-title">{{$t("time")}}</span>
                                  <span class="ant-table-column-sorter"></span>
                                </div>
                              </span>
                            </th>
                            <th class="ant-table-align-right" style="text-align: right;background-color: var(--assets-rwd-home-list);border-top-right-radius: 0px;width: 29%;">
                              <span class="ant-table-header-column">
                                <div>
                                  <span class="ant-table-column-title">{{$t("quantity")}}</span>
                                  <span class="ant-table-column-sorter"></span>
                                </div>
                              </span>
                            </th>
                          </tr>
                        </div>
                        <div class="ant-table-tbody tcontent" v-if="list_is_have_data" style="overflow-y: scroll;overflow-x: hidden;height: 687px;">
                          <tr v-for = "item in withdraws">
                            <th style="width: 16%;">
                              <span>
                                <div>
                                  <span>{{currency.code}}</span>
                                  <span></span>
                                </div>
                              </span>
                            </th>
                            <th style="width: 16%;">
                              <span>
                                <div style="text-align: center;">
                                  <span>{{item.is_approved ? (item.commit_at == "" ? $t("no_coins") : $t("deposited")) : (item.approved_at == "" ? $t("unreviewed") : $t("withdrawal_application_rejected")) }}</span>
                                  <span></span>
                                </div>
                              </span>
                            </th>
                            <th style="width: 39%;">
                              <span>
                                <div style="text-align: center;">
                                  <span>{{item.created_at}}</span>
                                  <span></span>
                                </div>
                              </span>
                            </th>
                            <th style="width: 29%;">
                              <span>
                                <div style="text-align: right;">
                                  <span>{{item.amount}}</span>
                                  <span></span>
                                </div>
                              </span>
                            </th>
                          </tr>
                        </div>
                      </div>
                    </div>
                    <div class="ant-table-placeholder" v-if="!list_is_have_data">
                      <div class="components-empty-index-wrapper">
                        <i class="iconfont icon-wushuju"></i>
                        <div>{{$t("no_data")}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css" scoped>
.tcontent tr{
  display: flex;
  justify-content:space-between;
}
.tcontent tr th{
  white-space:normal;
  word-wrap: break-word;
  word-break: normal;
}
.ant-table-column-title{
  color: #006cdb;
}
.currency_address_input input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: var(--assets-label-color);
  font-size: 14px;
}
</style>
<script type="text/javascript">
import Vue from 'vue'
import {Decimal} from 'decimal.js';
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    faceValue: String,
    language: String,
    is_have_payment_address: Boolean,
    paymentAddress: Object,
    currency: Object,
    withdraws: Array,
    available_balance: String,
    withdraw_comment: String,
    isShowWithdrawComment: Boolean,
    locked_balance: String
  },
  data(){
    return {
      withdrawal_amount: '',
      actual_arrival: '--',
      isShowErrorCurrencyAddressClass: false,
      currency_address: '',
      isShowErrorCurrencyAddressDiv: false,
      currencyAddressErrorMsg: '',
      isShowErrorFundPwdClass: false,
      isShowErrorFundPwdDiv: false,
      fundPwdErrorMsg: '',
      fund_pwd: '',
      accountErrorMsg: '',
      isShowAccountError: false,
      description: ''
    }
  },
  computed: {
    auth_status: function () {
      if(this.isLoggedIn){
        return this.currentUserInfo.auth_status
      }else{
        return false;
      }
    },
    submitIsAbleUnbind: function() {
      if(!this.isShowErrorCurrencyAddressClass && !this.isShowErrorCurrencyAddressDiv && !this.isShowErrorFundPwdClass && !this.isShowErrorFundPwdDiv && !this.isShowAccountError  && this.withdrawal_amount != '' && this.currency_address != ''){
        return false;
      }else{
        return true;
      }
    },
    list_is_have_data: function() {
      if (this.withdraws.length > 0) {
        return true;
      }else{
        return false;
      }
    }
  },
  mounted() {
    this.getMemberInfo()
  },
  methods: {
    submitCawi: function(){
      if(!this.currentUserInfo.is_bind_assets_password){
        this.$message({
          message: this.$t('you_have_not_set_a_fund_password'),
          type: 'error'
        })
        return
      }
      if(!this.currentUserInfo.withdrawal_is_allowed){
        this.$message({
          message: this.$t('is_not_allowed'),
          type: 'error'
        })
        return
      }
      this.postUrl('/www/user_center/reset_asset_password_locked', {
        lang: this.currentLang
      }).then((response) => {
        if(response.data.code == 20000){
          this.OperationValidation('Withdraw')
          Vue.setGlobalVariable({
            'withdrawal_amount': this.withdrawal_amount
          });
          Vue.setGlobalVariable({
            'withdraw_to_address': this.currency_address
          });
          Vue.setGlobalVariable({
            'description': this.description
          });
        }
      });
    },
    inputCurrencyAddress: function(){
      if(this.currency_address == ''){
        this.isShowErrorCurrencyAddressClass = true;
        this.isShowErrorCurrencyAddressDiv = true;
        this.currencyAddressErrorMsg = this.$t('withdrawal_address_cannot_be_empty');
      }else{
        this.isShowErrorCurrencyAddressClass = false;
        this.isShowErrorCurrencyAddressDiv = false;
        this.currencyAddressErrorMsg = '';
      }
    },
    inputFundPwd: function(){
      this.fund_pwd = this.fund_pwd.replace(/[\u4e00-\u9fa5]/ig,'');
      let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,32}$/;
      if (this.fund_pwd == '') {
        //不合法 不可为空
        this.fundPwdErrorMsg = this.$t('please_enter_your_password');
        this.isShowErrorFundPwdClass = true;
        this.isShowErrorFundPwdDiv = true;
      }else if(this.fund_pwd.length < 8 || this.fund_pwd.length > 32){
        //不合法 格式错误
        this.fundPwdErrorMsg = this.$t('please_enter_an_8_32_password');
        this.isShowErrorFundPwdClass = true;
        this.isShowErrorFundPwdDiv = true;
      }else if(!reg.test(this.fund_pwd)){
        this.fundPwdErrorMsg = this.$t('password_must_contain_letters_and_numbers');
        this.isShowErrorFundPwdClass  = true;
        this.isShowErrorFundPwdDiv = true;
      }else{
        //正确
        this.isShowErrorFundPwdClass = false;
        this.isShowErrorFundPwdDiv = false;
      }
    },
    chooseAll: function(){
      all_available_amount = this.available_balance
      if(new Decimal(this.available_balance).lte(new Decimal(0))){
        this.$message.closeAll();
        this.$message({
          message: this.$t('insufficient_balance'),
          type: 'warning'
        })
        return false;
      }else{
        this.withdrawal_amount = all_available_amount;
        this.inputActualArrival();
      }
    },
    inputActualArrival: function(){
      this.withdrawal_amount = this.withdrawal_amount.replace(/[^0-9.]/g,'');
      if(this.withdrawal_amount != ''){
        if(this.withdrawal_amount.substring(0,1) == '0' && this.withdrawal_amount.substring(1,2) != '' && this.withdrawal_amount.substring(1,2) != '.' ){
          this.withdrawal_amount = this.withdrawal_amount.substr(1);
        }
        if(this.withdrawal_amount == '.'){
          this.withdrawal_amount = "0."
        }
        withdrawal_amount_number = 0
        withdrawal_amount_number = Number(this.withdrawal_amount.split(".")[0]);
        if(this.withdrawal_amount.split(".")[1]){
          if(this.withdrawal_amount.split(".")[1].length > this.currency.withdraw_scale){
            this.withdrawal_amount = String(withdrawal_amount_number) + '.' +this.withdrawal_amount.split(".")[1].slice(0,this.withdrawal_amount.split(".")[1].length-1)
          }
        }
        if((this.withdrawal_amount.split('.')).length-1 > 1){
          //去除多余的小数点
          this.withdrawal_amount = String(withdrawal_amount_number) + '.' +this.withdrawal_amount.split(".")[1].slice(0,this.withdrawal_amount.split(".")[1].length-1)
        }
        if(new Decimal(this.withdrawal_amount).gt(new Decimal(this.available_balance))){
          this.withdrawal_amount =  this.available_balance;
        }
        if(new Decimal(this.withdrawal_amount).lte(new Decimal(this.currency.withdraw_fee_amount))){
          this.$message.closeAll();
          this.$message({
            message: this.$t('the_withdraw_amount_must_be_greater_than_the_handling_fee'),
            type: 'warning'
          })
          this.isShowAccountError = true;
          this.accountErrorMsg = this.$t('the_withdraw_amount_must_be_greater_than_the_handling_fee')
          this.actual_arrival = '--';
          return false;
        }
        if(new Decimal(this.withdrawal_amount).lt(new Decimal(this.currency.min_withdraw_amount))){
          this.$message.closeAll();
          this.$message({
            message: this.$t('the_withdrawal_amount_cannot_be_less_than_the_minimum_withdrawal_amount'),
            type: 'warning'
          })
          this.isShowAccountError = true;
          this.accountErrorMsg = this.$t('the_withdrawal_amount_cannot_be_less_than_the_minimum_withdrawal_amount')
          this.actual_arrival = '--';
          return false;
        }
        if(new Decimal(this.withdrawal_amount).gte(new Decimal(this.currency.min_withdraw_amount)) && new Decimal(this.withdrawal_amount).gt(new Decimal(this.currency.withdraw_fee_amount))){
          this.isShowAccountError = false;
          this.accountErrorMsg = '';
          this.$message.closeAll();
          this.actual_arrival = new Decimal(this.withdrawal_amount).sub(new Decimal(this.currency.withdraw_fee_amount))
        }
      }else{
        this.isShowAccountError = true;
        this.accountErrorMsg = this.$t('please_enter_the_amount_of_withdrawal');
        this.$message.closeAll();
        this.actual_arrival = '--';
        this.withdrawal_amount = '';
        return false;
      }
      if(this.withdrawal_amount <= 0){
        this.isShowAccountError = true;
        this.accountErrorMsg = this.$t('please_enter_the_amount_of_withdrawal');
        this.actual_arrival = '--';
        return false;
      }
      this.isShowAccountError = false;
      this.accountErrorMsg = '';
    }
  }
}
</script>
