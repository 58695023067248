<template>
	<div>
		<h3 class="pages-ucenter-commonform-title" v-if='bind_otp_binded'>{{$t("bind_google_authenticator")}}</h3>
		<h3 class="pages-ucenter-commonform-title"  v-if='!bind_otp_binded'>{{$t('turn_on_google_verification')}}</h3>
	</div>
</template>
<script type="text/javascript">
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)
    bind_otp_binded: Boolean
  }
}
</script>