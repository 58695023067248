<template>
<section class="pages-ucenter-commonform-content" style="margin-top: 15px">
    <form class="ant-form ant-form-vertical ant-form-hide-required-mark">
        <div class="ant-row ant-form-item" :class="[otcNicknameErrorClass ? 'ant-form-item-with-help' : null ]">
            <div class="ant-col ant-form-item-label">
            	<label for="oldPassword" class="ant-form-item-required ant-form-item-no-colon" :title="$t('please_enter_the_nickname_you_want_to_modify')">{{$t("please_enter_the_nickname_you_want_to_modify")}}</label>
            </div>
            <div class="ant-col ant-form-item-control-wrapper">
                <div class="ant-form-item-control" :class="[otcNicknameErrorClass ? 'has-feedback has-error' : null ]">
	                <span class="ant-form-item-children">
	                	<input type="text" id="text" class="ant-input ant-input-lg"   v-model="otcNickname" maxlength="32">
	                </span>
                    <div class="ant-form-explain"  v-if="isShowErrorOtcNicknameDiv">{{otcNicknameErrorMsg}}</div>
                </div>
            </div>
        </div>
        <div class="pages-ucenter-commonform-handle">
	        <button type="button" class="ant-btn ant-btn-primary"  style="width: 130px;height: 46px;line-height: 46px;font-size: 15px;border-radius: 6px;background: -webkit-linear-gradient(left, #7655e9,#4f8de9);"  :disabled="buttonIsDisabled" :title="$t('confirm_the_changes')" @click="editOtcNickname" >
	        	<span>{{$t("confirm_the_changes")}}</span>
	        </button>
        	<router-link class="pages-ucenter-commonform-back" to="/user_center">{{$t("return")}}</router-link>
        </div>
    </form>
</section>
</template>
<script type="text/javascript">
export default {
  data() {   
     return {
        otcNickname: '',
        otcNicknameErrorClass: false,
        isShowErrorOtcNicknameDiv: false,
        otcNicknameErrorMsg: ''
     }
  },
  computed: {
    buttonIsDisabled: function () {
        if(!this.otcNicknameErrorClass && !this.isShowErrorOtcNicknameDiv && this.otcNickname != ''){
            return false;
        }else{
            return true;
        }
    }
  },
  methods: {
    editOtcNickname(){
        this.postUrl('/www/user_center/edit_otc_nickname', {
            lang: this.currentLang,
            nick_name: this.otcNickname
         }).then((response) => {
            if(response.data.code == 20000){
              //重新加载用户数据
              this.getMemberInfo();
              this.$router.push({path:'/user_center'}).catch(data => {  })
            }
         });
    }
  },
  mounted() {
    if(this.isLoggedIn){
        if(this.currentUserInfo.otc_nickname != '' && this.currentUserInfo.otc_nickname != null){
            this.otcNickname = this.currentUserInfo.otc_nickname;
        }
    }
  }
}
</script>