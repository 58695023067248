<template>
<div class="pages-ucenter-commonform-wrapper">
  <bind-email-title :language = "language"/>
  <bind-email-header-text :language = "language"/>
  <bind-email-content :language = "language"/>
  <operation-validation></operation-validation>
</div>
</template>
<script>
import BindEmailTitle from '../BindEmail/BindEmailTitle'
import BindEmailHeaderText from '../BindEmail/BindEmailHeaderText'
import BindEmailContent from '../BindEmail/BindEmailContent'
import OperationValidation from '../OperationValidation'
export default {
  props: {
    // 基础的类型检查 (`null` 和 `undefined` 会通过任何类型验证)

  },
  data() {
     return {
     }
  },
  computed: {
    language: function () {
      return this.currentLang;
    }
  },
  mounted() {
    
  },
  methods: {

  },
  components: {
    BindEmailTitle,
    BindEmailHeaderText,
    BindEmailContent,
    OperationValidation
  }
}
</script>
