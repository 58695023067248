import {
    SET_WEBSOCKET,
  } from '../mutation_types'
  const state = {
    www_home_market: [],
    www_home_trades_main_area_list: [],
    www_home_trades_main_area_pass_card: [],
    www_home_trades_main_innovation_list: []
  }
  const mutations = {
    ['WWW_HOME_MARKET'] (state, data) {
        state.www_home_market = data.data
    },
    ['WWW_HOME_TRADES_MAIN_LIST'] (state, data) {
        state.www_home_trades_main_area_list = data.main.data
        state.www_home_trades_main_area_pass_card = data.main.pass_card
        state.www_home_trades_main_innovation_list = data.innovation.data
    }
  }

  export default {
    state,
    mutations
  }
